export const CityData = [

  { id: '15', name: 'BURDUR' },
  { id: '26', name: 'ESKİŞEHİR' },
  { id: '18', name: 'ÇANKIRI' },
  { id: '80', name: 'OSMANİYE' },
  { id: '41', name: 'KOCAELİ' },
  { id: '27', name: 'GAZİANTEP' },
  { id: '31', name: 'HATAY' },
  { id: '38', name: 'KAYSERİ' },
  { id: '29', name: 'GÜMÜŞHANE' },
  { id: '54', name: 'SAKARYA' },
  { id: '16', name: 'BURSA' },
  { id: '69', name: 'BAYBURT' },
  { id: '17', name: 'ÇANAKKALE' },
  { id: '57', name: 'SİNOP' },
  { id: '74', name: 'BARTIN' },
  { id: '503', name: 'MAĞUSA (KIBRIS)' },
  { id: '33', name: 'MERSİN' },
  { id: '51', name: 'NİĞDE' },
  { id: '42', name: 'KONYA' },
  { id: '60', name: 'TOKAT' },
  { id: '2', name: 'ADIYAMAN' },
  { id: '6', name: 'ANKARA' },
  { id: '66', name: 'YOZGAT' },
  { id: '52', name: 'ORDU' },
  { id: '53', name: 'RİZE' },
  { id: '1', name: 'ADANA' },
  { id: '40', name: 'KIRŞEHİR' },
  { id: '76', name: 'IĞDIR' },

  { id: '21', name: 'DİYARBAKIR' },
  { id: '64', name: 'UŞAK' },
  { id: '501', name: 'LEFKOŞE (KIBRIS)' },
  { id: '5', name: 'AMASYA' },
  { id: '24', name: 'ERZİNCAN' },
  { id: '32', name: 'ISPARTA' },
  { id: '502', name: 'GİRNE (KIBRIS)' },
  { id: '23', name: 'ELAZIĞ' },
  { id: '78', name: 'KARABÜK' },
  { id: '30', name: 'HAKKARİ' },
  { id: '36', name: 'KARS' },
  { id: '67', name: 'ZONGULDAK' },
  { id: '68', name: 'AKSARAY' },
  { id: '44', name: 'MALATYA' },
  { id: '10', name: 'BALIKESİR' },
  { id: '20', name: 'DENİZLİ' },
  { id: '49', name: 'MUŞ' },
  { id: '73', name: 'ŞIRNAK' },
  { id: '48', name: 'MUĞLA' },
  { id: '59', name: 'TEKİRDAĞ' },
  { id: '39', name: 'KIRKLARELİ' },
  { id: '56', name: 'SİİRT' },
  { id: '28', name: 'GİRESUN' },
  { id: '63', name: 'ŞANLIURFA' },
  { id: '9', name: 'AYDIN' },
  { id: '72', name: 'BATMAN' },
  { id: '13', name: 'BİTLİS' },
  { id: '3', name: 'AFYONKARAHİSAR' },
  { id: '8', name: 'ARTVİN' },
  { id: '4', name: 'AĞRI' },
  { id: '77', name: 'YALOVA' },
  { id: '50', name: 'NEVŞEHİR' },
  { id: '61', name: 'TRABZON' },
  { id: '58', name: 'SİVAS' },
  { id: '7', name: 'ANTALYA' },
  { id: '37', name: 'KASTAMONU' },
  { id: '47', name: 'MARDİN' },
  { id: '46', name: 'KAHRAMANMARAŞ' },
  { id: '25', name: 'ERZURUM' },
  { id: '75', name: 'ARDAHAN' },
  { id: '81', name: 'DÜZCE' },
  { id: '55', name: 'SAMSUN' },
  { id: '19', name: 'ÇORUM' },
  { id: '65', name: 'VAN' },
  { id: '14', name: 'BOLU' },
  { id: '43', name: 'KÜTAHYA' },
  { id: '11', name: 'BİLECİK' },

  { id: '79', name: 'KİLİS' },
  { id: '62', name: 'TUNCELİ' },
  { id: '12', name: 'BİNGÖL' },
  { id: '22', name: 'EDİRNE' },
  { id: '71', name: 'KIRIKKALE' },
  { id: '70', name: 'KARAMAN' },
].sort((a, b) => a.name.localeCompare(b.name));

export const DistrictData = [
  { id: '1757', il_id: '1', name: 'ALADAĞ' },
  { id: '1219', il_id: '1', name: 'CEYHAN' },
  { id: '2033', il_id: '1', name: 'ÇUKUROVA' },
  { id: '1329', il_id: '1', name: 'FEKE' },
  { id: '1806', il_id: '1', name: 'İMAMOĞLU' },
  { id: '1437', il_id: '1', name: 'KARAİSALI' },
  { id: '1443', il_id: '1', name: 'KARATAŞ' },
  { id: '1486', il_id: '1', name: 'KOZAN' },
  { id: '1580', il_id: '1', name: 'POZANTI' },
  { id: '1588', il_id: '1', name: 'SAİMBEYLİ' },
  { id: '2032', il_id: '1', name: 'SARIÇAM' },
  { id: '1104', il_id: '1', name: 'SEYHAN' },
  { id: '1687', il_id: '1', name: 'TUFANBEYLİ' },
  { id: '1734', il_id: '1', name: 'YUMURTALIK' },
  { id: '1748', il_id: '1', name: 'YÜREĞİR' },
  { id: '1182', il_id: '2', name: 'BESNİ' },
  { id: '1246', il_id: '2', name: 'ÇELİKHAN' },
  { id: '1347', il_id: '2', name: 'GERGER' },
  { id: '1354', il_id: '2', name: 'GÖLBAŞI' },
  { id: '1425', il_id: '2', name: 'KAHTA' },
  { id: '1105', il_id: '2', name: 'MERKEZ' },
  { id: '1592', il_id: '2', name: 'SAMSAT' },
  { id: '1985', il_id: '2', name: 'SİNCİK' },
  { id: '1989', il_id: '2', name: 'TUT' },
  { id: '1771', il_id: '3', name: 'BAŞMAKÇI' },
  { id: '1773', il_id: '3', name: 'BAYAT' },
  { id: '1200', il_id: '3', name: 'BOLVADİN' },
  { id: '1239', il_id: '3', name: 'ÇAY' },
  { id: '1906', il_id: '3', name: 'ÇOBANLAR' },
  { id: '1267', il_id: '3', name: 'DAZKIRI' },
  { id: '1281', il_id: '3', name: 'DİNAR' },
  { id: '1306', il_id: '3', name: 'EMİRDAĞ' },
  { id: '1923', il_id: '3', name: 'EVCİLER' },
  { id: '1944', il_id: '3', name: 'HOCALAR' },
  { id: '1404', il_id: '3', name: 'İHSANİYE' },
  { id: '1809', il_id: '3', name: 'İSCEHİSAR' },
  { id: '1961', il_id: '3', name: 'KIZILÖREN' },
  { id: '1108', il_id: '3', name: 'MERKEZ' },
  { id: '1594', il_id: '3', name: 'SANDIKLI' },
  { id: '1626', il_id: '3', name: 'SİNANPAŞA' },
  { id: '1639', il_id: '3', name: 'SULTANDAĞI' },
  { id: '1664', il_id: '3', name: 'ŞUHUT' },
  { id: '1283', il_id: '4', name: 'DİYADİN' },
  { id: '1287', il_id: '4', name: 'DOĞUBAYAZIT' },
  { id: '1301', il_id: '4', name: 'ELEŞKİRT' },
  { id: '1379', il_id: '4', name: 'HAMUR' },
  { id: '1111', il_id: '4', name: 'MERKEZ' },
  { id: '1568', il_id: '4', name: 'PATNOS' },
  { id: '1667', il_id: '4', name: 'TAŞLIÇAY' },
  { id: '1691', il_id: '4', name: 'TUTAK' },
  { id: '1363', il_id: '5', name: 'GÖYNÜCEK' },
  { id: '1368', il_id: '5', name: 'GÜMÜŞHACIKÖY' },
  { id: '1938', il_id: '5', name: 'HAMAMÖZÜ' },
  { id: '1134', il_id: '5', name: 'MERKEZ' },
  { id: '1524', il_id: '5', name: 'MERZİFON' },
  { id: '1641', il_id: '5', name: 'SULUOVA' },
  { id: '1668', il_id: '5', name: 'TAŞOVA' },
  { id: '1872', il_id: '6', name: 'AKYURT' },
  { id: '1130', il_id: '6', name: 'ALTINDAĞ' },
  { id: '1157', il_id: '6', name: 'AYAŞ' },
  { id: '1167', il_id: '6', name: 'BALA' },
  { id: '1187', il_id: '6', name: 'BEYPAZARI' },
  { id: '1227', il_id: '6', name: 'ÇAMLIDERE' },
  { id: '1231', il_id: '6', name: 'ÇANKAYA' },
  { id: '1260', il_id: '6', name: 'ÇUBUK' },
  { id: '1302', il_id: '6', name: 'ELMADAĞ' },
  { id: '1922', il_id: '6', name: 'ETİMESGUT' },
  { id: '1924', il_id: '6', name: 'EVREN' },
  { id: '1744', il_id: '6', name: 'GÖLBAŞI' },
  { id: '1365', il_id: '6', name: 'GÜDÜL' },
  { id: '1387', il_id: '6', name: 'HAYMANA' },
  { id: '1815', il_id: '6', name: 'KAHRAMANKAZAN' },
  { id: '1427', il_id: '6', name: 'KALECİK' },
  { id: '1745', il_id: '6', name: 'KEÇİÖREN' },
  { id: '1473', il_id: '6', name: 'KIZILCAHAMAM' },
  { id: '1746', il_id: '6', name: 'MAMAK' },
  { id: '1539', il_id: '6', name: 'NALLIHAN' },
  { id: '1578', il_id: '6', name: 'POLATLI' },
  { id: '2034', il_id: '6', name: 'PURSAKLAR' },
  { id: '1747', il_id: '6', name: 'SİNCAN' },
  { id: '1658', il_id: '6', name: 'ŞEREFLİKOÇHİSAR' },
  { id: '1723', il_id: '6', name: 'YENİMAHALLE' },
  { id: '1121', il_id: '7', name: 'AKSEKİ' },
  { id: '2035', il_id: '7', name: 'AKSU' },
  { id: '1126', il_id: '7', name: 'ALANYA' },
  { id: '1811', il_id: '7', name: 'DEMRE' },
  { id: '2036', il_id: '7', name: 'DÖŞEMEALTI' },
  { id: '1303', il_id: '7', name: 'ELMALI' },
  { id: '1333', il_id: '7', name: 'FİNİKE' },
  { id: '1337', il_id: '7', name: 'GAZİPAŞA' },
  { id: '1370', il_id: '7', name: 'GÜNDOĞMUŞ' },
  { id: '1946', il_id: '7', name: 'İBRADI' },
  { id: '1451', il_id: '7', name: 'KAŞ' },
  { id: '1959', il_id: '7', name: 'KEMER' },
  { id: '2037', il_id: '7', name: 'KEPEZ' },
  { id: '2038', il_id: '7', name: 'KONYAALTI' },
  { id: '1483', il_id: '7', name: 'KORKUTELİ' },
  { id: '1492', il_id: '7', name: 'KUMLUCA' },
  { id: '1512', il_id: '7', name: 'MANAVGAT' },
  { id: '2039', il_id: '7', name: 'MURATPAŞA' },
  { id: '1616', il_id: '7', name: 'SERİK' },
  { id: '1145', il_id: '8', name: 'ARDANUÇ' },
  { id: '1147', il_id: '8', name: 'ARHAVİ' },
  { id: '1202', il_id: '8', name: 'BORÇKA' },
  { id: '1395', il_id: '8', name: 'HOPA' },
  { id: '2105', il_id: '8', name: 'KEMALPAŞA' },
  { id: '1152', il_id: '8', name: 'MERKEZ' },
  { id: '1828', il_id: '8', name: 'MURGUL' },
  { id: '1653', il_id: '8', name: 'ŞAVŞAT' },
  { id: '1736', il_id: '8', name: 'YUSUFELİ' },
  { id: '1206', il_id: '9', name: 'BOZDOĞAN' },
  { id: '1781', il_id: '9', name: 'BUHARKENT' },
  { id: '1256', il_id: '9', name: 'ÇİNE' },
  { id: '2000', il_id: '9', name: 'DİDİM' },
  { id: '2076', il_id: '9', name: 'EFELER' },
  { id: '1348', il_id: '9', name: 'GERMENCİK' },
  { id: '1807', il_id: '9', name: 'İNCİRLİOVA' },
  { id: '1435', il_id: '9', name: 'KARACASU' },
  { id: '1957', il_id: '9', name: 'KARPUZLU' },
  { id: '1479', il_id: '9', name: 'KOÇARLI' },
  { id: '1968', il_id: '9', name: 'KÖŞK' },
  { id: '1497', il_id: '9', name: 'KUŞADASI' },
  { id: '1498', il_id: '9', name: 'KUYUCAK' },
  { id: '1542', il_id: '9', name: 'NAZİLLİ' },
  { id: '1637', il_id: '9', name: 'SÖKE' },
  { id: '1640', il_id: '9', name: 'SULTANHİSAR' },
  { id: '1724', il_id: '9', name: 'YENİPAZAR' },
  { id: '2077', il_id: '10', name: 'ALTIEYLÜL' },
  { id: '1161', il_id: '10', name: 'AYVALIK' },
  { id: '1169', il_id: '10', name: 'BALYA' },
  { id: '1171', il_id: '10', name: 'BANDIRMA' },
  { id: '1191', il_id: '10', name: 'BİGADİÇ' },
  { id: '1216', il_id: '10', name: 'BURHANİYE' },
  { id: '1291', il_id: '10', name: 'DURSUNBEY' },
  { id: '1294', il_id: '10', name: 'EDREMİT' },
  { id: '1310', il_id: '10', name: 'ERDEK' },
  { id: '1928', il_id: '10', name: 'GÖMEÇ' },
  { id: '1360', il_id: '10', name: 'GÖNEN' },
  { id: '1384', il_id: '10', name: 'HAVRAN' },
  { id: '1418', il_id: '10', name: 'İVRİNDİ' },
  { id: '2078', il_id: '10', name: 'KARESİ' },
  { id: '1462', il_id: '10', name: 'KEPSUT' },
  { id: '1514', il_id: '10', name: 'MANYAS' },
  { id: '1824', il_id: '10', name: 'MARMARA' },
  { id: '1608', il_id: '10', name: 'SAVAŞTEPE' },
  { id: '1619', il_id: '10', name: 'SINDIRGI' },
  { id: '1644', il_id: '10', name: 'SUSURLUK' },
  { id: '1210', il_id: '11', name: 'BOZÜYÜK' },
  { id: '1359', il_id: '11', name: 'GÖLPAZARI' },
  { id: '1948', il_id: '11', name: 'İNHİSAR' },
  { id: '1192', il_id: '11', name: 'MERKEZ' },
  { id: '1559', il_id: '11', name: 'OSMANELİ' },
  { id: '1571', il_id: '11', name: 'PAZARYERİ' },
  { id: '1636', il_id: '11', name: 'SÖĞÜT' },
  { id: '1857', il_id: '11', name: 'YENİPAZAR' },
  { id: '1750', il_id: '12', name: 'ADAKLI' },
  { id: '1344', il_id: '12', name: 'GENÇ' },
  { id: '1446', il_id: '12', name: 'KARLIOVA' },
  { id: '1475', il_id: '12', name: 'KİĞI' },
  { id: '1193', il_id: '12', name: 'MERKEZ' },
  { id: '1633', il_id: '12', name: 'SOLHAN' },
  { id: '1855', il_id: '12', name: 'YAYLADERE' },
  { id: '1996', il_id: '12', name: 'YEDİSU' },
  { id: '1106', il_id: '13', name: 'ADİLCEVAZ' },
  { id: '1112', il_id: '13', name: 'AHLAT' },
  { id: '1798', il_id: '13', name: 'GÜROYMAK' },
  { id: '1394', il_id: '13', name: 'HİZAN' },
  { id: '1196', il_id: '13', name: 'MERKEZ' },
  { id: '1537', il_id: '13', name: 'MUTKİ' },
  { id: '1669', il_id: '13', name: 'TATVAN' },
  { id: '1916', il_id: '14', name: 'DÖRTDİVAN' },
  { id: '1346', il_id: '14', name: 'GEREDE' },
  { id: '1364', il_id: '14', name: 'GÖYNÜK' },
  { id: '1466', il_id: '14', name: 'KIBRISCIK' },
  { id: '1522', il_id: '14', name: 'MENGEN' },
  { id: '1199', il_id: '14', name: 'MERKEZ' },
  { id: '1531', il_id: '14', name: 'MUDURNU' },
  { id: '1610', il_id: '14', name: 'SEBEN' },
  { id: '1997', il_id: '14', name: 'YENİÇAĞA' },
  { id: '1109', il_id: '15', name: 'AĞLASUN' },
  { id: '1874', il_id: '15', name: 'ALTINYAYLA' },
  { id: '1211', il_id: '15', name: 'BUCAK' },
  { id: '1899', il_id: '15', name: 'ÇAVDIR' },
  { id: '1903', il_id: '15', name: 'ÇELTİKÇİ' },
  { id: '1357', il_id: '15', name: 'GÖLHİSAR' },
  { id: '1813', il_id: '15', name: 'KARAMANLI' },
  { id: '1816', il_id: '15', name: 'KEMER' },
  { id: '1215', il_id: '15', name: 'MERKEZ' },
  { id: '1672', il_id: '15', name: 'TEFENNİ' },
  { id: '1728', il_id: '15', name: 'YEŞİLOVA' },
  { id: '1783', il_id: '16', name: 'BÜYÜKORHAN' },
  { id: '1343', il_id: '16', name: 'GEMLİK' },
  { id: '1935', il_id: '16', name: 'GÜRSU' },
  { id: '1799', il_id: '16', name: 'HARMANCIK' },
  { id: '1411', il_id: '16', name: 'İNEGÖL' },
  { id: '1420', il_id: '16', name: 'İZNİK' },
  { id: '1434', il_id: '16', name: 'KARACABEY' },
  { id: '1457', il_id: '16', name: 'KELES' },
  { id: '1960', il_id: '16', name: 'KESTEL' },
  { id: '1530', il_id: '16', name: 'MUDANYA' },
  { id: '1535', il_id: '16', name: 'MUSTAFAKEMALPAŞA' },
  { id: '1829', il_id: '16', name: 'NİLÜFER' },
  { id: '1553', il_id: '16', name: 'ORHANELİ' },
  { id: '1554', il_id: '16', name: 'ORHANGAZİ' },
  { id: '1832', il_id: '16', name: 'OSMANGAZİ' },
  { id: '1725', il_id: '16', name: 'YENİŞEHİR' },
  { id: '1859', il_id: '16', name: 'YILDIRIM' },
  { id: '1160', il_id: '17', name: 'AYVACIK' },
  { id: '1180', il_id: '17', name: 'BAYRAMİÇ' },
  { id: '1190', il_id: '17', name: 'BİGA' },
  { id: '1205', il_id: '17', name: 'BOZCAADA' },
  { id: '1229', il_id: '17', name: 'ÇAN' },
  { id: '1293', il_id: '17', name: 'ECEABAT' },
  { id: '1326', il_id: '17', name: 'EZİNE' },
  { id: '1340', il_id: '17', name: 'GELİBOLU' },
  { id: '1408', il_id: '17', name: 'GÖKÇEADA' },
  { id: '1503', il_id: '17', name: 'LAPSEKİ' },
  { id: '1230', il_id: '17', name: 'MERKEZ' },
  { id: '1722', il_id: '17', name: 'YENİCE' },
  { id: '1765', il_id: '18', name: 'ATKARACALAR' },
  { id: '1885', il_id: '18', name: 'BAYRAMÖREN' },
  { id: '1248', il_id: '18', name: 'ÇERKEŞ' },
  { id: '1300', il_id: '18', name: 'ELDİVAN' },
  { id: '1399', il_id: '18', name: 'ILGAZ' },
  { id: '1817', il_id: '18', name: 'KIZILIRMAK' },
  { id: '1963', il_id: '18', name: 'KORGUN' },
  { id: '1494', il_id: '18', name: 'KURŞUNLU' },
  { id: '1232', il_id: '18', name: 'MERKEZ' },
  { id: '1555', il_id: '18', name: 'ORTA' },
  { id: '1649', il_id: '18', name: 'ŞABANÖZÜ' },
  { id: '1718', il_id: '18', name: 'YAPRAKLI' },
  { id: '1124', il_id: '19', name: 'ALACA' },
  { id: '1177', il_id: '19', name: 'BAYAT' },
  { id: '1778', il_id: '19', name: 'BOĞAZKALE' },
  { id: '1911', il_id: '19', name: 'DODURGA' },
  { id: '1414', il_id: '19', name: 'İSKİLİP' },
  { id: '1445', il_id: '19', name: 'KARGI' },
  { id: '1972', il_id: '19', name: 'LAÇİN' },
  { id: '1520', il_id: '19', name: 'MECİTÖZÜ' },
  { id: '1259', il_id: '19', name: 'MERKEZ' },
  { id: '1976', il_id: '19', name: 'OĞUZLAR' },
  { id: '1556', il_id: '19', name: 'ORTAKÖY' },
  { id: '1558', il_id: '19', name: 'OSMANCIK' },
  { id: '1642', il_id: '19', name: 'SUNGURLU' },
  { id: '1850', il_id: '19', name: 'UĞURLUDAĞ' },
  { id: '1102', il_id: '20', name: 'ACIPAYAM' },
  { id: '1769', il_id: '20', name: 'BABADAĞ' },
  { id: '1881', il_id: '20', name: 'BAKLAN' },
  { id: '1774', il_id: '20', name: 'BEKİLLİ' },
  { id: '1888', il_id: '20', name: 'BEYAĞAÇ' },
  { id: '1889', il_id: '20', name: 'BOZKURT' },
  { id: '1214', il_id: '20', name: 'BULDAN' },
  { id: '1224', il_id: '20', name: 'ÇAL' },
  { id: '1226', il_id: '20', name: 'ÇAMELİ' },
  { id: '1233', il_id: '20', name: 'ÇARDAK' },
  { id: '1257', il_id: '20', name: 'ÇİVRİL' },
  { id: '1371', il_id: '20', name: 'GÜNEY' },
  { id: '1803', il_id: '20', name: 'HONAZ' },
  { id: '1426', il_id: '20', name: 'KALE' },
  { id: '2079', il_id: '20', name: 'MERKEZEFENDİ' },
  { id: '1871', il_id: '20', name: 'PAMUKKALE' },
  { id: '1597', il_id: '20', name: 'SARAYKÖY' },
  { id: '1840', il_id: '20', name: 'SERİNHİSAR' },
  { id: '1670', il_id: '20', name: 'TAVAS' },
  { id: '2040', il_id: '21', name: 'BAĞLAR' },
  { id: '1195', il_id: '21', name: 'BİSMİL' },
  { id: '1249', il_id: '21', name: 'ÇERMİK' },
  { id: '1253', il_id: '21', name: 'ÇINAR' },
  { id: '1263', il_id: '21', name: 'ÇÜNGÜŞ' },
  { id: '1278', il_id: '21', name: 'DİCLE' },
  { id: '1791', il_id: '21', name: 'EĞİL' },
  { id: '1315', il_id: '21', name: 'ERGANİ' },
  { id: '1381', il_id: '21', name: 'HANİ' },
  { id: '1389', il_id: '21', name: 'HAZRO' },
  { id: '2041', il_id: '21', name: 'KAYAPINAR' },
  { id: '1962', il_id: '21', name: 'KOCAKÖY' },
  { id: '1490', il_id: '21', name: 'KULP' },
  { id: '1504', il_id: '21', name: 'LİCE' },
  { id: '1624', il_id: '21', name: 'SİLVAN' },
  { id: '2042', il_id: '21', name: 'SUR' },
  { id: '2043', il_id: '21', name: 'YENİŞEHİR' },
  { id: '1307', il_id: '22', name: 'ENEZ' },
  { id: '1385', il_id: '22', name: 'HAVSA' },
  { id: '1412', il_id: '22', name: 'İPSALA' },
  { id: '1464', il_id: '22', name: 'KEŞAN' },
  { id: '1502', il_id: '22', name: 'LALAPAŞA' },
  { id: '1523', il_id: '22', name: 'MERİÇ' },
  { id: '1295', il_id: '22', name: 'MERKEZ' },
  { id: '1988', il_id: '22', name: 'SÜLOĞLU' },
  { id: '1705', il_id: '22', name: 'UZUNKÖPRÜ' },
  { id: '1110', il_id: '23', name: 'AĞIN' },
  { id: '1873', il_id: '23', name: 'ALACAKAYA' },
  { id: '1762', il_id: '23', name: 'ARICAK' },
  { id: '1173', il_id: '23', name: 'BASKİL' },
  { id: '1438', il_id: '23', name: 'KARAKOÇAN' },
  { id: '1455', il_id: '23', name: 'KEBAN' },
  { id: '1820', il_id: '23', name: 'KOVANCILAR' },
  { id: '1506', il_id: '23', name: 'MADEN' },
  { id: '1298', il_id: '23', name: 'MERKEZ' },
  { id: '1566', il_id: '23', name: 'PALU' },
  { id: '1631', il_id: '23', name: 'SİVRİCE' },
  { id: '1243', il_id: '24', name: 'ÇAYIRLI' },
  { id: '1406', il_id: '24', name: 'İLİÇ' },
  { id: '1459', il_id: '24', name: 'KEMAH' },
  { id: '1460', il_id: '24', name: 'KEMALİYE' },
  { id: '1318', il_id: '24', name: 'MERKEZ' },
  { id: '1977', il_id: '24', name: 'OTLUKBELİ' },
  { id: '1583', il_id: '24', name: 'REFAHİYE' },
  { id: '1675', il_id: '24', name: 'TERCAN' },
  { id: '1853', il_id: '24', name: 'ÜZÜMLÜ' },
  { id: '1153', il_id: '25', name: 'AŞKALE' },
  { id: '1945', il_id: '25', name: 'AZİZİYE' },
  { id: '1235', il_id: '25', name: 'ÇAT' },
  { id: '1392', il_id: '25', name: 'HINIS' },
  { id: '1396', il_id: '25', name: 'HORASAN' },
  { id: '1416', il_id: '25', name: 'İSPİR' },
  { id: '1812', il_id: '25', name: 'KARAÇOBAN' },
  { id: '1444', il_id: '25', name: 'KARAYAZI' },
  { id: '1967', il_id: '25', name: 'KÖPRÜKÖY' },
  { id: '1540', il_id: '25', name: 'NARMAN' },
  { id: '1550', il_id: '25', name: 'OLTU' },
  { id: '1551', il_id: '25', name: 'OLUR' },
  { id: '2044', il_id: '25', name: 'PALANDÖKEN' },
  { id: '1567', il_id: '25', name: 'PASİNLER' },
  { id: '1865', il_id: '25', name: 'PAZARYOLU' },
  { id: '1657', il_id: '25', name: 'ŞENKAYA' },
  { id: '1674', il_id: '25', name: 'TEKMAN' },
  { id: '1683', il_id: '25', name: 'TORTUM' },
  { id: '1851', il_id: '25', name: 'UZUNDERE' },
  { id: '2045', il_id: '25', name: 'YAKUTİYE' },
  { id: '1759', il_id: '26', name: 'ALPU' },
  { id: '1777', il_id: '26', name: 'BEYLİKOVA' },
  { id: '1255', il_id: '26', name: 'ÇİFTELER' },
  { id: '1934', il_id: '26', name: 'GÜNYÜZÜ' },
  { id: '1939', il_id: '26', name: 'HAN' },
  { id: '1808', il_id: '26', name: 'İNÖNÜ' },
  { id: '1508', il_id: '26', name: 'MAHMUDİYE' },
  { id: '1973', il_id: '26', name: 'MİHALGAZİ' },
  { id: '1527', il_id: '26', name: 'MİHALIÇÇIK' },
  { id: '2046', il_id: '26', name: 'ODUNPAZARI' },
  { id: '1599', il_id: '26', name: 'SARICAKAYA' },
  { id: '1618', il_id: '26', name: 'SEYİTGAZİ' },
  { id: '1632', il_id: '26', name: 'SİVRİHİSAR' },
  { id: '2047', il_id: '26', name: 'TEPEBAŞI' },
  { id: '1139', il_id: '27', name: 'ARABAN' },
  { id: '1415', il_id: '27', name: 'İSLAHİYE' },
  { id: '1956', il_id: '27', name: 'KARKAMIŞ' },
  { id: '1546', il_id: '27', name: 'NİZİP' },
  { id: '1974', il_id: '27', name: 'NURDAĞI' },
  { id: '1549', il_id: '27', name: 'OĞUZELİ' },
  { id: '1841', il_id: '27', name: 'ŞAHİNBEY' },
  { id: '1844', il_id: '27', name: 'ŞEHİTKAMİL' },
  { id: '1720', il_id: '27', name: 'YAVUZELİ' },
  { id: '1133', il_id: '28', name: 'ALUCRA' },
  { id: '1212', il_id: '28', name: 'BULANCAK' },
  { id: '1893', il_id: '28', name: 'ÇAMOLUK' },
  { id: '1894', il_id: '28', name: 'ÇANAKÇI' },
  { id: '1272', il_id: '28', name: 'DERELİ' },
  { id: '1912', il_id: '28', name: 'DOĞANKENT' },
  { id: '1320', il_id: '28', name: 'ESPİYE' },
  { id: '1324', il_id: '28', name: 'EYNESİL' },
  { id: '1361', il_id: '28', name: 'GÖRELE' },
  { id: '1930', il_id: '28', name: 'GÜCE' },
  { id: '1465', il_id: '28', name: 'KEŞAP' },
  { id: '1352', il_id: '28', name: 'MERKEZ' },
  { id: '1837', il_id: '28', name: 'PİRAZİZ' },
  { id: '1654', il_id: '28', name: 'ŞEBİNKARAHİSAR' },
  { id: '1678', il_id: '28', name: 'TİREBOLU' },
  { id: '1854', il_id: '28', name: 'YAĞLIDERE' },
  { id: '1458', il_id: '29', name: 'KELKİT' },
  { id: '1822', il_id: '29', name: 'KÖSE' },
  { id: '1971', il_id: '29', name: 'KÜRTÜN' },
  { id: '1369', il_id: '29', name: 'MERKEZ' },
  { id: '1660', il_id: '29', name: 'ŞİRAN' },
  { id: '1684', il_id: '29', name: 'TORUL' },
  { id: '1261', il_id: '30', name: 'ÇUKURCA' },
  { id: '2107', il_id: '30', name: 'DERECİK' },
  { id: '1377', il_id: '30', name: 'MERKEZ' },
  { id: '1656', il_id: '30', name: 'ŞEMDİNLİ' },
  { id: '1737', il_id: '30', name: 'YÜKSEKOVA' },
  { id: '1131', il_id: '31', name: 'ALTINÖZÜ' },
  { id: '2080', il_id: '31', name: 'ANTAKYA' },
  { id: '2081', il_id: '31', name: 'ARSUZ' },
  { id: '1887', il_id: '31', name: 'BELEN' },
  { id: '2082', il_id: '31', name: 'DEFNE' },
  { id: '1289', il_id: '31', name: 'DÖRTYOL' },
  { id: '1792', il_id: '31', name: 'ERZİN' },
  { id: '1382', il_id: '31', name: 'HASSA' },
  { id: '1413', il_id: '31', name: 'İSKENDERUN' },
  { id: '1468', il_id: '31', name: 'KIRIKHAN' },
  { id: '1970', il_id: '31', name: 'KUMLU' },
  { id: '2083', il_id: '31', name: 'PAYAS' },
  { id: '1585', il_id: '31', name: 'REYHANLI' },
  { id: '1591', il_id: '31', name: 'SAMANDAĞ' },
  { id: '1721', il_id: '31', name: 'YAYLADAĞI' },
  { id: '1755', il_id: '32', name: 'AKSU' },
  { id: '1154', il_id: '32', name: 'ATABEY' },
  { id: '1297', il_id: '32', name: 'EĞİRDİR' },
  { id: '1341', il_id: '32', name: 'GELENDOST' },
  { id: '1929', il_id: '32', name: 'GÖNEN' },
  { id: '1456', il_id: '32', name: 'KEÇİBORLU' },
  { id: '1401', il_id: '32', name: 'MERKEZ' },
  { id: '1615', il_id: '32', name: 'SENİRKENT' },
  { id: '1648', il_id: '32', name: 'SÜTÇÜLER' },
  { id: '1651', il_id: '32', name: 'ŞARKİKARAAĞAÇ' },
  { id: '1699', il_id: '32', name: 'ULUBORLU' },
  { id: '1717', il_id: '32', name: 'YALVAÇ' },
  { id: '2001', il_id: '32', name: 'YENİŞARBADEMLİ' },
  { id: '2064', il_id: '33', name: 'AKDENİZ' },
  { id: '1135', il_id: '33', name: 'ANAMUR' },
  { id: '1766', il_id: '33', name: 'AYDINCIK' },
  { id: '1779', il_id: '33', name: 'BOZYAZI' },
  { id: '1892', il_id: '33', name: 'ÇAMLIYAYLA' },
  { id: '1311', il_id: '33', name: 'ERDEMLİ' },
  { id: '1366', il_id: '33', name: 'GÜLNAR' },
  { id: '2065', il_id: '33', name: 'MEZİTLİ' },
  { id: '1536', il_id: '33', name: 'MUT' },
  { id: '1621', il_id: '33', name: 'SİLİFKE' },
  { id: '1665', il_id: '33', name: 'TARSUS' },
  { id: '2066', il_id: '33', name: 'TOROSLAR' },
  { id: '2067', il_id: '33', name: 'YENİŞEHİR' },
  { id: '1103', il_id: '34', name: 'ADALAR' },
  { id: '2048', il_id: '34', name: 'ARNAVUTKÖY' },
  { id: '2049', il_id: '34', name: 'ATAŞEHİR' },
  { id: '2003', il_id: '34', name: 'AVCILAR' },
  { id: '2004', il_id: '34', name: 'BAĞCILAR' },
  { id: '2005', il_id: '34', name: 'BAHÇELİEVLER' },
  { id: '1166', il_id: '34', name: 'BAKIRKÖY' },
  { id: '2050', il_id: '34', name: 'BAŞAKŞEHİR' },
  { id: '1886', il_id: '34', name: 'BAYRAMPAŞA' },
  { id: '1183', il_id: '34', name: 'BEŞİKTAŞ' },
  { id: '1185', il_id: '34', name: 'BEYKOZ' },
  { id: '2051', il_id: '34', name: 'BEYLİKDÜZÜ' },
  { id: '1186', il_id: '34', name: 'BEYOĞLU' },
  { id: '1782', il_id: '34', name: 'BÜYÜKÇEKMECE' },
  { id: '1237', il_id: '34', name: 'ÇATALCA' },
  { id: '2052', il_id: '34', name: 'ÇEKMEKÖY' },
  { id: '2016', il_id: '34', name: 'ESENLER' },
  { id: '2053', il_id: '34', name: 'ESENYURT' },
  { id: '1325', il_id: '34', name: 'EYÜPSULTAN' },
  { id: '1327', il_id: '34', name: 'FATİH' },
  { id: '1336', il_id: '34', name: 'GAZİOSMANPAŞA' },
  { id: '2010', il_id: '34', name: 'GÜNGÖREN' },
  { id: '1421', il_id: '34', name: 'KADIKÖY' },
  { id: '1810', il_id: '34', name: 'KAĞITHANE' },
  { id: '1449', il_id: '34', name: 'KARTAL' },
  { id: '1823', il_id: '34', name: 'KÜÇÜKÇEKMECE' },
  { id: '2012', il_id: '34', name: 'MALTEPE' },
  { id: '1835', il_id: '34', name: 'PENDİK' },
  { id: '2054', il_id: '34', name: 'SANCAKTEPE' },
  { id: '1604', il_id: '34', name: 'SARIYER' },
  { id: '1622', il_id: '34', name: 'SİLİVRİ' },
  { id: '2014', il_id: '34', name: 'SULTANBEYLİ' },
  { id: '2055', il_id: '34', name: 'SULTANGAZİ' },
  { id: '1659', il_id: '34', name: 'ŞİLE' },
  { id: '1663', il_id: '34', name: 'ŞİŞLİ' },
  { id: '2015', il_id: '34', name: 'TUZLA' },
  { id: '1852', il_id: '34', name: 'ÜMRANİYE' },
  { id: '1708', il_id: '34', name: 'ÜSKÜDAR' },
  { id: '1739', il_id: '34', name: 'ZEYTİNBURNU' },
  { id: '1128', il_id: '35', name: 'ALİAĞA' },
  { id: '2006', il_id: '35', name: 'BALÇOVA' },
  { id: '1178', il_id: '35', name: 'BAYINDIR' },
  { id: '2056', il_id: '35', name: 'BAYRAKLI' },
  { id: '1181', il_id: '35', name: 'BERGAMA' },
  { id: '1776', il_id: '35', name: 'BEYDAĞ' },
  { id: '1203', il_id: '35', name: 'BORNOVA' },
  { id: '1780', il_id: '35', name: 'BUCA' },
  { id: '1251', il_id: '35', name: 'ÇEŞME' },
  { id: '2007', il_id: '35', name: 'ÇİĞLİ' },
  { id: '1280', il_id: '35', name: 'DİKİLİ' },
  { id: '1334', il_id: '35', name: 'FOÇA' },
  { id: '2009', il_id: '35', name: 'GAZİEMİR' },
  { id: '2018', il_id: '35', name: 'GÜZELBAHÇE' },
  { id: '2057', il_id: '35', name: 'KARABAĞLAR' },
  { id: '1432', il_id: '35', name: 'KARABURUN' },
  { id: '1448', il_id: '35', name: 'KARŞIYAKA' },
  { id: '1461', il_id: '35', name: 'KEMALPAŞA' },
  { id: '1467', il_id: '35', name: 'KINIK' },
  { id: '1477', il_id: '35', name: 'KİRAZ' },
  { id: '1819', il_id: '35', name: 'KONAK' },
  { id: '1826', il_id: '35', name: 'MENDERES' },
  { id: '1521', il_id: '35', name: 'MENEMEN' },
  { id: '2013', il_id: '35', name: 'NARLIDERE' },
  { id: '1563', il_id: '35', name: 'ÖDEMİŞ' },
  { id: '1611', il_id: '35', name: 'SEFERİHİSAR' },
  { id: '1612', il_id: '35', name: 'SELÇUK' },
  { id: '1677', il_id: '35', name: 'TİRE' },
  { id: '1682', il_id: '35', name: 'TORBALI' },
  { id: '1703', il_id: '35', name: 'URLA' },
  { id: '1756', il_id: '36', name: 'AKYAKA' },
  { id: '1149', il_id: '36', name: 'ARPAÇAY' },
  { id: '1279', il_id: '36', name: 'DİGOR' },
  { id: '1424', il_id: '36', name: 'KAĞIZMAN' },
  { id: '1447', il_id: '36', name: 'MERKEZ' },
  { id: '1601', il_id: '36', name: 'SARIKAMIŞ' },
  { id: '1614', il_id: '36', name: 'SELİM' },
  { id: '1645', il_id: '36', name: 'SUSUZ' },
  { id: '1101', il_id: '37', name: 'ABANA' },
  { id: '1867', il_id: '37', name: 'AĞLI' },
  { id: '1140', il_id: '37', name: 'ARAÇ' },
  { id: '1162', il_id: '37', name: 'AZDAVAY' },
  { id: '1208', il_id: '37', name: 'BOZKURT' },
  { id: '1221', il_id: '37', name: 'CİDE' },
  { id: '1238', il_id: '37', name: 'ÇATALZEYTİN' },
  { id: '1264', il_id: '37', name: 'DADAY' },
  { id: '1277', il_id: '37', name: 'DEVREKANİ' },
  { id: '1915', il_id: '37', name: 'DOĞANYURT' },
  { id: '1940', il_id: '37', name: 'HANÖNÜ' },
  { id: '1805', il_id: '37', name: 'İHSANGAZİ' },
  { id: '1410', il_id: '37', name: 'İNEBOLU' },
  { id: '1499', il_id: '37', name: 'KÜRE' },
  { id: '1450', il_id: '37', name: 'MERKEZ' },
  { id: '1836', il_id: '37', name: 'PINARBAŞI' },
  { id: '1984', il_id: '37', name: 'SEYDİLER' },
  { id: '1845', il_id: '37', name: 'ŞENPAZAR' },
  { id: '1666', il_id: '37', name: 'TAŞKÖPRÜ' },
  { id: '1685', il_id: '37', name: 'TOSYA' },
  { id: '1752', il_id: '38', name: 'AKKIŞLA' },
  { id: '1218', il_id: '38', name: 'BÜNYAN' },
  { id: '1275', il_id: '38', name: 'DEVELİ' },
  { id: '1330', il_id: '38', name: 'FELAHİYE' },
  { id: '1936', il_id: '38', name: 'HACILAR' },
  { id: '1409', il_id: '38', name: 'İNCESU' },
  { id: '1863', il_id: '38', name: 'KOCASİNAN' },
  { id: '1864', il_id: '38', name: 'MELİKGAZİ' },
  { id: '1978', il_id: '38', name: 'ÖZVATAN' },
  { id: '1576', il_id: '38', name: 'PINARBAŞI' },
  { id: '1603', il_id: '38', name: 'SARIOĞLAN' },
  { id: '1605', il_id: '38', name: 'SARIZ' },
  { id: '1846', il_id: '38', name: 'TALAS' },
  { id: '1680', il_id: '38', name: 'TOMARZA' },
  { id: '1715', il_id: '38', name: 'YAHYALI' },
  { id: '1727', il_id: '38', name: 'YEŞİLHİSAR' },
  { id: '1163', il_id: '39', name: 'BABAESKİ' },
  { id: '1270', il_id: '39', name: 'DEMİRKÖY' },
  { id: '1480', il_id: '39', name: 'KOFÇAZ' },
  { id: '1505', il_id: '39', name: 'LÜLEBURGAZ' },
  { id: '1471', il_id: '39', name: 'MERKEZ' },
  { id: '1572', il_id: '39', name: 'PEHLİVANKÖY' },
  { id: '1577', il_id: '39', name: 'PINARHİSAR' },
  { id: '1714', il_id: '39', name: 'VİZE' },
  { id: '1869', il_id: '40', name: 'AKÇAKENT' },
  { id: '1754', il_id: '40', name: 'AKPINAR' },
  { id: '1890', il_id: '40', name: 'BOZTEPE' },
  { id: '1254', il_id: '40', name: 'ÇİÇEKDAĞI' },
  { id: '1429', il_id: '40', name: 'KAMAN' },
  { id: '1472', il_id: '40', name: 'MERKEZ' },
  { id: '1529', il_id: '40', name: 'MUCUR' },
  { id: '2058', il_id: '41', name: 'BAŞİSKELE' },
  { id: '2059', il_id: '41', name: 'ÇAYIROVA' },
  { id: '2060', il_id: '41', name: 'DARICA' },
  { id: '2030', il_id: '41', name: 'DERİNCE' },
  { id: '2061', il_id: '41', name: 'DİLOVASI' },
  { id: '1338', il_id: '41', name: 'GEBZE' },
  { id: '1355', il_id: '41', name: 'GÖLCÜK' },
  { id: '2062', il_id: '41', name: 'İZMİT' },
  { id: '1430', il_id: '41', name: 'KANDIRA' },
  { id: '1440', il_id: '41', name: 'KARAMÜRSEL' },
  { id: '2063', il_id: '41', name: 'KARTEPE' },
  { id: '1821', il_id: '41', name: 'KÖRFEZ' },
  { id: '1868', il_id: '42', name: 'AHIRLI' },
  { id: '1753', il_id: '42', name: 'AKÖREN' },
  { id: '1122', il_id: '42', name: 'AKŞEHİR' },
  { id: '1760', il_id: '42', name: 'ALTINEKİN' },
  { id: '1188', il_id: '42', name: 'BEYŞEHİR' },
  { id: '1207', il_id: '42', name: 'BOZKIR' },
  { id: '1222', il_id: '42', name: 'CİHANBEYLİ' },
  { id: '1902', il_id: '42', name: 'ÇELTİK' },
  { id: '1262', il_id: '42', name: 'ÇUMRA' },
  { id: '1907', il_id: '42', name: 'DERBENT' },
  { id: '1789', il_id: '42', name: 'DEREBUCAK' },
  { id: '1285', il_id: '42', name: 'DOĞANHİSAR' },
  { id: '1920', il_id: '42', name: 'EMİRGAZİ' },
  { id: '1312', il_id: '42', name: 'EREĞLİ' },
  { id: '1933', il_id: '42', name: 'GÜNEYSINIR' },
  { id: '1375', il_id: '42', name: 'HADİM' },
  { id: '1937', il_id: '42', name: 'HALKAPINAR' },
  { id: '1804', il_id: '42', name: 'HÜYÜK' },
  { id: '1400', il_id: '42', name: 'ILGIN' },
  { id: '1422', il_id: '42', name: 'KADINHANI' },
  { id: '1441', il_id: '42', name: 'KARAPINAR' },
  { id: '1814', il_id: '42', name: 'KARATAY' },
  { id: '1491', il_id: '42', name: 'KULU' },
  { id: '1827', il_id: '42', name: 'MERAM' },
  { id: '1598', il_id: '42', name: 'SARAYÖNÜ' },
  { id: '1839', il_id: '42', name: 'SELÇUKLU' },
  { id: '1617', il_id: '42', name: 'SEYDİŞEHİR' },
  { id: '1848', il_id: '42', name: 'TAŞKENT' },
  { id: '1990', il_id: '42', name: 'TUZLUKÇU' },
  { id: '1994', il_id: '42', name: 'YALIHÜYÜK' },
  { id: '1735', il_id: '42', name: 'YUNAK' },
  { id: '1132', il_id: '43', name: 'ALTINTAŞ' },
  { id: '1764', il_id: '43', name: 'ASLANAPA' },
  { id: '1898', il_id: '43', name: 'ÇAVDARHİSAR' },
  { id: '1288', il_id: '43', name: 'DOMANİÇ' },
  { id: '1790', il_id: '43', name: 'DUMLUPINAR' },
  { id: '1304', il_id: '43', name: 'EMET' },
  { id: '1339', il_id: '43', name: 'GEDİZ' },
  { id: '1802', il_id: '43', name: 'HİSARCIK' },
  { id: '1500', il_id: '43', name: 'MERKEZ' },
  { id: '1979', il_id: '43', name: 'PAZARLAR' },
  { id: '1625', il_id: '43', name: 'SİMAV' },
  { id: '1843', il_id: '43', name: 'ŞAPHANE' },
  { id: '1671', il_id: '43', name: 'TAVŞANLI' },
  { id: '1114', il_id: '44', name: 'AKÇADAĞ' },
  { id: '1143', il_id: '44', name: 'ARAPGİR' },
  { id: '1148', il_id: '44', name: 'ARGUVAN' },
  { id: '1772', il_id: '44', name: 'BATTALGAZİ' },
  { id: '1265', il_id: '44', name: 'DARENDE' },
  { id: '1286', il_id: '44', name: 'DOĞANŞEHİR' },
  { id: '1914', il_id: '44', name: 'DOĞANYOL' },
  { id: '1390', il_id: '44', name: 'HEKİMHAN' },
  { id: '1953', il_id: '44', name: 'KALE' },
  { id: '1969', il_id: '44', name: 'KULUNCAK' },
  { id: '1582', il_id: '44', name: 'PÜTÜRGE' },
  { id: '1995', il_id: '44', name: 'YAZIHAN' },
  { id: '1729', il_id: '44', name: 'YEŞİLYURT' },
  { id: '1751', il_id: '45', name: 'AHMETLİ' },
  { id: '1118', il_id: '45', name: 'AKHİSAR' },
  { id: '1127', il_id: '45', name: 'ALAŞEHİR' },
  { id: '1269', il_id: '45', name: 'DEMİRCİ' },
  { id: '1793', il_id: '45', name: 'GÖLMARMARA' },
  { id: '1362', il_id: '45', name: 'GÖRDES' },
  { id: '1470', il_id: '45', name: 'KIRKAĞAÇ' },
  { id: '1965', il_id: '45', name: 'KÖPRÜBAŞI' },
  { id: '1489', il_id: '45', name: 'KULA' },
  { id: '1590', il_id: '45', name: 'SALİHLİ' },
  { id: '1600', il_id: '45', name: 'SARIGÖL' },
  { id: '1606', il_id: '45', name: 'SARUHANLI' },
  { id: '1613', il_id: '45', name: 'SELENDİ' },
  { id: '1634', il_id: '45', name: 'SOMA' },
  { id: '2086', il_id: '45', name: 'ŞEHZADELER' },
  { id: '1689', il_id: '45', name: 'TURGUTLU' },
  { id: '2087', il_id: '45', name: 'YUNUSEMRE' },
  { id: '1107', il_id: '46', name: 'AFŞİN' },
  { id: '1136', il_id: '46', name: 'ANDIRIN' },
  { id: '1785', il_id: '46', name: 'ÇAĞLAYANCERİT' },
  { id: '2084', il_id: '46', name: 'DULKADİROĞLU' },
  { id: '1919', il_id: '46', name: 'EKİNÖZÜ' },
  { id: '1299', il_id: '46', name: 'ELBİSTAN' },
  { id: '1353', il_id: '46', name: 'GÖKSUN' },
  { id: '1975', il_id: '46', name: 'NURHAK' },
  { id: '2085', il_id: '46', name: 'ONİKİŞUBAT' },
  { id: '1570', il_id: '46', name: 'PAZARCIK' },
  { id: '1694', il_id: '46', name: 'TÜRKOĞLU' },
  { id: '2088', il_id: '47', name: 'ARTUKLU' },
  { id: '1787', il_id: '47', name: 'DARGEÇİT' },
  { id: '1273', il_id: '47', name: 'DERİK' },
  { id: '1474', il_id: '47', name: 'KIZILTEPE' },
  { id: '1519', il_id: '47', name: 'MAZIDAĞI' },
  { id: '1526', il_id: '47', name: 'MİDYAT' },
  { id: '1547', il_id: '47', name: 'NUSAYBİN' },
  { id: '1564', il_id: '47', name: 'ÖMERLİ' },
  { id: '1609', il_id: '47', name: 'SAVUR' },
  { id: '2002', il_id: '47', name: 'YEŞİLLİ' },
  { id: '1197', il_id: '48', name: 'BODRUM' },
  { id: '1742', il_id: '48', name: 'DALAMAN' },
  { id: '1266', il_id: '48', name: 'DATÇA' },
  { id: '1331', il_id: '48', name: 'FETHİYE' },
  { id: '1958', il_id: '48', name: 'KAVAKLIDERE' },
  { id: '1488', il_id: '48', name: 'KÖYCEĞİZ' },
  { id: '1517', il_id: '48', name: 'MARMARİS' },
  { id: '2089', il_id: '48', name: 'MENTEŞE' },
  { id: '1528', il_id: '48', name: 'MİLAS' },
  { id: '1831', il_id: '48', name: 'ORTACA' },
  { id: '2090', il_id: '48', name: 'SEYDİKEMER' },
  { id: '1695', il_id: '48', name: 'ULA' },
  { id: '1719', il_id: '48', name: 'YATAĞAN' },
  { id: '1213', il_id: '49', name: 'BULANIK' },
  { id: '1801', il_id: '49', name: 'HASKÖY' },
  { id: '1964', il_id: '49', name: 'KORKUT' },
  { id: '1510', il_id: '49', name: 'MALAZGİRT' },
  { id: '1534', il_id: '49', name: 'MERKEZ' },
  { id: '1711', il_id: '49', name: 'VARTO' },
  { id: '1749', il_id: '50', name: 'ACIGÖL' },
  { id: '1155', il_id: '50', name: 'AVANOS' },
  { id: '1274', il_id: '50', name: 'DERİNKUYU' },
  { id: '1367', il_id: '50', name: 'GÜLŞEHİR' },
  { id: '1374', il_id: '50', name: 'HACIBEKTAŞ' },
  { id: '1485', il_id: '50', name: 'KOZAKLI' },
  { id: '1543', il_id: '50', name: 'MERKEZ' },
  { id: '1707', il_id: '50', name: 'ÜRGÜP' },
  { id: '1876', il_id: '51', name: 'ALTUNHİSAR' },
  { id: '1201', il_id: '51', name: 'BOR' },
  { id: '1225', il_id: '51', name: 'ÇAMARDI' },
  { id: '1904', il_id: '51', name: 'ÇİFTLİK' },
  { id: '1544', il_id: '51', name: 'MERKEZ' },
  { id: '1700', il_id: '51', name: 'ULUKIŞLA' },
  { id: '1119', il_id: '52', name: 'AKKUŞ' },
  { id: '2103', il_id: '52', name: 'ALTINORDU' },
  { id: '1158', il_id: '52', name: 'AYBASTI' },
  { id: '1891', il_id: '52', name: 'ÇAMAŞ' },
  { id: '1897', il_id: '52', name: 'ÇATALPINAR' },
  { id: '1900', il_id: '52', name: 'ÇAYBAŞI' },
  { id: '1328', il_id: '52', name: 'FATSA' },
  { id: '1358', il_id: '52', name: 'GÖLKÖY' },
  { id: '1795', il_id: '52', name: 'GÜLYALI' },
  { id: '1797', il_id: '52', name: 'GÜRGENTEPE' },
  { id: '1947', il_id: '52', name: 'İKİZCE' },
  { id: '1950', il_id: '52', name: 'KABADÜZ' },
  { id: '1951', il_id: '52', name: 'KABATAŞ' },
  { id: '1482', il_id: '52', name: 'KORGAN' },
  { id: '1493', il_id: '52', name: 'KUMRU' },
  { id: '1525', il_id: '52', name: 'MESUDİYE' },
  { id: '1573', il_id: '52', name: 'PERŞEMBE' },
  { id: '1696', il_id: '52', name: 'ULUBEY' },
  { id: '1706', il_id: '52', name: 'ÜNYE' },
  { id: '1146', il_id: '53', name: 'ARDEŞEN' },
  { id: '1228', il_id: '53', name: 'ÇAMLIHEMŞİN' },
  { id: '1241', il_id: '53', name: 'ÇAYELİ' },
  { id: '1908', il_id: '53', name: 'DEREPAZARI' },
  { id: '1332', il_id: '53', name: 'FINDIKLI' },
  { id: '1796', il_id: '53', name: 'GÜNEYSU' },
  { id: '1943', il_id: '53', name: 'HEMŞİN' },
  { id: '1405', il_id: '53', name: 'İKİZDERE' },
  { id: '1949', il_id: '53', name: 'İYİDERE' },
  { id: '1428', il_id: '53', name: 'KALKANDERE' },
  { id: '1586', il_id: '53', name: 'MERKEZ' },
  { id: '1569', il_id: '53', name: 'PAZAR' },
  { id: '2068', il_id: '54', name: 'ADAPAZARI' },
  { id: '1123', il_id: '54', name: 'AKYAZI' },
  { id: '2069', il_id: '54', name: 'ARİFİYE' },
  { id: '2070', il_id: '54', name: 'ERENLER' },
  { id: '1925', il_id: '54', name: 'FERİZLİ' },
  { id: '1351', il_id: '54', name: 'GEYVE' },
  { id: '1391', il_id: '54', name: 'HENDEK' },
  { id: '1955', il_id: '54', name: 'KARAPÜRÇEK' },
  { id: '1442', il_id: '54', name: 'KARASU' },
  { id: '1453', il_id: '54', name: 'KAYNARCA' },
  { id: '1818', il_id: '54', name: 'KOCAALİ' },
  { id: '1833', il_id: '54', name: 'PAMUKOVA' },
  { id: '1595', il_id: '54', name: 'SAPANCA' },
  { id: '2071', il_id: '54', name: 'SERDİVAN' },
  { id: '1986', il_id: '54', name: 'SÖĞÜTLÜ' },
  { id: '1847', il_id: '54', name: 'TARAKLI' },
  { id: '1125', il_id: '55', name: 'ALAÇAM' },
  { id: '1763', il_id: '55', name: 'ASARCIK' },
  { id: '2072', il_id: '55', name: 'ATAKUM' },
  { id: '1879', il_id: '55', name: 'AYVACIK' },
  { id: '1164', il_id: '55', name: 'BAFRA' },
  { id: '2073', il_id: '55', name: 'CANİK' },
  { id: '1234', il_id: '55', name: 'ÇARŞAMBA' },
  { id: '1386', il_id: '55', name: 'HAVZA' },
  { id: '2074', il_id: '55', name: 'İLKADIM' },
  { id: '1452', il_id: '55', name: 'KAVAK' },
  { id: '1501', il_id: '55', name: 'LADİK' },
  { id: '1838', il_id: '55', name: 'SALIPAZARI' },
  { id: '1849', il_id: '55', name: 'TEKKEKÖY' },
  { id: '1676', il_id: '55', name: 'TERME' },
  { id: '1712', il_id: '55', name: 'VEZİRKÖPRÜ' },
  { id: '1993', il_id: '55', name: 'YAKAKENT' },
  { id: '1830', il_id: '55', name: '19 MAYIS' },
  { id: '1179', il_id: '56', name: 'BAYKAN' },
  { id: '1317', il_id: '56', name: 'ERUH' },
  { id: '1495', il_id: '56', name: 'KURTALAN' },
  { id: '1620', il_id: '56', name: 'MERKEZ' },
  { id: '1575', il_id: '56', name: 'PERVARİ' },
  { id: '1662', il_id: '56', name: 'ŞİRVAN' },
  { id: '1878', il_id: '56', name: 'TİLLO' },
  { id: '1156', il_id: '57', name: 'AYANCIK' },
  { id: '1204', il_id: '57', name: 'BOYABAT' },
  { id: '1910', il_id: '57', name: 'DİKMEN' },
  { id: '1290', il_id: '57', name: 'DURAĞAN' },
  { id: '1314', il_id: '57', name: 'ERFELEK' },
  { id: '1349', il_id: '57', name: 'GERZE' },
  { id: '1627', il_id: '57', name: 'MERKEZ' },
  { id: '1981', il_id: '57', name: 'SARAYDÜZÜ' },
  { id: '1693', il_id: '57', name: 'TÜRKELİ' },
  { id: '1870', il_id: '58', name: 'AKINCILAR' },
  { id: '1875', il_id: '58', name: 'ALTINYAYLA' },
  { id: '1282', il_id: '58', name: 'DİVRİĞİ' },
  { id: '1913', il_id: '58', name: 'DOĞANŞAR' },
  { id: '1342', il_id: '58', name: 'GEMEREK' },
  { id: '1927', il_id: '58', name: 'GÖLOVA' },
  { id: '1373', il_id: '58', name: 'GÜRÜN' },
  { id: '1376', il_id: '58', name: 'HAFİK' },
  { id: '1407', il_id: '58', name: 'İMRANLI' },
  { id: '1431', il_id: '58', name: 'KANGAL' },
  { id: '1484', il_id: '58', name: 'KOYULHİSAR' },
  { id: '1628', il_id: '58', name: 'MERKEZ' },
  { id: '1646', il_id: '58', name: 'SUŞEHRİ' },
  { id: '1650', il_id: '58', name: 'ŞARKIŞLA' },
  { id: '1991', il_id: '58', name: 'ULAŞ' },
  { id: '1731', il_id: '58', name: 'YILDIZELİ' },
  { id: '1738', il_id: '58', name: 'ZARA' },
  { id: '1250', il_id: '59', name: 'ÇERKEZKÖY' },
  { id: '1258', il_id: '59', name: 'ÇORLU' },
  { id: '2094', il_id: '59', name: 'ERGENE' },
  { id: '1388', il_id: '59', name: 'HAYRABOLU' },
  { id: '2095', il_id: '59', name: 'KAPAKLI' },
  { id: '1511', il_id: '59', name: 'MALKARA' },
  { id: '1825', il_id: '59', name: 'MARMARAEREĞLİSİ' },
  { id: '1538', il_id: '59', name: 'MURATLI' },
  { id: '1596', il_id: '59', name: 'SARAY' },
  { id: '2096', il_id: '59', name: 'SÜLEYMANPAŞA' },
  { id: '1652', il_id: '59', name: 'ŞARKÖY' },
  { id: '1129', il_id: '60', name: 'ALMUS' },
  { id: '1151', il_id: '60', name: 'ARTOVA' },
  { id: '1883', il_id: '60', name: 'BAŞÇİFTLİK' },
  { id: '1308', il_id: '60', name: 'ERBAA' },
  { id: '1679', il_id: '60', name: 'MERKEZ' },
  { id: '1545', il_id: '60', name: 'NİKSAR' },
  { id: '1834', il_id: '60', name: 'PAZAR' },
  { id: '1584', il_id: '60', name: 'REŞADİYE' },
  { id: '1987', il_id: '60', name: 'SULUSARAY' },
  { id: '1690', il_id: '60', name: 'TURHAL' },
  { id: '1858', il_id: '60', name: 'YEŞİLYURT' },
  { id: '1740', il_id: '60', name: 'ZİLE' },
  { id: '1113', il_id: '61', name: 'AKÇAABAT' },
  { id: '1141', il_id: '61', name: 'ARAKLI' },
  { id: '1150', il_id: '61', name: 'ARSİN' },
  { id: '1775', il_id: '61', name: 'BEŞİKDÜZÜ' },
  { id: '1896', il_id: '61', name: 'ÇARŞIBAŞI' },
  { id: '1244', il_id: '61', name: 'ÇAYKARA' },
  { id: '1909', il_id: '61', name: 'DERNEKPAZARI' },
  { id: '1917', il_id: '61', name: 'DÜZKÖY' },
  { id: '1942', il_id: '61', name: 'HAYRAT' },
  { id: '1966', il_id: '61', name: 'KÖPRÜBAŞI' },
  { id: '1507', il_id: '61', name: 'MAÇKA' },
  { id: '1548', il_id: '61', name: 'OF' },
  { id: '2097', il_id: '61', name: 'ORTAHİSAR' },
  { id: '1647', il_id: '61', name: 'SÜRMENE' },
  { id: '1842', il_id: '61', name: 'ŞALPAZARI' },
  { id: '1681', il_id: '61', name: 'TONYA' },
  { id: '1709', il_id: '61', name: 'VAKFIKEBİR' },
  { id: '1732', il_id: '61', name: 'YOMRA' },
  { id: '1247', il_id: '62', name: 'ÇEMİŞGEZEK' },
  { id: '1397', il_id: '62', name: 'HOZAT' },
  { id: '1518', il_id: '62', name: 'MAZGİRT' },
  { id: '1688', il_id: '62', name: 'MERKEZ' },
  { id: '1541', il_id: '62', name: 'NAZIMİYE' },
  { id: '1562', il_id: '62', name: 'OVACIK' },
  { id: '1574', il_id: '62', name: 'PERTEK' },
  { id: '1581', il_id: '62', name: 'PÜLÜMÜR' },
  { id: '1115', il_id: '63', name: 'AKÇAKALE' },
  { id: '1194', il_id: '63', name: 'BİRECİK' },
  { id: '1209', il_id: '63', name: 'BOZOVA' },
  { id: '1220', il_id: '63', name: 'CEYLANPINAR' },
  { id: '2091', il_id: '63', name: 'EYYÜBİYE' },
  { id: '1378', il_id: '63', name: 'HALFETİ' },
  { id: '2092', il_id: '63', name: 'HALİLİYE' },
  { id: '1800', il_id: '63', name: 'HARRAN' },
  { id: '1393', il_id: '63', name: 'HİLVAN' },
  { id: '2093', il_id: '63', name: 'KARAKÖPRÜ' },
  { id: '1630', il_id: '63', name: 'SİVEREK' },
  { id: '1643', il_id: '63', name: 'SURUÇ' },
  { id: '1713', il_id: '63', name: 'VİRANŞEHİR' },
  { id: '1170', il_id: '64', name: 'BANAZ' },
  { id: '1323', il_id: '64', name: 'EŞME' },
  { id: '1436', il_id: '64', name: 'KARAHALLI' },
  { id: '1704', il_id: '64', name: 'MERKEZ' },
  { id: '1629', il_id: '64', name: 'SİVASLI' },
  { id: '1697', il_id: '64', name: 'ULUBEY' },
  { id: '1770', il_id: '65', name: 'BAHÇESARAY' },
  { id: '1175', il_id: '65', name: 'BAŞKALE' },
  { id: '1786', il_id: '65', name: 'ÇALDIRAN' },
  { id: '1236', il_id: '65', name: 'ÇATAK' },
  { id: '1918', il_id: '65', name: 'EDREMİT' },
  { id: '1309', il_id: '65', name: 'ERCİŞ' },
  { id: '1350', il_id: '65', name: 'GEVAŞ' },
  { id: '1372', il_id: '65', name: 'GÜRPINAR' },
  { id: '2098', il_id: '65', name: 'İPEKYOLU' },
  { id: '1533', il_id: '65', name: 'MURADİYE' },
  { id: '1565', il_id: '65', name: 'ÖZALP' },
  { id: '1980', il_id: '65', name: 'SARAY' },
  { id: '2099', il_id: '65', name: 'TUŞBA' },
  { id: '1117', il_id: '66', name: 'AKDAĞMADENİ' },
  { id: '1877', il_id: '66', name: 'AYDINCIK' },
  { id: '1198', il_id: '66', name: 'BOĞAZLIYAN' },
  { id: '1895', il_id: '66', name: 'ÇANDIR' },
  { id: '1242', il_id: '66', name: 'ÇAYIRALAN' },
  { id: '1245', il_id: '66', name: 'ÇEKEREK' },
  { id: '1952', il_id: '66', name: 'KADIŞEHRİ' },
  { id: '1733', il_id: '66', name: 'MERKEZ' },
  { id: '1982', il_id: '66', name: 'SARAYKENT' },
  { id: '1602', il_id: '66', name: 'SARIKAYA' },
  { id: '1635', il_id: '66', name: 'SORGUN' },
  { id: '1655', il_id: '66', name: 'ŞEFAATLİ' },
  { id: '1998', il_id: '66', name: 'YENİFAKILI' },
  { id: '1726', il_id: '66', name: 'YERKÖY' },
  { id: '1758', il_id: '67', name: 'ALAPLI' },
  { id: '1240', il_id: '67', name: 'ÇAYCUMA' },
  { id: '1276', il_id: '67', name: 'DEVREK' },
  { id: '1313', il_id: '67', name: 'EREĞLİ' },
  { id: '1926', il_id: '67', name: 'GÖKÇEBEY' },
  { id: '2100', il_id: '67', name: 'KİLİMLİ' },
  { id: '2101', il_id: '67', name: 'KOZLU' },
  { id: '1741', il_id: '67', name: 'MERKEZ' },
  { id: '1860', il_id: '68', name: 'AĞAÇÖREN' },
  { id: '1921', il_id: '68', name: 'ESKİL' },
  { id: '1932', il_id: '68', name: 'GÜLAĞAÇ' },
  { id: '1861', il_id: '68', name: 'GÜZELYURT' },
  { id: '1120', il_id: '68', name: 'MERKEZ' },
  { id: '1557', il_id: '68', name: 'ORTAKÖY' },
  { id: '1866', il_id: '68', name: 'SARIYAHŞİ' },
  { id: '2106', il_id: '68', name: 'SULTANHANI' },
  { id: '1767', il_id: '69', name: 'AYDINTEPE' },
  { id: '1788', il_id: '69', name: 'DEMİRÖZÜ' },
  { id: '1176', il_id: '69', name: 'MERKEZ' },
  { id: '1768', il_id: '70', name: 'AYRANCI' },
  { id: '1884', il_id: '70', name: 'BAŞYAYLA' },
  { id: '1316', il_id: '70', name: 'ERMENEK' },
  { id: '1862', il_id: '70', name: 'KAZIMKARABEKİR' },
  { id: '1439', il_id: '70', name: 'MERKEZ' },
  { id: '1983', il_id: '70', name: 'SARIVELİLER' },
  { id: '1880', il_id: '71', name: 'BAHŞİLİ' },
  { id: '1882', il_id: '71', name: 'BALIŞEYH' },
  { id: '1901', il_id: '71', name: 'ÇELEBİ' },
  { id: '1268', il_id: '71', name: 'DELİCE' },
  { id: '1954', il_id: '71', name: 'KARAKEÇİLİ' },
  { id: '1463', il_id: '71', name: 'KESKİN' },
  { id: '1469', il_id: '71', name: 'MERKEZ' },
  { id: '1638', il_id: '71', name: 'SULAKYURT' },
  { id: '1992', il_id: '71', name: 'YAHŞİHAN' },
  { id: '1184', il_id: '72', name: 'BEŞİRİ' },
  { id: '1345', il_id: '72', name: 'GERCÜŞ' },
  { id: '1941', il_id: '72', name: 'HASANKEYF' },
  { id: '1487', il_id: '72', name: 'KOZLUK' },
  { id: '1174', il_id: '72', name: 'MERKEZ' },
  { id: '1607', il_id: '72', name: 'SASON' },
  { id: '1189', il_id: '73', name: 'BEYTÜŞŞEBAP' },
  { id: '1223', il_id: '73', name: 'CİZRE' },
  { id: '1931', il_id: '73', name: 'GÜÇLÜKONAK' },
  { id: '1403', il_id: '73', name: 'İDİL' },
  { id: '1661', il_id: '73', name: 'MERKEZ' },
  { id: '1623', il_id: '73', name: 'SİLOPİ' },
  { id: '1698', il_id: '73', name: 'ULUDERE' },
  { id: '1761', il_id: '74', name: 'AMASRA' },
  { id: '1496', il_id: '74', name: 'KURUCAŞİLE' },
  { id: '1172', il_id: '74', name: 'MERKEZ' },
  { id: '1701', il_id: '74', name: 'ULUS' },
  { id: '1252', il_id: '75', name: 'ÇILDIR' },
  { id: '2008', il_id: '75', name: 'DAMAL' },
  { id: '1356', il_id: '75', name: 'GÖLE' },
  { id: '1380', il_id: '75', name: 'HANAK' },
  { id: '1144', il_id: '75', name: 'MERKEZ' },
  { id: '1579', il_id: '75', name: 'POSOF' },
  { id: '1142', il_id: '76', name: 'ARALIK' },
  { id: '2011', il_id: '76', name: 'KARAKOYUNLU' },
  { id: '1398', il_id: '76', name: 'MERKEZ' },
  { id: '1692', il_id: '76', name: 'TUZLUCA' },
  { id: '2019', il_id: '77', name: 'ALTINOVA' },
  { id: '2020', il_id: '77', name: 'ARMUTLU' },
  { id: '2021', il_id: '77', name: 'ÇINARCIK' },
  { id: '2022', il_id: '77', name: 'ÇİFTLİKKÖY' },
  { id: '1716', il_id: '77', name: 'MERKEZ' },
  { id: '2026', il_id: '77', name: 'TERMAL' },
  { id: '1296', il_id: '78', name: 'EFLANİ' },
  { id: '1321', il_id: '78', name: 'ESKİPAZAR' },
  { id: '1433', il_id: '78', name: 'MERKEZ' },
  { id: '1561', il_id: '78', name: 'OVACIK' },
  { id: '1587', il_id: '78', name: 'SAFRANBOLU' },
  { id: '1856', il_id: '78', name: 'YENİCE' },
  { id: '2023', il_id: '79', name: 'ELBEYLİ' },
  { id: '1476', il_id: '79', name: 'MERKEZ' },
  { id: '2024', il_id: '79', name: 'MUSABEYLİ' },
  { id: '2025', il_id: '79', name: 'POLATELİ' },
  { id: '1165', il_id: '80', name: 'BAHÇE' },
  { id: '1743', il_id: '80', name: 'DÜZİÇİ' },
  { id: '2027', il_id: '80', name: 'HASANBEYLİ' },
  { id: '1423', il_id: '80', name: 'KADİRLİ' },
  { id: '1560', il_id: '80', name: 'MERKEZ' },
  { id: '2028', il_id: '80', name: 'SUMBAS' },
  { id: '2029', il_id: '80', name: 'TOPRAKKALE' },
  { id: '1116', il_id: '81', name: 'AKÇAKOCA' },
  { id: '1784', il_id: '81', name: 'CUMAYERİ' },
  { id: '1905', il_id: '81', name: 'ÇİLİMLİ' },
  { id: '1794', il_id: '81', name: 'GÖLYAKA' },
  { id: '2017', il_id: '81', name: 'GÜMÜŞOVA' },
  { id: '2031', il_id: '81', name: 'KAYNAŞLI' },
  { id: '1292', il_id: '81', name: 'MERKEZ' },
  { id: '1730', il_id: '81', name: 'YIĞILCA' },
];
