import {
  Logo,
  LogoFullColor,
  NasilOkul,
  NasilOkulElement,
} from "../../assets/icon";
import Card from "../Card/Card";
import OkulImage from "../../assets/img/nasil-okul-image.png";
import OkulImage2 from "../../assets/img/nasil-okul-image-2.png";
import style from "./nasilokul.module.scss";
import useWindowDimensions from "../../helpers/windowWidth";
import { useSpring, a, useTrail } from "@react-spring/web";
import { useEffect } from "react";
import Discover from "../Elements/Discover/Discover";
import { useNavigate } from "react-router-dom";

const NasilOkulWrapper = ({
  status,
  doneStep,
  discover1,
  discover2,
  width,
}) => {
  const [logo, setLogo] = useSpring(() => ({
    transform: `translateX(${-50}px)`,
    opacity: 0,
  }));
  const [svg1, setSvg1] = useSpring(() => ({
    transform: `translateY(${-50}px)`,
    opacity: 0,
  }));
  const [svg2, setSvg2] = useSpring(() => ({
    transform: `translateY(${-100}px)`,
    opacity: 0,
  }));

  const [paragraph1, setParagraph1] = useSpring(() => ({
    transform: `translateX(${-100}px)`,
    opacity: 0,
  }));
  const [paragraph2, setParagraph2] = useSpring(() => ({
    transform: `translateX(${-100}px)`,
    opacity: 0,
  }));
  const [paragraph3, setParagraph3] = useSpring(() => ({
    transform: `translateX(${-100}px)`,
    opacity: 0,
  }));
  const [paragraph4, setParagraph4] = useSpring(() => ({
    transform: `translateX(${-100}px)`,
    opacity: 0,
  }));

  const [row1, setRow1] = useSpring(() => ({
    transform: `translateX(${-100}px)`,
    opacity: 0,
  }));

  const [row2, setRow2] = useSpring(() => ({
    transform: `translateX(${-100}px)`,
    opacity: 0,
  }));
  const [row3, setRow3] = useSpring(() => ({
    transform: `translateX(${-100}px)`,
    opacity: 0,
  }));

  const [row4, setRow4] = useSpring(() => ({
    transform: `translateX(${-100}px)`,
    opacity: 0,
  }));
  const [row5, setRow5] = useSpring(() => ({
    transform: `translateX(${-100}px)`,
    opacity: 0,
  }));

  const [part2, setPart2] = useSpring(() => ({
    backgroundColor: `#f7f3ee`,
  }));

  const startAnimation = () => {
    setTimeout(() => {
      setLogo({
        transform: `translateX(${0}px)`,
        opacity: 1,
      });
    }, 100); // Bayetav okulları
    setTimeout(() => {
      setSvg2({
        transform: `translateY(${0}px)`,
        opacity: 1,
      });
    }, 500);
    setTimeout(() => {
      setSvg1({
        transform: `translateY(${0}px)`,
        opacity: 1,
      });
    }, 800);

    setTimeout(() => {
      setParagraph1({
        transform: `translateX(${0}px)`,
        opacity: 1,
      });
    }, 900);
    setTimeout(() => {
      setParagraph2({
        transform: `translateX(${0}px)`,
        opacity: 1,
      });
    }, 1100);
    setTimeout(() => {
      setParagraph3({
        transform: `translateX(${0}px)`,
        opacity: 1,
      });
    }, 1400);
    setTimeout(() => {
      setParagraph4({
        transform: `translateX(${0}px)`,
        opacity: 1,
      });
    }, 1900);

    setTimeout(() => {
      doneStep();
    }, 500);
  };

  const resetAnimation = () => {
    setLogo({
      transform: `translateX(${-50}px)`,
      opacity: 0,
    });
    setSvg2({
      transform: `translateY(${-100}px)`,
      opacity: 0,
    });
    setSvg1({
      transform: `translateY(${-50}px)`,
      opacity: 0,
    });

    setParagraph1({
      transform: `translateX(${-100}px)`,
      opacity: 0,
    });
    setParagraph2({
      transform: `translateX(${-100}px)`,
      opacity: 0,
    });
    setParagraph3({
      transform: `translateX(${-100}px)`,
      opacity: 0,
    });

    setParagraph4({
      transform: `translateX(${-100}px)`,
      opacity: 0,
    });
  };

  const startAnimation1 = () => {
    setTimeout(() => {
      setPart2({
        backgroundColor: "#0055b8",
      });
    }, 500);
    setTimeout(() => {
      setRow1({
        transform: `translateX(${0}px)`,
        opacity: 1,
      });
    }, 500);
    setTimeout(() => {
      setRow2({
        transform: `translateX(${0}px)`,
        opacity: 1,
      });
    }, 700);
    setTimeout(() => {
      setRow3({
        transform: `translateX(${0}px)`,
        opacity: 1,
      });
    }, 900);

    setTimeout(() => {
      setRow4({
        transform: `translateX(${0}px)`,
        opacity: 1,
      });
    }, 1100);
    setTimeout(() => {
      setRow5({
        transform: `translateX(${0}px)`,
        opacity: 1,
      });
    }, 1400);
    setTimeout(() => {
      doneStep();
    }, 500);
  };

  const resetAnimation1 = () => {
    setPart2({
      backgroundColor: `#f7f3ee`,
    });
    setRow1({
      transform: `translateX(${-100}px)`,
      opacity: 0,
    });
    setRow2({
      transform: `translateX(${-100}px)`,
      opacity: 0,
    });
    setRow3({
      transform: `translateX(${-100}px)`,
      opacity: 0,
    });
    setRow4({
      transform: `translateX(${-100}px)`,
      opacity: 0,
    });
    setRow5({
      transform: `translateX(${-100}px)`,
      opacity: 0,
    });
  };

  useEffect(() => {
    if (width > 940) {
      if (status === 2) {
        resetAnimation();
      } else if (status === 1) {
        startAnimation();
      }

      if (status === 3 || status === 1) {
        resetAnimation1();
      } else if (status === 2) {
        startAnimation1();
      }
    } else {
      if (status === 200) {
        resetAnimation();
      } else if (status === 1) {
        startAnimation();
      }

      if (status === 1) {
        resetAnimation1();
      } else if (status === 2) {
        startAnimation1();
      }
    }
  }, [status]);

  const navigate = useNavigate();

  return (
    <>
      {width > 940 ? (
        <div onWheel={(event) => {}} className={style.nasilOkul}>
          <div className={style.part1}>
            <div className={style.top}>
              <a.div style={logo} className={style.logo}>
                <Logo type={"color"} />
              </a.div>
              <a.div style={svg2} className={style.svgWrapper}>
                <a.span style={svg1}>
                  <NasilOkul type={"top"} />
                </a.span>
                <NasilOkul />
              </a.div>
            </div>
            <div className={style.info}>
              <a.p style={paragraph1}>
                Geleceğe bir değer üretmek
                <br /> için yola çıkan
              </a.p>

              <a.p style={paragraph2}>
                Günümüzü doğru analiz
                <br /> ederek geleceği öngören
              </a.p>
              <a.p style={paragraph3}>
                Nitelikli eğitimi erişilebilir
                <br /> kılmak için çalışan
              </a.p>
              <a.p style={paragraph4}>
                Çocukların sesini, velilerin
                <br /> kaygı ve beklentilerini duyan
              </a.p>
              <a.p onClick={() => navigate("/sss")} style={paragraph4}>
                Daha fazla <br />
                bilgi almak için <a href="/sss">S.S.S</a>
              </a.p>
            </div>
            <Discover click={discover1} type={"primary"} />
          </div>
          <a.div style={part2} className={style.part2}>
            <a.div style={row1} className={style.row}>
              <div className={style.column}>
                <NasilOkulElement type={0} />
              </div>
              <div className={style.column}>
                <NasilOkulElement type={1} />
                <p>
                  sosyalleşme, beslenme, güvenlik,
                  <br /> hareket, oyun gibi ihtiyaçların <br /> çocuğun
                  gelişimsel ihtiyaçlarıyla <br /> bir arada değerlendirildiği
                </p>
              </div>
            </a.div>
            <a.div style={row2} className={style.row}>
              <div className={style.column}>
                <NasilOkulElement type={2} />
              </div>
              <div className={style.column}>
                <NasilOkulElement type={3} />
                <p>
                  bir eğitimin her çocuğun hakkı <br /> olduğunun bilincinde
                  olan
                </p>
              </div>
            </a.div>
            <div className={style.infoRow}>
              <a.p style={row3}>
                Okulun tasarımından uygulamasına <br /> tüm süreçlerde
                uzmanların, <br />
                öğretmenlerin, velilerin ve çocukların <br /> aktif katılımla
                yer aldığı
              </a.p>
              <a.p style={row4}>
                Hiyerarşik bir yapı yerine dayanışmayı esas <br /> alan kolektif
                yönetim anlayışına sahip <br /> <br /> Başta çocuklar olmak
                üzere öğretmenlerin, <br /> tüm çalışan personelin, velilerin
                değer ve <br /> özgüven duygusuyla kendilerini ait hissettiği
              </a.p>
              <a.p style={row5}>
                Öğrenci, öğretmen, veli ve okul çalışanlarıyla <br /> tüm
                bileşenlerinin iyi olma halini gözeten
              </a.p>
            </div>
            <Discover click={discover2} />
          </a.div>
        </div>
      ) : (
        <div id="okul" key={"okul"} className={style.nasilOkul}>
          <div className={style.part1}>
            <div className={style.top}>
              <div className={style.logo}>
                <Logo type={"color"} />
              </div>
              <div className={style.svgWrapper}>
                <span>
                  <NasilOkul type={"top"} />
                </span>
                <NasilOkul />
              </div>
            </div>
            <div className={style.info}>
              <p style={paragraph1}>
                Geleceğe bir değer üretmek
                <br /> için yola çıkan
              </p>

              <p style={paragraph2}>
                Günümüzü doğru analiz
                <br /> ederek geleceği öngören
              </p>
              <p style={paragraph3}>
                Nitelikli eğitimi erişilebilir
                <br /> kılmak için çalışan
              </p>
              <p style={paragraph4}>
                Çocukların sesini, velilerin
                <br /> kaygı ve beklentilerini duyan
              </p>
              <p onClick={() => navigate("/sss")} style={paragraph4}>
                Daha fazla <br />
                bilgi almak için <a href="/sss">S.S.S</a>
              </p>
            </div>
            <Discover click={discover1} type={"primary"} />
          </div>
          <div className={style.part2}>
            <div className={style.row}>
              <div className={style.column}>
                <NasilOkulElement type={0} />
              </div>
              <div className={style.column}>
                <NasilOkulElement type={1} />
                <p>
                  sosyalleşme, beslenme, güvenlik,
                  <br /> hareket, oyun gibi ihtiyaçların <br /> çocuğun
                  gelişimsel ihtiyaçlarıyla <br /> bir arada değerlendirildiği
                </p>
              </div>
            </div>
            <div className={style.row}>
              <div className={style.column}>
                <NasilOkulElement type={2} />
              </div>
              <div className={style.column}>
                <NasilOkulElement type={3} />
                <p>bir eğitimin her çocuğun hakkı olduğunun bilincinde olan</p>
              </div>
            </div>
            <div className={style.infoRow}>
              <p>
                Okulun tasarımından uygulamasına <br /> tüm süreçlerde
                uzmanların, <br />
                öğretmenlerin, velilerin ve çocukların <br /> aktif katılımla
                yer aldığı
              </p>
              <p>
                Hiyerarşik bir yapı yerine dayanışmayı esas <br /> alan kolektif
                yönetim anlayışına sahip <br /> <br /> Başta çocuklar olmak
                üzere öğretmenlerin, <br /> tüm çalışan personelin, velilerin
                değer ve <br /> özgüven duygusuyla kendilerini ait hissettiği
              </p>
              <p>
                Öğrenci, öğretmen, veli ve okul çalışanlarıyla <br /> tüm
                bileşenlerinin iyi olma halini gözeten
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default NasilOkulWrapper;
