import style from './whatsappcard.module.scss';
import { DiscoverArrow, WhatsappIcon } from '../../../assets/icon';

const WhatsappCard = ({ click, status }) => {
  return (
    <div
      //   onClick={click}
      className={`${style.cardWrapper} ${status ? style.active : null}`}
    >
      <a
        href="https://wa.me/+905306687707"
        target="__blank"
        className={style.card}
      >
        <WhatsappIcon />

        <h2>0530 668 77 07</h2>
      </a>
      {/* <div className={`${style.content} ${style.active}`}>
        <p>
          Sizinle iletişime geçebilmemiz ve randevu oluşturabilmemiz için lütfen
          formu doldurun.
        </p>
        <button>
          İLETİŞİM <DiscoverArrow color={'primary'} />
        </button>
      </div> */}
    </div>
  );
};

export default WhatsappCard;
