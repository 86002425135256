import Image1 from "../../assets/img/image-1.png";
import Image2 from "../../assets/img/image-2.png";
import style from "./imagewrapper.module.scss";
import { useSpring, a, useTrail } from "@react-spring/web";
import { useEffect } from "react";
import Discover from "../Elements/Discover/Discover";

const ImageWrapper = ({ second, status, doneStep, width, discover1 }) => {
  const [info1, setInfo1] = useSpring(() => ({
    transform: `translateX(${-100}px)`,
    opacity: 0,
  }));
  const [info2, setInfo2] = useSpring(() => ({
    transform: `translateX(${-100}px)`,
    opacity: 0,
  }));
  const [info3, setInfo3] = useSpring(() => ({
    transform: `translateX(${-100}px)`,
    opacity: 0,
  }));

  const [info4, setInfo4] = useSpring(() => ({
    transform: `translateX(${-100}px)`,
    opacity: 0,
  }));

  const startAnimation = () => {
    setTimeout(() => {
      setInfo1({
        transform: `translateX(${0}px)`,
        opacity: 1,
      });
    }, 500);
    setTimeout(() => {
      setInfo2({
        transform: `translateX(${0}px)`,
        opacity: 1,
      });
    }, 700);
    setTimeout(() => {
      setInfo3({
        transform: `translateX(${0}px)`,
        opacity: 1,
      });
    }, 900);
    setTimeout(() => {
      setInfo4({
        transform: `translateX(${0}px)`,
        opacity: 1,
      });
    }, 1100);
    setTimeout(() => {
      doneStep();
    }, 1000);
  };

  const resetAnimation = () => {
    setInfo1({
      transform: `translateX(${-100}px)`,
      opacity: 0,
      immediate: true,
    });
    setInfo2({
      transform: `translateX(${-100}px)`,
      opacity: 0,
      immediate: true,
    });
    setInfo3({
      transform: `translateX(${-100}px)`,
      opacity: 0,
      immediate: true,
    });
    setInfo4({
      transform: `translateX(${-100}px)`,
      opacity: 0,
      immediate: true,
    });
  };

  useEffect(() => {
    if (width > 940) {
      if (status === 4 || status === 2) {
        resetAnimation();
      } else if (status === 3) {
        startAnimation();
      }

      if (status === 16 || status === 14) {
        resetAnimation();
      } else if (status === 15) {
        startAnimation();
      }
    } else {
      if (status === 6) {
        resetAnimation();
      } else if (status === 7) {
        startAnimation();
      }

      if (status === 26) {
        resetAnimation();
      } else if (status === 27) {
        startAnimation();
      }
    }
  }, [status]);

  return (
    <>
      {width > 940 ? (
        <div className={style.imageWrapper}>
          <img src={second ? Image2 : Image1} />
          <div className={style.cardWrapper}>
            {second ? (
              <>
                <a.div style={info1} className={style.card}>
                  <span>Sağlıklı ve Temiz</span>
                  <br />
                  İklimlendirme ve
                  <br /> Havalandırma
                </a.div>
                <a.div style={info2} className={style.card}>
                  <span>Depremde </span>
                  <br />
                  Sıfır Riskli Bina
                </a.div>
                <a.div style={info3} className={style.card}>
                  <span>Yenilenebilir Enerji </span>
                  <br />
                  Uygulamaları
                </a.div>
                <a.div style={info4} className={style.card}>
                  <span>Erişilebilir</span> Evrensel Tasarım
                </a.div>
              </>
            ) : (
              <>
                <a.div style={info1} className={style.card}>
                  6 OKUL ÖNCESİ , <span>12 İLKOKUL,</span> <br />
                  <span>8 ORTAOKUL,</span> 4 LİSE DERSLİĞİ
                </a.div>
                <a.div style={info2} className={style.card}>
                  HER YAŞ GRUBUNA ÖZEL
                  <br />
                  <span>TEMATİK BAHÇELER</span>
                </a.div>
                <a.div style={info3} className={style.card}>
                  KONUM: <span>BORNOVA / KAVAKLIDERE</span>
                </a.div>
              </>
            )}
          </div>
          <Discover click={discover1} />
        </div>
      ) : (
        <div className={style.imageWrapper}>
          <img src={second ? Image2 : Image1} />
          <div className={style.cardWrapper}>
            {second ? (
              <>
                <div className={style.card}>
                  <span>Sağlıklı ve Temiz</span>
                  <br />
                  İklimlendirme ve
                  <br /> Havalandırma
                </div>
                <div className={style.card}>
                  <span>Depremde </span>
                  <br />
                  Sıfır Riskli Bina
                </div>
                <div className={style.card}>
                  <span>Yenilenebilir Enerji </span>
                  <br />
                  Uygulamaları
                </div>
                <div className={style.card}>
                  <span>Erişilebilir</span> Evrensel Tasarım
                </div>
              </>
            ) : (
              <>
                <div className={style.card}>
                  6 OKUL ÖNCESİ , <span>12 İLKOKUL,</span> <br />
                  <span>8 ORTAOKUL,</span> 4 LİSE DERSLİĞİ
                </div>
                <div className={style.card}>
                  HER YAŞ GRUBUNA ÖZEL
                  <br />
                  <span>TEMATİK BAHÇELER</span>
                </div>
                <div className={style.card}>
                  KONUM: <span>BORNOVA / KAVAKLIDERE</span>
                </div>
              </>
            )}
          </div>
          {/* <Discover click={discover1} /> */}
        </div>
      )}
    </>
  );
};

export default ImageWrapper;
