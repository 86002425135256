import { OgretmenForm, NavBar, WhatsappCard } from '../../components';
import style from './faq.module.scss';
import {
  Logo,
  FAQArrow,
  FAQDesign1,
  FAQDesign2,
  FAQDesign3,
  FAQDesign4,
} from '../../assets/icon';
import useWindowDimensions from '../../helpers/windowWidth';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { PopupKvkk, ContactCard } from '../../components';
import { useRef } from 'react';
import axios from 'axios';

const FAQCard = ({ title, text, click, isOpen }) => {
  return (
    <div className={`${style.faqCard} ${isOpen ? style.active : null}`}>
      <div onClick={click} className={style.top}>
        <h4>{title}</h4>
        <button>
          <FAQArrow />
        </button>
      </div>
      <p>{text}</p>
    </div>
  );
};

const FAQ = () => {
  const { width } = useWindowDimensions();
  const navigate = useNavigate();

  const [isOpen, setOpen] = useState([]);
  const [data, setData] = useState();

  //   const data = [
  //     {
  //       title:
  //         'Okul binanız 2024 - 2025 eğitim öğretim döneminde hazır olacak mı?',
  //       text: 'Okul binamız 2024 Mayısta bitecek ve  haziran ayında ruhsatımızı alacağız ve 1 Ağustosta öğretmenlerimizin mesleki gelişim atölyeleri, planlama faaliyetleri başlayacak.',
  //     },
  //     {
  //       title: 'Okul ücret politikanız nedir?',
  //       text: 'Okul binamız 2024 Mayısta bitecek ve  haziran ayında ruhsatımızı alacağız ve 1 Ağustosta öğretmenlerimizin mesleki gelişim atölyeleri, planlama faaliyetleri başlayacak.',
  //     },
  //   ];

  useEffect(() => {
    axios
      .get('https://bayetav-backend.vercel.app/faq')
      .then(res => {
        setData(
          res.data['records']
            .sort((a, b) => a.fields.Sira - b.fields.Sira)
            .map(item => {
              return {
                title: item.fields.Soru,
                text: item.fields.Cevap,
              };
            })
        );
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  return (
    <div className={style.ogretmenBasvur}>
      <ContactCard
        click={() => {
          navigate('/iletisim');
        }}
        status={true}
        width={width}
      />
      <WhatsappCard
        click={() => {
          navigate('/iletisim');
        }}
        status={true}
        width={width}
      />
      <NavBar
        width={width}
        status={true}
        current={0}
        navChange={() => console.log()}
        okulChange={() => navigate('/')}
        ogrenciChange={() => navigate('/')}
        ogretmenChange={() => navigate('/')}
        veliChange={() => navigate('/')}
        contactChange={() => navigate('/iletisim')}
        sssChange={() => {
          navigate('/sss');
        }}
        brosurChange={() => {
          navigate('/brosur');
        }}
      />
      {data ? (
        <div className={style.content}>
          <div className={style.left}>
            <h1>Sıkça Sorulan Sorular</h1>
            <div className={style.cardWrapper}>
              {data.map((item, index) => {
                const inArr = isOpen.includes(index);
                return (
                  <FAQCard
                    isOpen={inArr}
                    title={item.title}
                    text={item.text}
                    click={() => {
                      if (inArr) {
                        setOpen(isOpen.filter(i => i !== index));
                      } else {
                        setOpen([...isOpen, index]);
                      }
                    }}
                  />
                );
              })}
            </div>
          </div>
          <div className={style.faqDesign}>
            <FAQDesign1 />
            <FAQDesign2 />
            <FAQDesign3 />
            <FAQDesign4 />
          </div>
        </div>
      ) : (
        <div className={style.loading}>
          {' '}
          <div className={style.ldsRipple}>
            <div></div>
            <div></div>
          </div>
        </div>
      )}
    </div>
  );
};

export default FAQ;
