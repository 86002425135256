import style from './popup.module.scss';
import { CloseIcon } from '../../assets/icon';
import { useState } from 'react';

const PopupKvkk = ({ closeClick, ogretmen }) => {
  const [isContent, setContent] = useState(false);

  return (
    <div
      onClick={() => {
        if (!isContent) {
          closeClick();
        }
      }}
      className={style.popup}
    >
      <div
        onMouseEnter={() => setContent(true)}
        onMouseLeave={() => setContent(false)}
        className={style.content}
      >
        <div className={style.topRow}>
          <h3> Kişisel Verilerinin İşlenmesine İlişkin Aydınlatma Metni</h3>
          <button onClick={closeClick}>
            <CloseIcon />
          </button>
        </div>
        {ogretmen ? (
          <div className={style.textContent}>
            <div className={style.row}>
              <h2>
                BAYETAV OKULLARI ÇALIŞAN ADAYI KİŞİSEL VERİLERİNİN KORUNMASI
                AYDINLATMA METNİ
              </h2>
              <p>
                İşbu Aydınlatma Metni’nde, kişisel verilerinizin, 6698 sayılı
                Kişisel Verilerin Korunması Kanunu (“Kanun”) ve ilgili mevzuata
                uygun olarak, veri sorumlusu Merkezi Kemalpaşa Osb Mah. 16 sokak
                No:2/2 Kemalpaşa / İzmir adresinde bulunan adresinde yer alan
                Katharsis Özel Eğitim Hizmetleri A.Ş. (BAYETAV OKULLARI)
                nezdinde işlenmesine ilişkin esaslar aşağıda belirtilmiştir.   
                 
              </p>
            </div>
            <div className={style.row}>
              <h2>1. Kişisel Verilerin İşlenme Amacı </h2>
              <p>
                Kurumumuzda çalışmak için yapmış olduğunuz başvuru neticesinde
                toplanan kişisel verileriniz, Kanun tarafından öngörülen temel
                ilkelere uygun olarak ve Kanun’un 5. ve 6. maddelerinde
                belirtilen kişisel veri işleme şartları ve amaçları dâhilinde;
                işe giriş sürecinin yürütülmesine dayalı sözleşmesel ilişkisi
                kapsamında iş başvurunuzun alınması, özgeçmişinizin incelenmesi
                ve ilgili pozisyona uygunluğunuzun değerlendirilmesi, sağlık
                durumunuzun ilgili pozisyona elverişliliğinin değerlendirilmesi,
                işe alım süreçlerinin yürütülmesi ve bu kapsamda sizinle
                iletişime geçilmesi amaçlarıyla işlenmektedir.
              </p>
            </div>
            <div className={style.row}>
              <h2>
                2. İşlenen Kişisel Verilerin Aktarıldığı Yerler ve Aktarım Amacı{' '}
              </h2>
              <p>
                Kişisel verileriniz, Kanun’a uygun olarak iş ortaklarımıza, işe
                alım faaliyetini yürütmemizi sağlayan tedarikçilere, alt
                işverenlere, Kurumumuzun sözleşme ilişkisi içerisinde olduğu
                yurtiçi ve/veya yurtdışındaki 3. taraflar ile ilgili resmî
                kurumlara ve istisnai olarak özel kişilere aktarılabilmektedir.
                Kişisel verilerin işlenme amacı ile verilerin aktarım amacı
                paralellik göstermektedir.
                <br />
                <br />
                Kurumumuz iş süreçlerinin, çalışma saatlerinin ve özlük
                dosyasında yer alan belgelerin oluşturulması veya saklanması
                gibi yardımcı işler için aracı kurumlar ile iş birliği
                yapabilir. Kişisel verileriniz bu kurumlar ile görevlerini
                yerine getirebilmeleri için Kanun’a uygun olarak
                paylaşılabilecek olup, ilgili kurumların bu verileri başka
                herhangi bir faaliyet için kullanma hakları bulunmadığını
                belirtiriz.
              </p>
            </div>
            <div className={style.row}>
              <h2>3. Kişisel Verilerin Toplanma Yöntemi ve Hukuki Sebebi </h2>
              <p>
                Kişisel verileriniz işe alım süreçlerinin yürütülmesi kapsamında
                internet ortamında internet sitelerimiz, iş başvurusu yapmanıza
                imkân tanıyan çeşitli internet siteleri vasıtasıyla, Türkiye İş
                Kurumu kanalıyla veya fiziki ortamda, iş başvuru talebinizin
                veya ilgili pozisyona uygunluğunuzun değerlendirilmesi ve
                sonuçlandırılması hukuki sebebiyle toplanmaktadır. Bu süreç
                kapsamında toplanan kişisel verileriniz, Kanun’un 5. ve 6.
                maddelerinde belirtilen kişisel veri işleme şartları ve amaçları
                dâhilinde işbu Aydınlatma Metninde belirtilen amaçlarla
                işlenebilecek ve aktarılabilecektir.
                <br />
                <br />
                Kişisel verilerinizi işlememizi gerektiren hukuki
                yükümlülüklerimiz 6698 sayılı Kişisel Verilerin Korunması
                Kanunu, 4857 sayılı İş Kanunu, 5237 Sayılı Türk Ceza Kanunu’ndan
                doğmaktadır. Bununla birlikte, kişisel verileriniz Kanun’un 5.
                ve 6. maddelerinde düzenlenen (i) bir sözleşmenin kurulması veya
                ifasıyla doğrudan doğruya ilgili olması kaydıyla, sözleşmenin
                taraflarına ait kişisel verilerin işlenmesinin gerekli olması,
                (ii) ilgili kişinin temel hak ve özgürlüklerine zarar vermemek
                kaydıyla, veri sorumlusunun meşru menfaatleri için veri
                işlenmesinin zorunlu olması ve (iii) açık rızanızın temin
                edilmiş olması hukuki sebeplerine dayalı olarak işlenmektedir.
              </p>
            </div>
            <div className={style.row}>
              <h2>4. Veri Sorumlusuna Başvuru Yolları ve Haklarınız </h2>
              <p>
                Kanun’un 11. maddesi uyarınca, Kurumumuza başvurarak, kişisel
                verilerinizin; a) işlenip işlenmediğini öğrenme, b) işlenmişse
                bilgi talep etme, c) işlenme amacını ve amacına uygun kullanılıp
                kullanılmadığını öğrenme, d) yurt içinde / yurt dışında transfer
                edildiği tarafları öğrenme, e) eksik / yanlış işlenmişse
                düzeltilmesini isteme, f) Kanun’un 7. maddesinde öngörülen
                şartlar çerçevesinde silinmesini/yok edilmesini isteme, g)
                aktarıldığı 3. kişilere yukarıda sayılan (e) ve (f) bentleri
                uyarınca yapılan işlemlerin bildirilmesini isteme, h) münhasıran
                otomatik sistemler ile analiz edilmesi nedeniyle aleyhinize bir
                sonucun ortaya çıkmasına itiraz etme, i) Kanun’a aykırı olarak
                işlenmesi sebebiyle zarara uğramanız hâlinde zararın
                giderilmesini talep etme hakkına sahipsiniz.   <br />
                <br />
                Yukarıda sıralanan haklarınıza yönelik başvurularınızı, internet
                sitemizden ulaşabileceğiniz www.bayetavokullari.com adresinde
                yer alan başvuru formunu doldurarak, BAYETAV
                OKULLARI’nın “Kemalpaşa Osb Mah. 16 sokak no:2/2
                Kemalpaşa/İzmir”  adresine posta yoluyla
                veya info@bayetavokullari.com adresine e-posta göndererek
                iletebilirsiniz.
                <br />
                <br />
                BAYETAV taleplerinizi, talebin niteliğine göre en kısa sürede ve
                en geç otuz gün içinde ücretsiz olarak sonuçlandırır. Ancak,
                işlemin ayrıca bir maliyeti gerektirmesi hâlinde, ücret
                alınabilir. Kurumumuz talebi kabul edip işleme koyabilir veya
                gerekçesini açıklayarak talebi yazılı usulle reddedebilir.
                <br />
                <br />
                Yukarıda belirtilen prosedür takip edilerek gerçekleştirilen
                başvurunun reddedilmesi, verilen cevabın yetersiz bulunması veya
                süresinde başvuruya cevap verilmemesi hâllerinde; cevabın
                tebliğini takiben otuz ve herhâlde başvuru tarihinden itibaren
                altmış gün içinde Kişisel Verileri Koruma Kurulu’na şikâyette
                bulunma hakkı mevcuttur. Ancak başvuru yolu tüketilmeden şikâyet
                yoluna başvurulamaz.
                <br />
                <br />
                Kurul, şikâyet üzerine veya ihlal iddiasını öğrenmesi durumunda
                resen, görev alanına giren konularda gerekli incelemeyi yapar.
                Şikâyet üzerine Kurul, talebi inceleyerek ilgililere bir cevap
                verir. Şikâyet tarihinden itibaren altmış gün içinde cevap
                verilmezse talep reddedilmiş sayılır. Şikâyet üzerine veya resen
                yapılan inceleme sonucunda, ihlalin varlığının anlaşılması
                hâlinde Kurul, tespit ettiği hukuka aykırılıkların veri
                sorumlusu tarafından giderilmesine karar vererek ilgililere
                tebliğ eder. Bu karar, tebliğden itibaren gecikmeksizin ve en
                geç otuz gün içinde yerine getirilir. Kurul, telafisi güç veya
                imkânsız zararların doğması ve açıkça hukuka aykırılık olması
                hâlinde, veri işlenmesinin veya verinin yurt dışına
                aktarılmasının durdurulmasına karar verebilir.
                <br />
                <br />
                Verilerinizin BAYETAV nezdinde hassasiyetle ve titizlikle
                korunduğunu belirtir, bize duyduğunuz güven için teşekkür
                ederiz.
              </p>
            </div>
          </div>
        ) : (
          <div className={style.textContent}>
            <div className={style.row}>
              <h2>
                Veli / Vasiler ve Öğrencileri İçin Kişisel Verilerinin
                İşlenmesine İlişkin Aydınlatma Metni
              </h2>
              <p>
                Merkezi Kemalpaşa Osb Mah. 16 sokak No:2/2 Kemalpaşa / İzmir
                adresinde bulunan, İzmir Ticaret Sicil Müdürlüğü nezdinde
                1-175638 Sicil Numarası ile kayıtlı Katharsis Özel Eğitim
                Hizmetleri Anonim Şirketi’nin (“Şirket”, “BAYETAV” veya “Veri
                Sorumlusu”) olarak, kişisel verilerinizin 6698 sayılı Kişisel
                Verilerin Korunması Hakkında Kanun’a (“Kanun”) uygun olarak
                işlenmesine ve saklanmasına büyük önem vermekte, kişisel
                verilerinizin güvenliğini büyük bir hassasiyetle sağlamaktayız.
                <br />
                <br />
                BAYETAV olarak, Kanun kapsamında işlemiş olduğumuz kişisel
                veriler bakımından sizlere, bir başka ifadeyle kişisel verileri
                işlenen ilgili kişilere karşı “veri sorumlusu” sıfatını
                taşımaktayız. Bu doğrultuda işbu aydınlatma metni ile siz
                veli/vasilerimizi kişisel verileri toplama, işleme, amaçlarımız,
                yöntemlerimiz ve sizlerin Kanun’dan kaynaklanan haklarınızla
                ilgili bilgilendirmek isteriz.{' '}
              </p>
            </div>
            <div className={style.row}>
              <h2>2. Kişisel Verinin Tanımı </h2>
              <p>
                Kişisel veri, Kanun’un 3. maddesinde tanımlandığı üzere kimliği
                belirli veya belirlenebilir gerçek kişiye ilişkin her türlü
                bilgi olup, işbu Aydınlatma Metni içeriğinde bahsedilen kişisel
                veriyi oluşturmaktadır.{' '}
              </p>
            </div>
            <div className={style.row}>
              <h2>3. Kişisel Verilerin Toplanma Yöntemi ve Hukuki Sebebi </h2>
              <p>
                Kişisel verileriniz ve/veya özel nitelikli kişisel verileriniz,
                BAYETAV veya BAYETAV adına veri işleyen gerçek ya da tüzel
                kişiler tarafından; sözlü, yazılı veya elektronik kanallar
                aracılığı ile toplanmakta olup; toplanan kişisel verileriniz
                ve/veya özel nitelikli kişisel verileriniz, 6698 sayılı Kanun’un
                5. ve 6. maddelerinde belirtilen kişisel veri işleme şartları ve
                amaçları kapsamında işbu Aydınlatma Metni’nin 4.maddesinde
                belirtilen amaçlarla işlenebilmektedir.
              </p>
            </div>
            <div className={style.row}>
              <h2>4. Kişisel Verilerin İşlenmesi ve Amaçlar </h2>
              <p>
                Veri Sorumlusu’nun yürütmüş olduğu tüm kişisel veri işleme
                faaliyetlerinde Kanun başta olmak üzere, ilgili tüm mevzuatta
                aranan yükümlüklere de uygun olarak hareket edilmektedir.
                <br />
                <br />
                Kişisel verilerinizin işlenme amaçları Kanun’un 5. ve 6.
                maddelerinde belirtilen kişisel veri işleme şartları ve amaçları
                dâhilinde Veri Sorumlusu tarafından sunulan hizmetlerden sizleri
                faydalandırmak için gerekli çalışmaların iş birimlerimiz
                tarafından yapılması; tarafınıza sunulan hizmetlerin sizlerin
                beğeni, kullanım alışkanlıkları ve ihtiyaçlarına göre
                özelleştirilerek sizlere önerilmesi; Veri Sorumlusu ile iş
                ilişkisi içerisinde olan kişilerin hukuki ve ticari güvenliğinin
                temini ile ticari ve iş stratejilerinin belirlenmesi ve
                uygulanmasıdır.
                <br />
                <br />
                Kişisel verileriniz BAYETAV tarafından sizinle aramızda bulunan
                ilişki kapsamında Kanun’un 5/2. maddesinde düzenlenen amaçlardan
                “Kanunlarda açıkça öngörülmesi”, “ilgili kişinin temel hak ve
                özgürlüklerine zarar vermemek kaydıyla, veri sorumlusunun meşru
                menfaatleri için veri işlenmesinin zorunlu olması”, “Bir
                sözleşmenin kurulması veya ifasıyla doğrudan doğruya ilgili
                olması kaydıyla, sözleşmenin taraflarına ait kişisel verilerin
                işlenmesinin gerekli olması” ve “Veri sorumlusunun hukuki
                yükümlülüğünü yerine getirebilmesi için zorunlu olması” hükmü
                kapsamında aşağıdaki hususlarla ilişkili olarak faaliyetlerimizi
                yürütmek amacıyla aşağıdaki hususlarla ilişkili olarak
                işlenmektedir:
                <br />
                <br />
                § Tarafımıza iletilen bilgilerin güncellenmesi ve doğruluğunun
                teyidi, <br />
                § Sözleşmesel ilişki kapsamındaki yükümlülüklerin gereği gibi
                ifa edilmesinin sağlanması, <br />
                § Müşteri ilişkilerinin yürütülmesi ve icrası <br />§ Kurumsal
                sürdürülebilirlik, kurumsal yönetim, stratejik planlama ve bilgi
                güvenliği süreçlerinin planlanması ve icrası, <br />
                § Finans ve muhasebe işleri, <br />
                § Güvenlik sebebiyle ve/veya kanundan kaynaklanan
                yükümlülüklerimiz kapsamında BAYETAV bina içi ve dışarısında
                kamera görüntülerinin kaydedilmesi, <br />§ Öğrencilerin
                kişisel, akademik, sosyal ve psikolojik durumlarının
                değerlendirilmesi ve gelişiminin desteklenmesi
                <br />
                <br />
                BAYETAV olarak, veri sorumlusu sıfatı ile; Kanun’un 5. ve 6.
                maddelerinde yer alan hukuka uygunluk sebepleri çerçevesinde
                elde ettiğimiz kişisel verileriniz, tamamen veya kısmen elde
                edilebilir, kaydedilebilir, saklanabilir, depolanabilir,
                değiştirilebilir, güncellenebilir, periyodik olarak kontrol
                edilebilir, yeniden düzenlenebilir, sınıflandırılabilir,
                işlendikleri amaç için gerekli olan ya da ilgili kanunda
                öngörülen süre kadar muhafaza edilebilir. Belirtmek gerekir ki;
                BAYETAV, kişisel verilerinizi hukuka ve dürüstlük kurallarına
                uygun, doğru ve gerektiğinde güncel, belirli, açık ve meşru
                amaçlar çerçevesinde, işlendikleri amaçla bağlantılı, sınırlı ve
                ölçülü ve ilgili mevzuatta öngörülen veya işlendikleri amaç için
                gerekli olan süre kadar muhafaza etmek suretiyle işlemektedir.
                Kişisel verileriniz Kanun’da açıkça belirtilmediği sürece,
                verinin saklanması için geçerli sebebin ve amacın varlığının
                devamı süresince işlenecek, işleme amaç ve sebebinin ortadan
                kalkması veya sona ermesi halinde, silinecek, yok edilecek
                veyahut da anonim hale getirilecektir.
              </p>
            </div>
            <div className={style.row}>
              <h2>5. İşlenen Kişisel Verileriniz </h2>
              <p>
                Şirket yukarıda ifade edildiği gibi size ait birtakım kişisel
                verileri/özel nitelikli kişisel verileri kaydetmekte ve
                işlemektedir. Bu bağlamda BAYETAV’in sizlerle ilgili olarak
                işlediği veriler aşağıdaki gibidir:
                <br />
                <br />• Kimlik: İsim, soy isim, T.C. Kimlik numarası, doğum
                yeri, doğum tarihi, imza, pasaport numarası, vatandaşlık
                numarası, yabancı kimlik numarası, cinsiyet, nüfus cüzdanı seri
                numarası nüfus cüzdanı sıra numarası, nüfus cüzdanı aile seri
                numarası, nüfus cüzdanı cilt numarası, nüfusa kayıtlı olunan
                yer, anne adı, baba adı, kardeş adı, medeni hal, uyruk, acil
                durumda ulaşılacak kişilerin adı ve soyadı, birlikte yaşanılan
                kişinin adı ve soyadı <br />
                • İletişim: Telefon numarası, acil durumda ulaşılacak kişinin
                adresi ve telefon numarası, ikamet adresi, e-posta adresi <br />
                • Sağlık: Boy, kilo, kan grubu <br />
                • Finans: Banka hesap numarası <br />
                • Görsel ve işitsel kayıtlar: fotoğraf, kamera kaydı <br />
                • Eğitim: Transkript bilgileri, sınav puanları, yabancı dil
                bilgileri, sınıf numarası, veli toplantısı tutanakları, mezun
                olunan okul bilgileri, öğrenim durumu <br />
                • Mesleki deneyim: meslek, unvan <br />
                • Hukuki işlem: velayet belgesi <br />
                • Özel nitelikli kişisel veriler: Geçirilen ameliyat bilgileri,
                hastalık, engellilik durumu, psikolojik değerlendirme raporları,
                sağlık raporu, üye olunan dernek, vakıf, kuruluş adı <br />
                • Diğer: ilgi alanları, sağlık sigortası bilgileri, giyim –
                beden bilgisi, imza, aile durum bilgisi <br />
              </p>
            </div>
            <div className={style.row}>
              <h2>6. Kişisel Verilerin Aktarılması </h2>
              <p>
                BAYETAV tarafından işlenen kişisel verileriniz, işbu Aydınlatma
                Metni’nin 4. maddesinde açıklanan amaçlarla, gerekli güvenlik
                önlemleri alınmak kaydıyla, yurtiçindeki resmi merciler, yargı
                mercileri ve düzenleyici ve denetleyici kurumlar başta olmak
                üzere, bağlı ortaklık ve iştiraklerimize, kanunen yetkili kamu
                kurumlara, Kanun’un 5. maddesinde belirtilen kişisel veri işleme
                şartları ve amaçları çerçevesinde kişisel verilerin korunması ve
                işlenmesi politikaları ve işbu Aydınlatma Metni’nde belirtilen
                amaçlarla sınırlı olarak aktarılabilecektir. Ayrıca kişisel
                verileriniz, gerekli güvenlik önlemlerinin alınması şartıyla ve
                işbu Aydınlatma Metni’nde yazılı amaçlar ile sınırlı olmak üzere
                yurt dışına da aktarılabilecektir.{' '}
              </p>
            </div>
            <div className={style.row}>
              <h2>7. Kişisel Veri Sahibinin Hakları </h2>
              <p>
                Kanun’un 11. maddesi uyarınca veri sahipleri olarak sizler,{' '}
                <br />
                • Kişisel veri işlenip işlenmediğini öğrenme, <br />• Kişisel
                verileri işlenmişse buna ilişkin bilgi talep etme, <br />
                • Kişisel verilerin işlenme amacını ve bunların amacına uygun
                kullanılıp kullanılmadığını öğrenme, <br />
                • Yurt içinde veya yurt dışında kişisel verilerin aktarıldığı
                üçüncü kişileri bilme, <br />• Kişisel verilerin eksik veya
                yanlış işlenmiş olması hâlinde bunların düzeltilmesini isteme ve
                bu kapsamda yapılan işlemin kişisel verilerin aktarıldığı üçüncü
                kişilere bildirilmesini isteme, <br />• Kanun ve ilgili diğer
                kanun hükümlerine uygun olarak işlenmiş olmasına rağmen,
                işlenmesini gerektiren sebeplerin ortadan kalkması hâlinde
                kişisel verilerin silinmesini veya yok edilmesini isteme ve bu
                kapsamda yapılan işlemin kişisel verilerin aktarıldığı üçüncü
                kişilere bildirilmesini isteme, <br />
                • İşlenen verilerin münhasıran otomatik sistemler vasıtasıyla
                analiz edilmesi suretiyle kişinin kendisi aleyhine bir sonucun
                ortaya çıkmasına itiraz etme, <br />
                • Kişisel verilerin kanuna aykırı olarak işlenmesi sebebiyle
                zarara uğraması hâlinde zararın giderilmesini talep etme
                haklarına sahipsiniz. <br />
                Söz konusu haklarınızı kullanmak istediğiniz takdirde her zaman
                BAYETAV yönetimi ile iletişime geçebilirsiniz. <br />
                <br />
                Kanun’dan doğan talepler “İlgili Kişi Başvuru Formu”
                aracılığıyla gerçekleştirecektir. Yukarıda belirtilen
                haklarınızı kullanmak için kimliğinizi tespit edici gerekli
                bilgiler ile Kanun’un 11. maddesinde belirtilen haklardan
                kullanmayı talep ettiğiniz hakkınıza yönelik açıklamalarınızı
                içeren talebinizi;{' '}
                <a href="https://www.bayetavokullari.com" target="__blank">
                  www.bayetavokullari.com
                </a>{' '}
                web adresinde yer alan İlgili Kişi Başvuru Formu’nu doldurarak,
                formun imzalı bir nüshasını Kemalpaşa Osb Mah. 16 sokak no:2/2
                Kemalpaşa/İzmir adresine bizzat elden, noter kanalıyla veya
                elektronik olarak{' '}
                <a href="mailto:info@bayetavokullari.com" target="__blank">
                  info@bayetavokullari.com
                </a>{' '}
                adresine iletebilir veya Kanun’un 13. maddesi uyarınca Kişisel
                Verileri Koruma Kurulu tarafından öngörülecek diğer yöntemler
                ile gönderebilirsiniz.
                <br />
                <br />
                Şirketimiz, Kanun’un 13. maddesine uygun olarak, başvuru
                taleplerini, talebin niteliğine göre ve en geç 30 (otuz) gün
                içinde ücretsiz olarak sonuçlandıracaktır. Talebin reddedilmesi
                halinde, ret nedeni/nedenleri yazılı olarak veya elektronik
                ortamda gerekçelendirilir.
                <br />
                <br />
                BAYETAV’e ilişkin iletişim bilgileri işbu aydınlatma metninin
                sonunda dikkatlerinize sunulmaktadır. Bu bağlamda BAYETAV,
                kişisel veri sahibinin Kanun’un 11. maddesinde sayılan haklarını
                kullanması halinde, ilgili talepleri yerine getirecektir.
              </p>
            </div>
            <div className={style.row}>
              <h2>8. Seçme Olanakları </h2>
              <p>
                Verilerinizden, yukarıdaki amaçlar doğrultusunda yararlanmak
                arzusundayız. Bu konuda onay vermemeniz durumunda bunu her an
                bildirebilirsiniz; biz de bilgilerinizi gerektiği şekilde
                kanunen işlenmesi zorunlu olmadıkça işlenmesini durdurabilir ve
                talebinize göre imha politikalarını uygulayabiliriz.
              </p>
            </div>
            <div className={style.row}>
              <h2>9. Veri Güvenliği </h2>
              <p>
                Veri Sorumlusu, kişisel verilerin hukuka aykırı olarak
                işlenmesini önlemek, kişisel verilere hukuka aykırı olarak
                erişilmesini önlemek, kişisel verilerin muhafazasını sağlamak
                amacıyla uygun güvenlik düzeyini temin etmeye yönelik gerekli
                her türlü teknik ve idari tedbirleri almaktadır.
                <br />
                <br />
                Yönetilen kişisel verilerinizi, manipülasyona uğramamaları,
                kaybolmamaları, hasar görmemeleri ve yetkisi olmayan kişilerce
                kullanılmamaları için koruma amacı ile teknik ve idari açıdan
                güvenlik önlemleri uygulanmaktadır. Güvenlik önlemlerimiz
                teknolojik ilerlemeler ile birlikte sürekli gelişme
                içerisindedir. Kişisel verilerin hukuka aykırı olarak
                işlenmesini önlemek, kişisel verilere hukuka aykırı olarak
                erişilmesini önlemek, kişisel verilerin muhafazasını sağlamak
                amacıyla uygun güvenlik düzeyini temin etmeye yönelik gerekli
                her türlü teknik ve idari tedbirleri almaktadır.
                <br />
                <br />
                Görüş, öneri ve talepleriniz ile ilgili bizimle iletişime
                geçebilirsiniz. <br />
                Unvan : Katharsis Özel Eğitim Hizmetleri A.Ş. <br />
                Sicil Numarası : 1-175638 <br />
                Telefon Numarası : 0 (232) 502 20 05 <br />
                Faks Numarası : 0 (232) 502 20 06 <br />
                Posta Adresi : Kemalpaşa Osb Mah. 16 sokak no:2/2 Kemalpaşa/
                İzmir <br />
                E-posta adresi : muhasebe@adalyatobaccco.com.tr <br />
                Kep Adresi: katharsisozelegitim@hs06.kep.tr <br />
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PopupKvkk;
