import { Form, Formik, Field } from "formik";
import style from "./formwrapper.module.scss";
import {
  DeleteIcon,
  EditIcon,
  FormBG,
  FormBackIcon,
  FormContinueIcon,
  FormSendIcon,
  Logo,
} from "../../assets/icon";
import { useSpring, a } from "@react-spring/web";
import { useEffect, useState } from "react";
import Airtable from "airtable";
import axios from "axios";
import BackToStory from "../Elements/BackToStory/BackToStory";
import * as Yup from "yup";
import { CityData, DistrictData } from "../../helpers/CityData";
import useWindowDimensions from "../../helpers/windowWidth";
import Stepper from "./Stepper";

const StudentCard = ({ name, sinif, kademe, editClick, deleteClick }) => {
  return (
    <div className={style.studentCard}>
      <div className={style.studentInfo}>
        <h4>{name}</h4>
        <h5> / {kademe}</h5>
        <h5> / {sinif}</h5>
      </div>
      <div className={style.buttonWrapper}>
        <button onClick={editClick}>
          <EditIcon />
        </button>
        <button onClick={deleteClick}>
          <DeleteIcon />
        </button>
      </div>
    </div>
  );
};

const InputWrapper = ({
  title,
  half,
  name,
  value,
  placeholder,
  student,
  change,
  error,
  select,
  selectValue,
  type,
  textarea,
  disabled,
  city,
  date,
  required,
  radio,
}) => {
  return (
    <label className={`${style.inputLabel} ${half ? style.half : null}`}>
      <h6>
        {title} <span>{required ? "*" : null}</span>
      </h6>
      {student && radio ? (
        <div className={style.radioWrapper}>
          {selectValue.map((item) => {
            return (
              <label>
                <input
                  name={title}
                  type="radio"
                  value={item}
                  onChange={change}
                />
                <h5>{item[0]}</h5>
              </label>
            );
          })}
        </div>
      ) : student && !date && !select ? (
        <input
          name={name}
          value={value}
          placeholder={placeholder}
          type={type ? type : "text"}
          onChange={change}
          disabled={disabled}
          id={name}
        />
      ) : student && date ? (
        <input
          name={name}
          value={value}
          placeholder={placeholder}
          type={"date"}
          onChange={change}
          disabled={disabled}
          max={new Date().toJSON().slice(0, 10)}
          id={name}
        />
      ) : student && select ? (
        <select id={name} value={value} disabled={disabled} onChange={change}>
          <option className={style.disabled} selected disabled value={""}>
            Seçim Yapınız
          </option>
          {selectValue &&
            selectValue.length &&
            selectValue.map((item) => {
              return <option value={item}>{item}</option>;
            })}
        </select>
      ) : select ? (
        <Field
          name={name}
          value={value}
          placeholder={placeholder}
          as={"select"}
          disabled={disabled}
          id={name}
        >
          <option className={style.disabled} selected disabled value={""}>
            Seçim Yapınız
          </option>
          {selectValue &&
            selectValue.length &&
            selectValue.map((item) => {
              return (
                <option value={item}>
                  {city ? item.split("?")[1].split("name=")[1] : item}
                </option>
              );
            })}
        </Field>
      ) : textarea ? (
        <Field
          name={name}
          value={value}
          placeholder={placeholder}
          as={"textarea"}
          disabled={disabled}
          id={name}
        />
      ) : (
        <Field
          name={name}
          value={value}
          placeholder={placeholder}
          type={type ? type : "text"}
          disabled={disabled}
          id={name}
        />
      )}
      {error ? <span>{error}</span> : null}
    </label>
  );
};

const Terms = ({ click, state, popupClick }) => {
  return (
    <label className={style.terms}>
      <div
        onClick={click}
        className={`${style.check} ${state ? style.activeCheck : null}`}
      />
      <p>
        <span onClick={click}>Kişisel Verilerinizin korunmasına ilişkin</span>{" "}
        <span onClick={popupClick} className={style.underline}>
          Aydınlatma Açık Rıza Formunu{" "}
        </span>
        <span onClick={click}>okudum onaylıyorum.</span>
      </p>
    </label>
  );
};

const FormCard = ({ children, title, formStyle, id }) => {
  return (
    <a.div
      style={{
        ...formStyle,
      }}
      className={style.formCard}
      id={id}
    >
      <h5 className={style.title}>{title}</h5>
      <div className={style.inputWrapper}>{children}</div>
    </a.div>
  );
};

const phoneRegExp = /^(5)([0-9]{2})\s?([0-9]{3})\s?([0-9]{2})\s?([0-9]{2})$/;
const emailRegExp =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const FormSchema = Yup.object().shape({
  ad: Yup.string().min(1, "Kısa isim.").required("İsim alanı zorunludur."),
  soyad: Yup.string()
    .min(1, "Kısa soyisim.")
    .required("Soyisim alanı zorunludur."),
  iletisimNumarasi: Yup.string().matches(
    phoneRegExp,
    "Doğru bir telefon numarası giriniz."
  ),
  eposta: Yup.string().email("Doğru bir eposta  giriniz."),

  terms: Yup.bool().oneOf([true], "Lütfen onay veriniz."),
});

const FormWrapper = ({
  status,
  backClick,
  popupClick,
  doneStep,
  changeContact,
}) => {
  const [isSuccess, setSuccess] = useState(false);
  const [studenList, setStudentList] = useState([]);
  const [currentStudent, setCurrentStudent] = useState({
    ad: "",
    soyad: "",
    dogumTarihi: "",
    cinsiyet: "",
    devamOkul: "",
    kademe: "",
    sinif: "",
  });
  const [errorStudent, setErrorStudent] = useState([]);
  const [error, setError] = useState();
  const [currentDistrict, setCurrentDistrict] = useState();
  const { width } = useWindowDimensions();
  const [isLoad, setLoad] = useState(false);

  const checkError = () => {
    let errorArr = [];
    if (!currentStudent.ad) {
      errorArr.push("ad");
    }

    if (!currentStudent.soyad) {
      errorArr.push("soyad");
    }

    if (!currentStudent.kademe) {
      errorArr.push("kademe");
    }

    if (!currentStudent.sinif) {
      errorArr.push("sinif");
    }

    if (errorArr.length) {
      setErrorStudent(errorArr);
    } else {
      setErrorStudent("");
      return true;
    }
  };

  const sinifData = (val) => {
    if (val === "all") {
      return [
        "4 Yaş",
        "5 Yaş",
        "1.Sınıf",
        "2.Sınıf",
        "3.Sınıf",
        "4.Sınıf",
        "5.Sınıf",
        "6.Sınıf",
        "7.Sınıf",
        "8.Sınıf",
        "9.Sınıf",
      ];
    }
    if (val === "Okul Öncesi") {
      return ["4 Yaş", "5 Yaş"];
    }
    if (val === "İlkokul") {
      return ["1.Sınıf", "2.Sınıf", "3.Sınıf", "4.Sınıf"];
    }
    if (val === "Ortaokul") {
      return ["5.Sınıf", "6.Sınıf", "7.Sınıf", "8.Sınıf"];
    }
    if (val === "Lise") {
      return ["9.Sınıf"];
    }
  };

  const submitRecord = (values, studentList) => {
    console.log("girdiFunc");

    let cocukList;

    if (studentList.length === 1) {
      cocukList = {
        CocukAd1: studentList[0].ad,
        CocukSoyad1: studentList[0].soyad,
        CocukDogumTarihi1: studentList[0].dogumTarihi,
        CocukCinsiyet1: studentList[0].cinsiyet,
        // CocukOncekiOkul1: studentList[0].devamOkul,
        CocukKademe1: studentList[0].kademe,
        CocukSinif1: studentList[0].sinif,
      };
    }

    // if (studentList.length === 2) {
    //   cocukList = {
    //     CocukAd1: studentList[0].ad,
    //     CocukSoyad1: studentList[0].soyad,
    //     CocukDogumTarihi1: studentList[0].dogumTarihi,
    //     CocukCinsiyet1: studentList[0].cinsiyet,
    //     CocukOncekiOkul1: studentList[0].devamOkul,
    //     CocukKademe1: studentList[0].kademe,
    //     CocukSinif1: studentList[0].sinif,

    //     CocukAd2: studentList[1].ad,
    //     CocukSoyad2: studentList[1].soyad,
    //     CocukDogumTarihi2: studentList[1].dogumTarihi,
    //     CocukCinsiyet2: studentList[1].cinsiyet,
    //     CocukOncekiOkul2: studentList[1].devamOkul,
    //     CocukKademe2: studentList[1].kademe,
    //     CocukSinif2: studentList[1].sinif,
    //   };
    // }

    // if (studentList.length === 3) {
    //   cocukList = {
    //     CocukAd1: studentList[0].ad,
    //     CocukSoyad1: studentList[0].soyad,
    //     CocukDogumTarihi1: studentList[0].dogumTarihi,
    //     CocukCinsiyet1: studentList[0].cinsiyet,
    //     CocukOncekiOkul1: studentList[0].devamOkul,
    //     CocukKademe1: studentList[0].kademe,
    //     CocukSinif1: studentList[0].sinif,

    //     CocukAd2: studentList[1].ad,
    //     CocukSoyad2: studentList[1].soyad,
    //     CocukDogumTarihi2: studentList[1].dogumTarihi,
    //     CocukCinsiyet2: studentList[1].cinsiyet,
    //     CocukOncekiOkul2: studentList[1].devamOkul,
    //     CocukKademe2: studentList[1].kademe,
    //     CocukSinif2: studentList[1].sinif,

    //     CocukAd3: studentList[2].ad,
    //     CocukSoyad3: studentList[2].soyad,
    //     CocukDogumTarihi3: studentList[2].dogumTarihi,
    //     CocukCinsiyet3: studentList[2].cinsiyet,
    //     CocukOncekiOkul3: studentList[2].devamOkul,
    //     CocukKademe3: studentList[2].kademe,
    //     CocukSinif3: studentList[2].sinif,
    //   };
    // }

    axios
      .post("https://bayetav-backend.vercel.app/contactForm", {
        values: {
          Adi: values.ad,
          Soyadi: values.soyad,
          // Yakinligi: values.yakinligi,
          OgrenimDurumu: values.ogrenimDurumu,
          Meslegi: values.meslegi,
          // CocukSayisi: values.cocukSayisi.toString(),
          // MedeniDurumu: values.medeniDurumu,
          // Adres: values.adres,
          Il: values.il ? values.il.split("?")[1].split("name=")[1] : values.il,
          Ilce: values.ilce,
          Telefon: values.iletisimNumarasi,
          Eposta: values.eposta,
          // NerdenDuydun: values.biziNerdenDuydun,
          Note: values.note,
          ...cocukList,
        },
      })
      .then((res) => {
        console.log(res.data);
        setSuccess(true);
        setLoad(false);
      })
      .catch((err) => {
        console.log(err);
        setLoad(false);
      });
  };

  useEffect(() => {
    if (status === 17) {
      doneStep();
    }
  }, [status]);

  const handleScroll = (event) => {
    const { scrollHeight, scrollTop, clientHeight } = event.target;
    const scroll = scrollHeight - scrollTop - clientHeight;
    if (scroll === clientHeight) {
      changeContact(true);
    } else {
      changeContact(false);
    }
    console.log(scroll);
    if (scroll > 0) {
      // We are not at the bottom of the scroll content
    } else if (scroll == 0) {
      // We are at the bottom
    }
  };

  const [rawErrors, setRawErrors] = useState({});

  const [currentStep, setStep] = useState(1);

  const updateStep = (error) => {
    if (currentStep !== 4) {
      setStep(currentStep + 1);
    }
  };

  return (
    <div onWheel={(event) => {}} className={style.formWrapper} id="iletisim">
      {width > 940 ? <BackToStory click={backClick} /> : null}
      {isSuccess ? (
        <div className={style.success}>
          <p>
            <span>Bilgilerinizi paylaştığınız için teşekkür ederiz.</span>
            <br /> Sizinle tanışacağımız için heyecanlı ve mutluyuz.
            <br /> Ekibimiz en kısa sürede sizinle iletişime geçerek randevunuzu
            oluşturacaktır.
          </p>
        </div>
      ) : (
        <div className={style.content}>
          <div className={style.mainTitle}>
            <div className={style.logo}>
              <Logo type={"color"} />
            </div>
            <h2>Tanışmaya</h2>
            <h3>
              {width > 940 ? (
                <>
                  ve <span>daha fazlasını</span>
                  <br /> <span>keşfetmeye</span>
                  <br /> <span>bekliyoruz</span>
                </>
              ) : (
                <>
                  ve <span>daha fazlasını</span>
                  <br /> <span> keşfetmeye </span>
                  <span>bekliyoruz</span>
                </>
              )}
            </h3>
            <h4>
              Sizinle iletişime geçebilmemiz ve randevu oluşturabilmemiz için
              lütfen formu doldurun, size ulaşalım.
            </h4>
            {width < 940 ? <BackToStory click={backClick} /> : null}
          </div>
          <Formik
            initialValues={{
              ad: "",
              soyad: "",
              // yakinligi: '',
              ogrenimDurumu: "",
              meslegi: "",
              // medeniDurumu: '',
              // cocukSayisi: '',
              // adres: '',
              il: "",
              ilce: "",
              iletisimNumarasi: "",
              eposta: "",
              // biziNerdenDuydun: '',
              note: "",
              terms: false,
            }}
            validationSchema={FormSchema}
            onSubmit={(values) => {
              console.log("girdi");

              submitRecord(values, [currentStudent]);
            }}
          >
            {({ errors, touched, values, handleSubmit, setFieldValue }) => {
              setRawErrors(errors);
              return (
                <Form
                  onChange={(e) => {
                    if (e.target.name === "il" && e.target.value) {
                      setCurrentDistrict(
                        DistrictData.filter(
                          (i) =>
                            i.il_id ===
                            e.target.value.split("?")[0].split("id=")[1]
                        ).sort((a, b) => a.name.localeCompare(b.name))
                      );
                      setFieldValue("ilce", "");
                    }
                  }}
                  id="veliForm"
                  onSubmit={handleSubmit}
                  onSubmitCapture={(e) => {
                    console.log("errors", "grid");
                    const scroll = (id) => {
                      const section = document.querySelector(`#${id}`);
                      section.scrollIntoView({
                        behavior: "smooth",
                        block: "start",
                      });
                    };

                    if (width < 940 && Object.keys(rawErrors).length !== 0) {
                      scroll("veliForms");
                    } else if (width < 940 && error) {
                      scroll("ogrenciForms");
                    }
                  }}
                >
                  {/* <Stepper current={currentStep} /> */}
                  <div
                    className={`${style.stepWrapper} ${
                      currentStep === 1 ? style.activeStep : null
                    }`}
                  >
                    <div className={style.formContent}>
                      <div className={style.split}>
                        <InputWrapper
                          name={"ad"}
                          value={values.ad}
                          title={"Adı"}
                          half
                          error={errors.ad && touched.ad ? errors.ad : null}
                          required
                        />
                        <InputWrapper
                          name={"soyad"}
                          value={values.soyad}
                          half
                          title={"Soyadı"}
                          error={
                            errors.soyad && touched.soyad ? errors.soyad : null
                          }
                          required
                        />
                      </div>
                      <div className={style.split}>
                        <InputWrapper
                          change={(e) => {
                            setCurrentStudent({
                              ...currentStudent,
                              ad: e.target.value,
                            });
                            if (errorStudent.length) {
                              checkError();
                            }
                          }}
                          half
                          student
                          title={"Çocuğunuzun Adı"}
                          value={currentStudent.ad}
                          error={
                            errorStudent.includes("ad")
                              ? "Adı alanı zorunludur."
                              : null
                          }
                          required
                        />
                        <InputWrapper
                          change={(e) => {
                            setCurrentStudent({
                              ...currentStudent,
                              soyad: e.target.value,
                            });
                            if (errorStudent.length) {
                              checkError();
                            }
                          }}
                          half
                          student
                          title={"Çocuğunuzun Soyadı"}
                          value={currentStudent.soyad}
                          error={
                            errorStudent.includes("soyad")
                              ? "Soyadı alanı zorunludur."
                              : null
                          }
                          required
                        />
                      </div>
                      <div className={style.split}>
                        <InputWrapper
                          name={"iletisimNumarasi"}
                          value={values.iletisimNumarasi}
                          placeholder={"500 222 00 00"}
                          title={"İletişim Numarası"}
                          type={"tel"}
                          error={
                            errors.iletisimNumarasi && touched.iletisimNumarasi
                              ? errors.iletisimNumarasi
                              : null
                          }
                        />
                        <InputWrapper
                          name={"eposta"}
                          value={values.eposta}
                          title={"E-Posta Adresi"}
                          error={
                            errors.eposta && touched.eposta
                              ? errors.eposta
                              : null
                          }
                        />
                      </div>
                      <div className={style.split}>
                        {/* <InputWrapper
                          change={e => {
                            setCurrentStudent({
                              ...currentStudent,
                              kademe: e.target.value,
                              sinif: '',
                            });
                            if (errorStudent.length) {
                              checkError();
                            }
                          }}
                          student
                          title={'Başvuru Yapacağı Kademe'}
                          value={currentStudent.kademe}
                          select
                          selectValue={[
                            'Okul Öncesi',
                            'İlkokul',
                            'Ortaokul',
                            'Lise',
                          ]}
                          error={
                            errorStudent.includes('kademe')
                              ? 'Kademe alanı zorunludur.'
                              : null
                          }
                          required
                        /> */}
                        <InputWrapper
                          change={(e) => {
                            setCurrentStudent({
                              ...currentStudent,
                              sinif: e.target.value,
                            });
                            if (errorStudent.length) {
                              checkError();
                            }
                          }}
                          student
                          select
                          title={"Başvuru Yapacağı Sınıf"}
                          value={currentStudent.sinif}
                          disabled={false}
                          selectValue={sinifData("all")}
                          error={
                            errorStudent.includes("sinif")
                              ? "Sınıf alanı zorunludur."
                              : null
                          }
                          required
                        />
                        <InputWrapper
                          change={(e) => {
                            setCurrentStudent({
                              ...currentStudent,
                              cinsiyet: e.target.value,
                            });
                            if (errorStudent.length) {
                              checkError();
                            }
                          }}
                          half
                          student
                          select
                          radio
                          selectValue={["Erkek", "Kız"]}
                          title={"Cinsiyet"}
                          value={currentStudent.cinsiyet}
                          error={
                            errorStudent.includes("cinsiyet")
                              ? "Cinsiyet alanı zorunludur."
                              : null
                          }
                        />
                      </div>
                      {/* <div className={style.split}>
                        <InputWrapper
                          name={'ogrenimDurumu'}
                          value={values.ogrenimDurumu}
                          half
                          title={'Öğrenim Durumu'}
                          select
                          selectValue={[
                            'İlkokul',
                            'Ortaokul',
                            'Lise',
                            'Lisans',
                            'Yüksek Lisans',
                          ]}
                          error={
                            errors.ogrenimDurumu && touched.ogrenimDurumu
                              ? errors.ogrenimDurumu
                              : null
                          }
                        />
                        <InputWrapper
                          name={'meslegi'}
                          value={values.meslegi}
                          half
                          title={'Mesleği'}
                          error={
                            errors.meslegi && touched.meslegi
                              ? errors.meslegi
                              : null
                          }
                        />
                      </div> */}
                    </div>
                  </div>
                  <div
                    className={`${style.stepWrapper} ${
                      currentStep === 2 ? style.activeStep : null
                    }`}
                  >
                    <h2>İletişim Kanalı</h2>
                    <div className={style.formContent}>
                      <div className={style.split}>
                        <InputWrapper
                          name={"il"}
                          value={values.il}
                          half
                          title={"İl"}
                          select
                          city
                          selectValue={[
                            { id: "35", name: "İZMİR" },
                            { id: "45", name: "MANİSA" },
                            { id: "34", name: "İSTANBUL" },
                            ...CityData,
                          ].map((item) => `id=${item.id}?name=${item.name}`)}
                          error={errors.il && touched.il ? errors.il : null}
                        />
                        <InputWrapper
                          name={"ilce"}
                          value={values.ilce}
                          half
                          title={"İlçe"}
                          select
                          disabled={
                            !(currentDistrict && currentDistrict.length)
                          }
                          selectValue={
                            currentDistrict && currentDistrict.length
                              ? currentDistrict.map((item) => item.name)
                              : null
                          }
                          error={
                            errors.ilce && touched.ilce ? errors.ilce : null
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className={`${style.stepWrapper} ${
                      currentStep === 3 ? style.activeStep : null
                    }`}
                  >
                    <h2>Öğrenci Bilgileri</h2>
                    <div className={style.formContent}>
                      <div className={style.split}>
                        <InputWrapper
                          change={(e) => {
                            setCurrentStudent({
                              ...currentStudent,
                              dogumTarihi: e.target.value,
                            });
                            if (errorStudent.length) {
                              checkError();
                            }
                          }}
                          half
                          student
                          title={"Doğum Tarihi"}
                          date
                          value={currentStudent.dogumTarihi}
                          error={
                            errorStudent.includes("dogumTarihi")
                              ? "Doğum Tarihi alanı zorunludur."
                              : null
                          }
                        />
                        <InputWrapper
                          change={(e) => {
                            setCurrentStudent({
                              ...currentStudent,
                              cinsiyet: e.target.value,
                            });
                            if (errorStudent.length) {
                              checkError();
                            }
                          }}
                          half
                          student
                          select
                          selectValue={["Kız", "Erkek"]}
                          title={"Cinsiyet"}
                          value={currentStudent.cinsiyet}
                          error={
                            errorStudent.includes("cinsiyet")
                              ? "Cinsiyet alanı zorunludur."
                              : null
                          }
                        />
                      </div>{" "}
                      {/* <InputWrapper
                        change={e => {
                          setCurrentStudent({
                            ...currentStudent,
                            devamOkul: e.target.value,
                          });
                          if (errorStudent.length) {
                            checkError();
                          }
                        }}
                        student
                        title={'Devam Ettiği Okul'}
                        value={currentStudent.devamOkul}
                        error={
                          errorStudent.includes('devamOkul')
                            ? 'Devam Ettiği Okul alanı zorunludur.'
                            : null
                        }
                      /> */}
                      <div className={style.split}>
                        <InputWrapper
                          change={(e) => {
                            setCurrentStudent({
                              ...currentStudent,
                              kademe: e.target.value,
                              sinif: "",
                            });
                            if (errorStudent.length) {
                              checkError();
                            }
                          }}
                          student
                          title={"Başvuru Yapacağı Kademe"}
                          value={currentStudent.kademe}
                          select
                          selectValue={[
                            "Okul Öncesi",
                            "İlkokul",
                            "Ortaokul",
                            "Lise",
                          ]}
                          error={
                            errorStudent.includes("kademe")
                              ? "Kademe alanı zorunludur."
                              : null
                          }
                          required
                        />
                        <InputWrapper
                          change={(e) => {
                            setCurrentStudent({
                              ...currentStudent,
                              sinif: e.target.value,
                            });
                            if (errorStudent.length) {
                              checkError();
                            }
                          }}
                          student
                          select
                          title={"Başvuru Yapacağı Sınıf"}
                          value={currentStudent.sinif}
                          disabled={!currentStudent.kademe}
                          selectValue={
                            currentStudent.kademe &&
                            sinifData(currentStudent.kademe)
                          }
                          error={
                            errorStudent.includes("sinif")
                              ? "Sınıf alanı zorunludur."
                              : null
                          }
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className={`${style.stepWrapper} ${
                      currentStep === 4 ? style.activeStep : null
                    }`}
                  >
                    <h2>Bize Ulaşın</h2>
                    <div className={style.formContent}>
                      <InputWrapper
                        value={values.note}
                        name={"note"}
                        title={"İletmek İstediğiniz Not"}
                        textarea
                      />

                      {error ? (
                        <span className={style.error}>{error}</span>
                      ) : null}
                    </div>
                  </div>

                  <Terms
                    click={() => {
                      setFieldValue("terms", !values.terms);
                    }}
                    popupClick={popupClick}
                    state={values.terms}
                  />
                  {errors.terms && touched.terms ? (
                    <span className={style.error}>{errors.terms}</span>
                  ) : null}
                  {error ? <span className={style.error}>{error}</span> : null}
                  <div className={style.buttonWrapper}>
                    {currentStep !== 1 ? (
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          if (currentStep !== 1) {
                            setStep(currentStep - 1);
                          }
                        }}
                        className={style.back}
                      >
                        <FormBackIcon /> ÖNCEKİ ADIM
                      </button>
                    ) : null}
                    <button
                      disabled={isLoad}
                      onClick={async (e) => {
                        e.preventDefault();

                        if (
                          values.ad &&
                          values.soyad &&
                          ((values.iletisimNumarasi &&
                            values.iletisimNumarasi.match(phoneRegExp)) ||
                            (values.eposta &&
                              values.eposta.match(emailRegExp))) &&
                          currentStudent.ad &&
                          currentStudent.soyad &&
                          currentStudent.sinif
                        ) {
                          setLoad(true);
                          await handleSubmit();
                          setError("");
                        } else if (!values.iletisimNumarasi && !values.eposta) {
                          setLoad(false);
                          setError(
                            "Lütfen bir iletişim numarası ya da e-posta adresi giriniz."
                          );
                        }

                        // if (
                        //   currentStep === 2 &&
                        //   ((values.iletisimNumarasi &&
                        //     values.iletisimNumarasi.match(phoneRegExp)) ||
                        //     (values.eposta && values.eposta.match(emailRegExp)))
                        // ) {
                        //   await updateStep();
                        //   setError('');
                        // }

                        // if (
                        //   currentStep === 3 &&
                        //   currentStudent.ad &&
                        //   currentStudent.soyad &&
                        //   currentStudent.kademe &&
                        //   currentStudent.sinif
                        // ) {
                        //   await updateStep();
                        // } else if (currentStep === 3) {
                        //   checkError();
                        // }

                        // updateStep(rawErrors);
                      }}
                      className={style.continue}
                    >
                      GÖNDER
                      <FormSendIcon />
                    </button>
                  </div>
                  {/* <FormCard id="veliForms" title={'Velinin'}>
                    <div className={style.split}>
                      <InputWrapper
                        name={'ad'}
                        value={values.ad}
                        title={'Adı'}
                        half
                        error={errors.ad && touched.ad ? errors.ad : null}
                      />
                      <InputWrapper
                        name={'soyad'}
                        value={values.soyad}
                        half
                        title={'Soyadı'}
                        error={
                          errors.soyad && touched.soyad ? errors.soyad : null
                        }
                      />
                    </div>
                    <InputWrapper
                      name={'yakinligi'}
                      value={values.yakinligi}
                      title={'Yakınlığı'}
                      select
                      selectValue={['Anne', 'Baba', 'Akraba', 'Vasi']}
                      error={
                        errors.yakinligi && touched.yakinligi
                          ? errors.yakinligi
                          : null
                      }
                    />
                    <div className={style.split}>
                      <InputWrapper
                        name={'ogrenimDurumu'}
                        value={values.ogrenimDurumu}
                        half
                        title={'Öğrenim Durumu'}
                        select
                        selectValue={[
                          'İlkokul',
                          'Ortaokul',
                          'Lise',
                          'Lisans',
                          'Yüksek Lisans',
                        ]}
                        error={
                          errors.ogrenimDurumu && touched.ogrenimDurumu
                            ? errors.ogrenimDurumu
                            : null
                        }
                      />
                      <InputWrapper
                        name={'meslegi'}
                        value={values.meslegi}
                        half
                        title={'Mesleği'}
                        error={
                          errors.meslegi && touched.meslegi
                            ? errors.meslegi
                            : null
                        }
                      />
                    </div>
                    <div className={style.split}>
                      <InputWrapper
                        name={'medeniDurumu'}
                        value={values.medeniDurumu}
                        half
                        title={'Medeni Durum'}
                        select
                        selectValue={['Evli', 'Bekar']}
                        error={
                          errors.medeniDurumu && touched.medeniDurumu
                            ? errors.medeniDurumu
                            : null
                        }
                      />
                      <InputWrapper
                        name={'cocukSayisi'}
                        value={values.cocukSayisi}
                        half
                        title={'Çocuk Sayısı'}
                        type={'number'}
                        error={
                          errors.cocukSayisi && touched.cocukSayisi
                            ? errors.cocukSayisi
                            : null
                        }
                      />
                    </div>
                    <InputWrapper
                      name={'adres'}
                      value={values.adres}
                      title={'Adres'}
                      textarea
                      error={
                        errors.adres && touched.adres ? errors.adres : null
                      }
                    />
                    <div className={style.split}>
                      <InputWrapper
                        name={'il'}
                        value={values.il}
                        half
                        title={'İl'}
                        select
                        city
                        selectValue={CityData.map(
                          item => `id=${item.id}?name=${item.name}`
                        )}
                        error={errors.il && touched.il ? errors.il : null}
                      />
                      <InputWrapper
                        name={'ilce'}
                        value={values.ilce}
                        half
                        title={'İlçe'}
                        select
                        disabled={!(currentDistrict && currentDistrict.length)}
                        selectValue={
                          currentDistrict && currentDistrict.length
                            ? currentDistrict.map(item => item.name)
                            : null
                        }
                        error={errors.ilce && touched.ilce ? errors.ilce : null}
                      />
                    </div>
                    <InputWrapper
                      name={'iletisimNumarasi'}
                      value={values.iletisimNumarasi}
                      placeholder={'500 222 00 00'}
                      title={'İletişim Numarası'}
                      type={'tel'}
                      error={
                        errors.iletisimNumarasi && touched.iletisimNumarasi
                          ? errors.iletisimNumarasi
                          : null
                      }
                    />
                    <InputWrapper
                      name={'eposta'}
                      value={values.eposta}
                      title={'E-Posta Adresi'}
                      error={
                        errors.eposta && touched.eposta ? errors.eposta : null
                      }
                    />
                    <InputWrapper
                      name={'biziNerdenDuydun'}
                      value={values.biziNerdenDuydun}
                      title={'Bizi Nereden Duydunuz?'}
                      error={
                        errors.biziNerdenDuydun && touched.biziNerdenDuydun
                          ? errors.biziNerdenDuydun
                          : null
                      }
                      select
                      selectValue={[
                        'Bayetav Instagram Hesabı',
                        'Bayetav Websitesi',
                        'Bayetav Websitesi',
                        'Bir Arkadaşımdan',
                        'Beyetav Okulları Instagram Hesabı',
                        'Beyetav Okulları Websitesi',
                        'İnternet Reklamları',
                        'Diğer',
                      ]}
                    />
                  </FormCard> */}
                  {/* <div className={style.left}>
                    {studenList.length !== 3 ? (
                      <FormCard id="ogrenciForms" title={'Öğrencinin'}>
                        <div className={style.split}>
                          <InputWrapper
                            change={e => {
                              setCurrentStudent({
                                ...currentStudent,
                                ad: e.target.value,
                              });
                              if (errorStudent.length) {
                                checkError();
                              }
                            }}
                            half
                            student
                            title={'Adı'}
                            value={currentStudent.ad}
                            error={
                              errorStudent.includes('ad')
                                ? 'Adı alanı zorunludur.'
                                : null
                            }
                          />
                          <InputWrapper
                            change={e => {
                              setCurrentStudent({
                                ...currentStudent,
                                soyad: e.target.value,
                              });
                              if (errorStudent.length) {
                                checkError();
                              }
                            }}
                            half
                            student
                            title={'Soyadı'}
                            value={currentStudent.soyad}
                            error={
                              errorStudent.includes('soyad')
                                ? 'Soyadı alanı zorunludur.'
                                : null
                            }
                          />
                        </div>
                        <div className={style.split}>
                          <InputWrapper
                            change={e => {
                              setCurrentStudent({
                                ...currentStudent,
                                dogumTarihi: e.target.value,
                              });
                              if (errorStudent.length) {
                                checkError();
                              }
                            }}
                            half
                            student
                            title={'Doğum Tarihi'}
                            date
                            value={currentStudent.dogumTarihi}
                            error={
                              errorStudent.includes('dogumTarihi')
                                ? 'Doğum Tarihi alanı zorunludur.'
                                : null
                            }
                          />
                          <InputWrapper
                            change={e => {
                              setCurrentStudent({
                                ...currentStudent,
                                cinsiyet: e.target.value,
                              });
                              if (errorStudent.length) {
                                checkError();
                              }
                            }}
                            half
                            student
                            select
                            selectValue={['Kız', 'Erkek']}
                            title={'Cinsiyet'}
                            value={currentStudent.cinsiyet}
                            error={
                              errorStudent.includes('cinsiyet')
                                ? 'Cinsiyet alanı zorunludur.'
                                : null
                            }
                          />
                        </div>{' '}
                        <InputWrapper
                          change={e => {
                            setCurrentStudent({
                              ...currentStudent,
                              devamOkul: e.target.value,
                            });
                            if (errorStudent.length) {
                              checkError();
                            }
                          }}
                          student
                          title={'Devam Ettiği Okul'}
                          value={currentStudent.devamOkul}
                          error={
                            errorStudent.includes('devamOkul')
                              ? 'Devam Ettiği Okul alanı zorunludur.'
                              : null
                          }
                        />
                        <div className={style.split}>
                          <InputWrapper
                            change={e => {
                              setCurrentStudent({
                                ...currentStudent,
                                kademe: e.target.value,
                                sinif: '',
                              });
                              if (errorStudent.length) {
                                checkError();
                              }
                            }}
                            student
                            title={'Başvuru Yapacağı Kademe'}
                            value={currentStudent.kademe}
                            select
                            selectValue={[
                              'Okul Öncesi',
                              'İlkokul',
                              'Ortaokul',
                              'Lise',
                            ]}
                            error={
                              errorStudent.includes('kademe')
                                ? 'Kademe alanı zorunludur.'
                                : null
                            }
                          />
                          <InputWrapper
                            change={e => {
                              setCurrentStudent({
                                ...currentStudent,
                                sinif: e.target.value,
                              });
                              if (errorStudent.length) {
                                checkError();
                              }
                            }}
                            student
                            select
                            title={'Başvuru Yapacağı Sınıf'}
                            value={currentStudent.sinif}
                            disabled={!currentStudent.kademe}
                            selectValue={
                              currentStudent.kademe &&
                              sinifData(currentStudent.kademe)
                            }
                            error={
                              errorStudent.includes('sinif')
                                ? 'Sınıf alanı zorunludur.'
                                : null
                            }
                          />
                        </div>
                        <button
                          onClick={e => {
                            e.preventDefault();

                            if (checkError()) {
                              console.log(currentStudent);
                              setStudentList([...studenList, currentStudent]);
                              setCurrentStudent({
                                ad: '',
                                soyad: '',
                                dogumTarihi: '',
                                cinsiyet: '',
                                devamOkul: '',
                                kademe: '',
                                sinif: '',
                              });
                            }
                          }}
                          className={style.studentSave}
                        >
                          Kaydet
                        </button>
                      </FormCard>
                    ) : null}
                    {studenList && studenList.length ? (
                      <div className={style.studentCardWrapper}>
                        {studenList.map((item, index) => (
                          <StudentCard
                            name={`${item.ad} ${item.soyad}`}
                            sinif={item.sinif}
                            kademe={item.kademe}
                            deleteClick={e => {
                              e.preventDefault();
                              setStudentList(
                                studenList.filter(
                                  (i, filterI) => index !== filterI
                                )
                              );
                            }}
                            editClick={e => {
                              e.preventDefault();
                              setCurrentStudent(
                                studenList.filter(
                                  (i, filterI) => index === filterI
                                )[0]
                              );
                              setStudentList(
                                studenList.filter(
                                  (i, filterI) => index !== filterI
                                )
                              );
                            }}
                          />
                        ))}
                      </div>
                    ) : null}
                    <div className={style.formBottom}>
                      <InputWrapper
                        value={values.note}
                        name={'note'}
                        title={'İletmek İstediğiniz Not'}
                        textarea
                      />

                      <Terms
                        click={() => {
                          setFieldValue('terms', !values.terms);
                        }}
                        popupClick={popupClick}
                        state={values.terms}
                      />
                      {errors.terms && touched.terms ? (
                        <span className={style.error}>{errors.terms}</span>
                      ) : null}
                      {error ? (
                        <span className={style.error}>{error}</span>
                      ) : null}
                      <button
                        disabled={isLoad}
                        type="submit"
                        className={style.send}
                      >
                        Gönder
                      </button>
                    </div>
                  </div> */}
                </Form>
              );
            }}
          </Formik>
        </div>
      )}
    </div>
  );
};

export default FormWrapper;
