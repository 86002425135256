import { OgretmenForm, NavBar, WhatsappCard } from '../../components';
import style from './ogretmenbasvur.module.scss';
import { Logo } from '../../assets/icon';
import useWindowDimensions from '../../helpers/windowWidth';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { PopupKvkk } from '../../components';

const OgretmenBasvur = () => {
  const { width } = useWindowDimensions();
  const navigate = useNavigate();
  const [isSuccess, setSuccess] = useState(false);
  const [isPopup, setPopup] = useState(false);

  useEffect(() => {
    if (isSuccess) {
      window.scrollTo(0, 0);
    }
  }, [isSuccess]);

  return (
    <div
      style={{
        overflowY: isSuccess ? 'hidden' : 'scroll',
      }}
      className={style.ogretmenBasvur}
    >
      {isPopup ? (
        <PopupKvkk ogretmen closeClick={() => setPopup(false)} />
      ) : null}
      <WhatsappCard
        click={() => {
          navigate('/iletisim');
        }}
        status={true}
        width={width}
      />
      <NavBar
        width={width}
        status={true}
        current={0}
        navChange={() => console.log()}
        okulChange={() => navigate('/')}
        ogrenciChange={() => navigate('/')}
        ogretmenChange={() => navigate('/')}
        veliChange={() => navigate('/')}
        contactChange={() => navigate('/iletisim')}
        sssChange={() => {
          navigate('/sss');
        }}
        brosurChange={() => {
          navigate('/brosur');
        }}
      />
      {!isSuccess ? (
        <div className={style.top}>
          <h2>Bayetav Okulları Öğretmen Başvuru Formu</h2>
          <div className={style.row}>
            <div className={style.column}>
              <h3>İş Tanımı</h3>
              <p>
                2024-2025 eğitim öğretim yılında okulumuzda görevlendirmek üzere
                Okul Öncesi, Sınıf, Türkçe, Sosyal Bilgiler, Ortaokul Matematik,
                Fen Bilimleri, Lise Matematik, Türk Dili ve Edebiyatı,
                İngilizce, Görsel Sanatlar, Müzik, Beden Eğitimi, Bilişim
                Teknolojileri, Din Kültürü ve Ahlak Bilgisi branşlarda
                öğretmenler alınacaktır.
              </p>
            </div>
            <div className={style.column}>
              <h3>Aranan Nitelikler</h3>
              <ul>
                <li>
                  Üniversitelerin eğitim, fen, edebiyat, sosyal bilimler
                  fakültelerinden (pedagojik formasyonu olan) mezun,
                </li>
                <li>Alanında deneyimli, yeni mezun veya mezun olacak,</li>
                <li>Özel okullarda çalışmış,</li>
                <li>
                  Başta eğitim alanında üzerine çeşitli STK’larda gönüllülük
                  faaliyetlerinde bulunan,
                </li>
                <li>
                  Mesleki gelişimini sürdüren ve bu alanda yapılan eğitim, kurs,
                  seminer vb. çalışmalara katılan,
                </li>
                <li>Kolektif çalışmaya yatkın, toplumsal duyarlılığı olan,</li>
                <li>
                  Öğrenci merkezli çalışma ve öğretimi farklılaştırma becerisine
                  sahip,
                </li>
                <li>
                  Dijital okur-yazarlığı olan ve eğitimde yardımcı teknolojileri
                  kullanabilen,
                </li>
                <li>
                  İşini ve çocukları seven, icat çıkaran, özveriyle
                  çalışabilecek adaylar.
                </li>
              </ul>
            </div>
          </div>
        </div>
      ) : null}
      <OgretmenForm
        popupClick={() => setPopup(true)}
        setSuccess={() => setSuccess(true)}
        isSuccess={isSuccess}
      />
    </div>
  );
};

export default OgretmenBasvur;
