import React, { useEffect, useState } from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { routes } from './routes';
import { ProtectedRoute } from './routes/ProtectedRoute';
import ScrollTop from './helpers/ScrollTop';

export const history = createBrowserHistory();

const App = () => {
  return (
    <>
      <BrowserRouter>
        <ScrollTop />
        {/* {!isLoad && <Preloader />} */}

        <Routes>
          {routes.map(route => {
            return (
              <Route
                path={route.path}
                element={
                  route.protected ? (
                    <ProtectedRoute>{route.component}</ProtectedRoute>
                  ) : (
                    route.component
                  )
                }
              />
            );
          })}
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default App;
