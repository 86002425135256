import {
  Logo,
  LogoFullColor,
  NasilOkul,
  NasilVeli,
  NasilOkulElement,
  BowIcon,
} from "../../assets/icon";
import Card from "../Card/Card";
import OkulImage from "../../assets/img/nasil-okul-image.png";
import OkulImage2 from "../../assets/img/nasil-okul-image-2.png";
import style from "./nasilveli.module.scss";
import useWindowDimensions from "../../helpers/windowWidth";
import { useSpring, a } from "@react-spring/web";
import { useEffect } from "react";
import Discover from "../Elements/Discover/Discover";
import { useNavigate } from "react-router-dom";

const NasilVeliWrapper = ({
  status,
  doneStep,
  discover1,
  discover2,
  width,
}) => {
  const [logo, setLogo] = useSpring(() => ({
    transform: `translateX(${-50}px)`,
    opacity: 0,
  }));
  const [svg1, setSvg1] = useSpring(() => ({
    transform: `translateY(${-50}px)`,
    opacity: 0,
  }));
  const [svg2, setSvg2] = useSpring(() => ({
    transform: `translateY(${-100}px)`,
    opacity: 0,
  }));

  const [paragraph1, setParagraph1] = useSpring(() => ({
    transform: `translateX(${-100}px)`,
    opacity: 0,
  }));
  const [paragraph2, setParagraph2] = useSpring(() => ({
    transform: `translateX(${-100}px)`,
    opacity: 0,
  }));
  const [paragraph3, setParagraph3] = useSpring(() => ({
    transform: `translateX(${-100}px)`,
    opacity: 0,
  }));

  const [row1, setRow1] = useSpring(() => ({
    transform: `translateX(${-100}px)`,
    opacity: 0,
  }));

  const [row2, setRow2] = useSpring(() => ({
    marginLeft: "0vh",
  }));
  const [row3, setRow3] = useSpring(() => ({
    marginLeft: "0vh",
  }));

  const [row4, setRow4] = useSpring(() => ({
    marginLeft: "0vh",
  }));
  const [row5, setRow5] = useSpring(() => ({
    marginLeft: "0vh",
  }));

  const [part2, setPart2] = useSpring(() => ({
    backgroundColor: `#0055b8`,
  }));

  const startAnimation = () => {
    setTimeout(() => {
      setLogo({
        transform: `translateX(${0}px)`,
        opacity: 1,
      });
    }, 500);
    setTimeout(() => {
      setSvg2({
        transform: `translateY(${0}px)`,
        opacity: 1,
      });
    }, 700);
    setTimeout(() => {
      setSvg1({
        transform: `translateY(${0}px)`,
        opacity: 1,
      });
    }, 900);

    setTimeout(() => {
      setParagraph1({
        transform: `translateX(${0}px)`,
        opacity: 1,
      });
    }, 1100);
    setTimeout(() => {
      setParagraph2({
        transform: `translateX(${0}px)`,
        opacity: 1,
      });
    }, 1300);
    setTimeout(() => {
      setParagraph3({
        transform: `translateX(${0}px)`,
        opacity: 1,
      });
    }, 1500);

    setTimeout(() => {
      doneStep();
    }, 500);
  };

  const resetAnimation = () => {
    setLogo({
      transform: `translateX(${-50}px)`,
      opacity: 0,
    });
    setSvg2({
      transform: `translateY(${-100}px)`,
      opacity: 0,
    });
    setSvg1({
      transform: `translateY(${-50}px)`,
      opacity: 0,
    });

    setParagraph1({
      transform: `translateX(${-100}px)`,
      opacity: 0,
    });
    setParagraph2({
      transform: `translateX(${-100}px)`,
      opacity: 0,
    });
    setParagraph3({
      transform: `translateX(${-100}px)`,
      opacity: 0,
    });
  };

  const startAnimation1 = () => {
    setTimeout(() => {
      setPart2({
        backgroundColor: "#f7f3ee",
      });
    }, 500);
    setTimeout(() => {
      setRow1({
        transform: `translateX(${0}px)`,
        opacity: 1,
      });
    }, 500);
    setTimeout(() => {
      setRow2({
        marginLeft: "16vh",
      });
    }, 1000);
    setTimeout(() => {
      setRow3({
        marginLeft: "25vh",
      });
    }, 1000);

    setTimeout(() => {
      setRow4({
        marginLeft: "32vh",
      });
    }, 1000);
    setTimeout(() => {
      setRow5({
        marginLeft: "40vh",
      });
    }, 1000);
    setTimeout(() => {
      doneStep();
    }, 1000);
  };

  const resetAnimation1 = () => {
    setPart2({
      backgroundColor: `#0055b8`,
    });
    setRow1({
      transform: `translateX(${-100}px)`,
      opacity: 0,
    });
    setRow2({
      marginLeft: "0vh",
    });
    setRow3({
      marginLeft: "0vh",
    });
    setRow4({
      marginLeft: "0vh",
    });
    setRow5({
      marginLeft: "0vh",
    });
  };

  useEffect(() => {
    if (width > 940) {
      if (status === 13 || status === 11) {
        resetAnimation();
      } else if (status === 12) {
        startAnimation();
      }

      if (status === 14 || status === 12) {
        resetAnimation1();
      } else if (status === 13) {
        startAnimation1();
      }
    } else {
      if (status === 20) {
        resetAnimation();
      } else if (status === 21) {
        startAnimation();
      }

      if (status === 21) {
        resetAnimation1();
      } else if (status === 22) {
        startAnimation1();
      }
    }
  }, [status]);

  const navigate = useNavigate();

  return (
    <>
      {width > 940 ? (
        <div onWheel={(event) => {}} className={style.nasilVeli}>
          <div className={style.part1}>
            <div className={style.top}>
              <a.div style={logo} className={style.logo}>
                <Logo type={"color"} />
              </a.div>
              <a.div style={svg2} className={style.svgWrapper}>
                <a.span style={svg1}>
                  <NasilVeli type={"top"} />
                </a.span>
                <NasilVeli />
              </a.div>
            </div>
            <div className={style.info}>
              <a.p style={paragraph1}>
                Çocuğun sosyal bir özne
                <br /> olduğunu bilen
              </a.p>
              <a.p style={paragraph2}>
                Nitelikli eğitimin bir hak
                <br /> olduğuna inanan
              </a.p>
              <a.p style={paragraph3}>
                Aile bütçesini zorlamadan
                <br /> nitelikli eğitime erişebilen
              </a.p>
              <a.p onClick={() => navigate("/sss")} style={paragraph3}>
                Daha fazla <br />
                bilgi almak için <a href="/sss">S.S.S</a>
              </a.p>
            </div>
            <Discover click={discover1} type={"primary"} />
          </div>
          <a.div style={part2} className={style.part2}>
            <div className={style.content}>
              <a.div style={row1} className={style.bow}>
                <BowIcon />
              </a.div>
              <ul>
                <li>
                  Kendi beklenti ve kaygılarından çok{" "}
                  <span>çocuğun ihtiyaçlarını ön planda tutan</span>
                </li>
                <a.li style={row2}>
                  Çocuğun sınav başarısından çok <span>hayatta başarılı</span>{" "}
                  olma potansiyelini destekleyen
                </a.li>
                <a.li style={row3}>
                  <span>Değişen dünyanın</span> ve yarattığı ihtiyaçların
                  farkında
                </a.li>
                <a.li style={row4}>
                  <span>Bir arada yaşamanın</span> gerekliliğine inanan
                </a.li>
                <a.li style={row4}>
                  Farklılıkların bilincinde, <span>ayrımcılığa karşı,</span>{" "}
                  çeşitlilikle yan yana duran
                </a.li>
                <a.li style={row4}>
                  <span>Sesi duyulan,</span> dinlenen, sesini katabilen
                </a.li>
                <a.li style={row3}>
                  <span>Ortak amaçlar</span> ve <span>hayaller</span> etrafında
                  bir araya gelen
                </a.li>
                <a.li style={row2}>
                  <span>Bir arada öğrenme deneyimine</span> dahil olmak isteyen
                </a.li>
                <li>
                  <span>Kendini öğrenme</span> topluluğunun parçası olarak gören
                </li>
              </ul>
            </div>
            <Discover click={discover2} />
          </a.div>
        </div>
      ) : (
        <div id="veli" onWheel={(event) => {}} className={style.nasilVeli}>
          <div className={style.part1}>
            <div className={style.top}>
              <a.div className={style.logo}>
                <Logo type={"color"} />
              </a.div>
              <a.div className={style.svgWrapper}>
                <a.span>
                  <NasilVeli type={"top"} />
                </a.span>
                <NasilVeli />
              </a.div>
            </div>
            <div className={style.info}>
              <a.p>
                Çocuğun sosyal bir özne
                <br /> olduğunu bilen
              </a.p>
              <a.p>
                Nitelikli eğitimin bir hak
                <br /> olduğuna inanan
              </a.p>
              <a.p>
                Aile bütçesini zorlamadan
                <br /> nitelikli eğitime erişebilen
              </a.p>
              <a.p onClick={() => navigate("/sss")}>
                Daha fazla <br />
                bilgi almak için <a href="/sss">S.S.S</a>
              </a.p>
            </div>
            <Discover click={discover1} type={"primary"} />
          </div>
          <a.div className={style.part2}>
            <div className={style.content}>
              <a.div className={style.bow}>
                <BowIcon />
              </a.div>
              <ul>
                <li>
                  Kendi beklenti ve kaygılarından çok{" "}
                  <span>çocuğun ihtiyaçlarını ön planda tutan</span>
                </li>
                <a.li>
                  Çocuğun sınav başarısından çok <span>hayatta başarılı</span>{" "}
                  olma potansiyelini destekleyen
                </a.li>
                <a.li>
                  <span>Değişen dünyanın</span> ve yarattığı ihtiyaçların
                  farkında
                </a.li>
                <a.li>
                  <span>Bir arada yaşamanın</span> gerekliliğine inanan
                </a.li>
                <a.li>
                  Farklılıkların bilincinde, <span>ayrımcılığa karşı,</span>{" "}
                  çeşitlilikle yan yana duran
                </a.li>
                <a.li>
                  <span>Sesi duyulan,</span> dinlenen, sesini katabilen
                </a.li>
                <a.li>
                  <span>Ortak amaçlar</span> ve <span>hayaller</span> etrafında
                  bir araya gelen
                </a.li>
                <a.li>
                  <span>Bir arada öğrenme deneyimine</span> dahil olmak isteyen
                </a.li>
                <li>
                  <span>Kendini öğrenme</span> topluluğunun parçası olarak gören
                </li>
              </ul>
            </div>
          </a.div>
        </div>
      )}
    </>
  );
};

export default NasilVeliWrapper;
