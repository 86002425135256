import { Document, Page, pdfjs } from "react-pdf";
import { useEffect, useState } from "react";
import { ContactCard, NavBar, WhatsappCard } from "../../components";
import BrosurPdf from "../../assets/pdf/e-brosur.pdf";
import style from "./brosur.module.scss";
import useWindowDimensions from "../../helpers/windowWidth";
import { useNavigate } from "react-router-dom";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const Brosur = () => {
  const { width } = useWindowDimensions();
  const [numPages, setNumPages] = useState();
  const [pageRow, setPageRow] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  const navigate = useNavigate();
  const [canNext, setCanNext] = useState(true);
  const [isLoad, setLoad] = useState(false);

  console.log("numPages", isLoad);

  return (
    <div id="brosur" className={style.brosur}>
      <ContactCard
        click={() => {
          navigate("/iletisim");
        }}
        status={true}
        width={width}
      />
      <WhatsappCard
        click={() => {
          navigate("/iletisim");
        }}
        status={true}
        width={width}
      />
      <NavBar
        width={width}
        status={true}
        current={0}
        navChange={() => console.log()}
        okulChange={() => navigate("/")}
        ogrenciChange={() => navigate("/")}
        ogretmenChange={() => navigate("/")}
        veliChange={() => navigate("/")}
        contactChange={() => navigate("/iletisim")}
        sssChange={() => {
          navigate("/sss");
        }}
        brosurChange={() => {
          navigate("/brosur");
        }}
      />
      <div className={style.content}>
        <h1>E-Broşür</h1>
        <div className={style.pdfNavigate}>
          <button
            onClick={() => {
              if (pageNumber !== 1) {
                setPageNumber(pageNumber - 2);
                if (width < 940) {
                  document.querySelector("#brosur").scrollTo({
                    top: 0,
                    behavior: "smooth",
                  });
                }
              }
            }}
          >
            -
          </button>
          <div className={style.pages}>{`${pageNumber} / ${
            pageNumber + 1
          }`}</div>
          <button
            onClick={() => {
              console.log(pageNumber, numPages, "denem");
              if (pageNumber + 1 !== numPages) {
                setPageNumber(pageNumber + 2);

                if (width < 940) {
                  document.querySelector("#brosur").scrollTo({
                    top: 0,
                    behavior: "smooth",
                  });
                }
              }
            }}
          >
            +
          </button>
        </div>
        <div className={style.pdfWrapper}>
          <Document
            file={{ url: BrosurPdf }}
            onLoadSuccess={(e) => {
              if (!isLoad) {
                setNumPages(e.numPages);
                setPageRow(e.numPages / 2);
              }
              setLoad(true);
            }}
            className={style.pdf}
            loading={() => {
              return (
                <div
                  style={{
                    width:
                      width > 940 ? width / 3.9 + width / 3.9 : width / 1.2,
                    height: "100%",
                  }}
                  className={style.emptyPage}
                />
              );
            }}
          >
            <Page
              renderTextLayer={false}
              renderAnnotationLayer={false}
              customTextRenderer={false}
              width={width > 940 ? width / 3.9 : width / 1.2}
              pageNumber={pageNumber}
            />
            <Page
              renderTextLayer={false}
              renderAnnotationLayer={false}
              customTextRenderer={false}
              width={width > 940 ? width / 3.9 : width / 1.2}
              pageNumber={pageNumber + 1}
            />
          </Document>
        </div>
      </div>
    </div>
  );
};

export default Brosur;
