import {
  Logo,
  LogoFullColor,
  NasilOkul,
  NasilOkulElement,
  NasilOlur,
  NasilOlurSvg,
} from '../../assets/icon';
import Card from '../Card/Card';
import OkulImage from '../../assets/img/nasil-okul-image.png';
import OkulImage2 from '../../assets/img/nasil-okul-image-2.png';
import style from './nasilolur.module.scss';
import useWindowDimensions from '../../helpers/windowWidth';
import { useSpring, a } from '@react-spring/web';
import { useEffect } from 'react';
import Discover from '../Elements/Discover/Discover';
import { useNavigate } from 'react-router-dom';

const NasilOlurWrapper = ({
  status,
  doneStep,
  discover1,
  discover2,
  width,
}) => {
  const [logo, setLogo] = useSpring(() => ({
    transform: `translateX(${-50}px)`,
    opacity: 0,
  }));
  const [svg1, setSvg1] = useSpring(() => ({
    transform: `translateY(${-50}px)`,
    opacity: 0,
  }));
  const [svg2, setSvg2] = useSpring(() => ({
    transform: `translateY(${-100}px)`,
    opacity: 0,
  }));

  const [paragraph1, setParagraph1] = useSpring(() => ({
    transform: `translateX(${-100}px)`,
    opacity: 0,
  }));
  const [paragraph2, setParagraph2] = useSpring(() => ({
    transform: `translateX(${-100}px)`,
    opacity: 0,
  }));
  const [paragraph3, setParagraph3] = useSpring(() => ({
    transform: `translateX(${-100}px)`,
    opacity: 0,
  }));
  const [paragraph4, setParagraph4] = useSpring(() => ({
    transform: `translateX(${-100}px)`,
    opacity: 0,
  }));

  const [row1, setRow1] = useSpring(() => ({
    transform: `translateX(${-100}px)`,
    opacity: 0,
  }));

  const [row2, setRow2] = useSpring(() => ({
    transform: `translateX(${-100}px)`,
    opacity: 0,
  }));
  const [row3, setRow3] = useSpring(() => ({
    transform: `translateX(${-100}px)`,
    opacity: 0,
  }));

  const [row4, setRow4] = useSpring(() => ({
    transform: `translateX(${-100}px)`,
    opacity: 0,
  }));
  const [row5, setRow5] = useSpring(() => ({
    transform: `translateX(${-100}px)`,
    opacity: 0,
  }));

  const [part2, setPart2] = useSpring(() => ({
    backgroundColor: `#f7f3ee`,
  }));

  const startAnimation = () => {
    setTimeout(() => {
      setLogo({
        transform: `translateX(${0}px)`,
        opacity: 1,
      });
    }, 500);
    setTimeout(() => {
      setSvg2({
        transform: `translateY(${0}px)`,
        opacity: 1,
      });
    }, 1000);
    setTimeout(() => {
      setSvg1({
        transform: `translateY(${0}px)`,
        opacity: 1,
      });
    }, 1300);

    setTimeout(() => {
      setParagraph1({
        transform: `translateX(${0}px)`,
        opacity: 1,
      });
    }, 1500);
    setTimeout(() => {
      setParagraph2({
        transform: `translateX(${0}px)`,
        opacity: 1,
      });
    }, 1700);
    setTimeout(() => {
      setParagraph3({
        transform: `translateX(${0}px)`,
        opacity: 1,
      });
    }, 1900);
    setTimeout(() => {
      setParagraph4({
        transform: `translateX(${0}px)`,
        opacity: 1,
      });
    }, 2100);

    setTimeout(() => {
      doneStep();
    }, 500);
  };

  const resetAnimation = () => {
    setLogo({
      transform: `translateX(${-50}px)`,
      opacity: 0,
    });
    setSvg2({
      transform: `translateY(${-100}px)`,
      opacity: 0,
    });
    setSvg1({
      transform: `translateY(${-50}px)`,
      opacity: 0,
    });

    setParagraph1({
      transform: `translateX(${-100}px)`,
      opacity: 0,
    });
    setParagraph2({
      transform: `translateX(${-100}px)`,
      opacity: 0,
    });
    setParagraph3({
      transform: `translateX(${-100}px)`,
      opacity: 0,
    });

    setParagraph4({
      transform: `translateX(${-100}px)`,
      opacity: 0,
    });
  };

  const startAnimation1 = () => {
    setTimeout(() => {
      setPart2({
        backgroundColor: '#0055b8',
      });
    }, 500);
    setTimeout(() => {
      setRow1({
        transform: `translateX(${0}px)`,
        opacity: 1,
      });
    }, 500);
    setTimeout(() => {
      setRow2({
        transform: `translateX(${0}px)`,
        opacity: 1,
      });
    }, 1000);
    setTimeout(() => {
      setRow3({
        transform: `translateX(${0}px)`,
        opacity: 1,
      });
    }, 1300);

    setTimeout(() => {
      setRow4({
        transform: `translateX(${0}px)`,
        opacity: 1,
      });
    }, 1500);
    setTimeout(() => {
      setRow5({
        transform: `translateX(${0}px)`,
        opacity: 1,
      });
    }, 1700);
    setTimeout(() => {
      doneStep();
    }, 500);
  };

  const resetAnimation1 = () => {
    setPart2({
      backgroundColor: `#f7f3ee`,
    });
    setRow1({
      transform: `translateX(${-100}px)`,
      opacity: 0,
    });
    setRow2({
      transform: `translateX(${-100}px)`,
      opacity: 0,
    });
    setRow3({
      transform: `translateX(${-100}px)`,
      opacity: 0,
    });
    setRow4({
      transform: `translateX(${-100}px)`,
      opacity: 0,
    });
    setRow5({
      transform: `translateX(${-100}px)`,
      opacity: 0,
    });
  };

  useEffect(() => {
    if (width > 940) {
      if (status === 16 || status === 14) {
        resetAnimation();
      } else if (status === 15) {
        startAnimation();
      }

      if (status === 17 || status === 15) {
        resetAnimation1();
      } else if (status === 16) {
        startAnimation1();
      }
    } else {
      if (status === 26) {
        resetAnimation();
      } else if (status === 27) {
        startAnimation();
      }

      if (status === 27) {
        resetAnimation1();
      } else if (status === 28) {
        startAnimation1();
      }
    }
  }, [status]);

  return (
    <>
      {width > 940 ? (
        <div onWheel={event => {}} className={style.nasilOlur}>
          <div className={style.part1}>
            <div className={style.top}>
              <a.div style={logo} className={style.logo}>
                <Logo type={'color'} />
              </a.div>
              <a.div style={svg2} className={style.svgWrapper}>
                <a.span style={svg1}>
                  <NasilOlur type={'top'} />
                </a.span>
                <NasilOlur />
              </a.div>
            </div>
            <div className={style.info}>
              <a.p style={paragraph1}>
                Yaptığımız her şey, hayal ettiğimiz geleceğe doğru attığımız
                <br /> bir adım. Eğitim planından fiziksel alanlara, sosyal
                <br />
                etkinliklerden sağlıklı beslenmeye oluşturduğumuz her nokta
                <br /> ve adımın bir “neden”i ve bir “nasıl”ı var.
              </a.p>
              <a.p style={paragraph2}>
                Bir çocuğun, bir gencin gelişim ve ihtiyaçları doğrultusunda
                <br />
                tasarlanmış bütünsel eğitim yaşantısı, bu yaşantıya alan açan{' '}
                <br /> bir okul ve eğitmenler topluluğu, bu birlikteliğe inanan
                veliler
                <br /> ve yolculuğun ana kahramanı öğrenciler… 
              </a.p>
            </div>
            <Discover click={discover1} type={'primary'} />
          </div>
          <a.div className={style.part2}>
            <div className={style.cardWrapper}>
              <a.div
                style={{
                  border: `0.3vh solid #A171AF`,
                  borderTop: `2vh solid #A171AF`,
                  ...row1,
                }}
                className={style.card}
              >
                Bayetav Okulları’nda birlikte yapacaklarımızı keşfetmek ve
                nedenlerimizi, nasıl yapacağımızı daha detaylı konuşabilmek için
                sizi iletişim ve tanıtım ofisimize bekliyoruz.
              </a.div>
              <a.div
                style={{
                  border: `0.3vh solid #FAB648`,
                  borderTop: `2vh solid #FAB648`,
                  ...row2,
                }}
                className={style.card}
              >
                Bayetav Okulları’nda yeni bir öğrenme kültürü oluşturma,
                eğitimde kültürel dönüşüme model olma motivasyonuyla bir araya
                geldik. Velilerimizi de bu hayalin ortağı, topluluğun parçası
                olarak görüyoruz. 
              </a.div>
              <a.div
                style={{
                  border: `0.3vh solid #B9D36C`,
                  borderTop: `2vh solid #B9D36C`,
                  ...row3,
                }}
                className={style.card}
              >
                Gelin, istemediğimiz bir dünya ile tek tek mücadele etmek yerine
                hayal ettiğimiz dünyayı birlikte yaratalım.
              </a.div>
            </div>
            <a.div style={row4} className={style.bottomSvg}>
              <NasilOlurSvg />
            </a.div>
            <Discover type={'primary'} click={discover2} />
          </a.div>
        </div>
      ) : (
        <div onWheel={event => {}} className={style.nasilOlur}>
          <div className={style.part1}>
            <div className={style.top}>
              <a.div className={style.logo}>
                <Logo type={'color'} />
              </a.div>
              <a.div className={style.svgWrapper}>
                <a.span>
                  <NasilOlur type={'top'} />
                </a.span>
                <NasilOlur />
              </a.div>
            </div>
            <div className={style.info}>
              <a.p>
                Yaptığımız her şey, hayal ettiğimiz geleceğe doğru attığımız
                <br /> bir adım. Eğitim planından fiziksel alanlara, sosyal
                <br />
                etkinliklerden sağlıklı beslenmeye oluşturduğumuz her nokta
                <br /> ve adımın bir “neden”i ve bir “nasıl”ı var.
              </a.p>
              <a.p>
                Bir çocuğun, bir gencin gelişim ve ihtiyaçları doğrultusunda
                <br />
                tasarlanmış bütünsel eğitim yaşantısı, bu yaşantıya alan açan{' '}
                <br /> bir okul ve eğitmenler topluluğu, bu birlikteliğe inanan
                veliler
                <br /> ve yolculuğun ana kahramanı öğrenciler… 
              </a.p>
            </div>
            <Discover click={discover1} type={'primary'} />
          </div>
          <a.div className={style.part2}>
            <div className={style.cardWrapper}>
              <a.div
                style={{
                  border: `0.3vh solid #A171AF`,
                  borderTop: `2vh solid #A171AF`,
                }}
                className={style.card}
              >
                Bayetav Okulları’nda birlikte yapacaklarımızı keşfetmek ve
                nedenlerimizi, nasıl yapacağımızı daha detaylı konuşabilmek için
                sizi iletişim ve tanıtım ofisimize bekliyoruz.
              </a.div>
              <a.div
                style={{
                  border: `0.3vh solid #FAB648`,
                  borderTop: `2vh solid #FAB648`,
                }}
                className={style.card}
              >
                Bayetav Okulları’nda yeni bir öğrenme kültürü oluşturma,
                eğitimde kültürel dönüşüme model olma motivasyonuyla bir araya
                geldik. Velilerimizi de bu hayalin ortağı, topluluğun parçası
                olarak görüyoruz. 
              </a.div>
              <a.div
                style={{
                  border: `0.3vh solid #B9D36C`,
                  borderTop: `2vh solid #B9D36C`,
                }}
                className={style.card}
              >
                Gelin, istemediğimiz bir dünya ile tek tek mücadele etmek yerine
                hayal ettiğimiz dünyayı birlikte yaratalım.
              </a.div>
            </div>
            <a.div className={style.bottomSvg}>
              <NasilOlurSvg />
            </a.div>
          </a.div>
        </div>
      )}
    </>
  );
};

export default NasilOlurWrapper;
