import { ButunselEgitimSvg } from "../../assets/icon";
import style from "./butunselegitim.module.scss";
import { useSpring, a } from "@react-spring/web";
import { useEffect } from "react";
import Discover from "../Elements/Discover/Discover";

const ButunselEgitim = ({ status, doneStep, discover1, width }) => {
  const [row1, setRow1] = useSpring(() => ({
    transform: `translateX(${-100}px)`,
    opacity: 0,
  }));
  const [row2, setRow2] = useSpring(() => ({
    transform: `translateX(${-100}px)`,
    opacity: 0,
  }));
  const [row3, setRow3] = useSpring(() => ({
    transform: `translateX(${-100}px)`,
    opacity: 0,
  }));
  const [part, setPart] = useSpring(() => ({
    backgroundColor: `#f7f3ee`,
  }));

  const startAnimation = () => {
    setTimeout(() => {
      setPart({
        backgroundColor: `#0055b8`,
      });
    }, 400);
    setTimeout(() => {
      setRow1({
        transform: `translateX(${0}px)`,
        opacity: 1,
      });
    }, 500);
    setTimeout(() => {
      setRow2({
        transform: `translateX(${0}px)`,
        opacity: 1,
      });
    }, 700);
    setTimeout(() => {
      setRow3({
        transform: `translateX(${0}px)`,
        opacity: 1,
      });
    }, 900);

    setTimeout(() => {
      doneStep();
    }, 500);
  };

  const resetAnimation = () => {
    setRow1({
      transform: `translateX(${-100}px)`,
      opacity: 0,
    });
    setRow2({
      transform: `translateX(${-100}px)`,
      opacity: 0,
    });
    setRow3({
      transform: `translateX(${-100}px)`,
      opacity: 0,
    });
    setPart({
      backgroundColor: `#f7f3ee`,
    });
  };

  useEffect(() => {
    if (width > 940) {
      if (status === 10 || status === 8) {
        resetAnimation();
      } else if (status === 9) {
        startAnimation();
      }
    } else {
      if (status === 15) {
        resetAnimation();
      } else if (status === 16) {
        startAnimation();
      }
    }
  }, [status]);

  return (
    <>
      {width > 940 ? (
        <a.div style={part} className={style.butunselEgitim}>
          <div className={style.content}>
            <a.h2 style={row1}>Bütünsel</a.h2>
            <a.div style={row2} className={style.svgWrapper}>
              <ButunselEgitimSvg />
            </a.div>
            <a.h2 style={row3}>Eğitim</a.h2>
          </div>
          <Discover click={discover1} />
        </a.div>
      ) : (
        <div className={style.butunselEgitim}>
          <div className={style.content}>
            <h2>Bütünsel</h2>
            <div style={row2} className={style.svgWrapper}>
              <ButunselEgitimSvg />
            </div>
            <h2>Eğitim</h2>
          </div>
        </div>
      )}
    </>
  );
};

export default ButunselEgitim;
