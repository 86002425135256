import { NitelikliElement, NitelikliType } from "../../assets/icon";
import style from "./nitelikli.module.scss";
import { useSpring, a, useTrail } from "@react-spring/web";
import { useEffect } from "react";
import Discover from "../Elements/Discover/Discover";

const Card = ({
  text,
  scale,
  top,
  bottom,
  left,
  right,
  color,
  scaleVal,
  width,
  height,
  styleAnim,
}) => {
  return (
    <a.div
      style={{
        scale: scale,
        top: top,
        bottom: bottom,
        left: left,
        right: right,
        border: `0.1vh solid ${color}`,
        borderTop: `0.6vh solid ${color}`,
        scale: scaleVal,
        width: width,
        height: height,
        ...styleAnim,
      }}
      className={style.card}
    >
      <NitelikliElement type={text} />
    </a.div>
  );
};

const Nitelikli = ({ status, doneStep, width, discover1 }) => {
  const [card1, setCard1] = useSpring(() => ({
    transform: `translateX(${-100}px)`,
    opacity: 0,
  }));
  const [card2, setCard2] = useSpring(() => ({
    transform: `translateX(${-100}px)`,
    opacity: 0,
  }));
  const [card3, setCard3] = useSpring(() => ({
    transform: `translateX(${-100}px)`,
    opacity: 0,
  }));
  const [card4, setCard4] = useSpring(() => ({
    transform: `translateX(${-100}px)`,
    opacity: 0,
  }));
  const [card5, setCard5] = useSpring(() => ({
    transform: `translateX(${-100}px)`,
    opacity: 0,
  }));
  const [card6, setCard6] = useSpring(() => ({
    transform: `translateX(${-100}px)`,
    opacity: 0,
  }));

  const data = [
    {
      top: "-15vh",
      bottom: "unset",
      left: "-25vh",
      right: "unset",
      borderColor: "#B9D36C",
      text: "kapsayıcı",
      width: "54vh",
      height: "13vh",
    },

    {
      top: "unset",
      bottom: "0",
      left: "20vh",
      right: "unset",
      borderColor: "#779FD4",
      text: "erişilebilir",
      width: "54vh",
      height: "13vh",
    },
    {
      top: "-10vh",
      bottom: "unset",
      left: "50vh",
      right: "unset",
      borderColor: "#A171AF",
      text: "katılımcı",
      width: "52vh",
      height: "13vh",
    },

    {
      top: "unset",
      bottom: "-7vh",
      left: "100vh",
      right: "unset",
      borderColor: "#A171AF",
      text: "bütünsel",
      width: "41vh",
      height: "13vh",
    },
    {
      top: "-15vh",
      bottom: "unset",
      left: "unset",
      right: "0vh",
      borderColor: "#0055B8",
      text: "çocuk odaklı",
      width: "103vh",
      height: "19vh",
    },
    {
      top: "unset",
      bottom: "-6vh",
      left: "unset",
      right: "-10vh",
      borderColor: "#FAB648",
      text: "kolektif tasarlanan",
      width: "74vh",
      height: "9vh",
    },
  ];

  const startAnimation = () => {
    setTimeout(() => {
      setCard1({
        opacity: 1,
        transform: `translateX(${0}px)`,
      });
    }, 500);
    setTimeout(() => {
      setCard2({
        opacity: 1,
        transform: `translateX(${0}px)`,
      });
    }, 700);
    setTimeout(() => {
      setCard3({
        opacity: 1,
        transform: `translateX(${0}px)`,
      });
    }, 900);
    setTimeout(() => {
      doneStep();
    }, 900);

    setTimeout(() => {
      setCard4({
        opacity: 1,
        transform: `translateX(${0}px)`,
      });
    }, 2000);
    setTimeout(() => {
      setCard5({
        opacity: 1,
        transform: `translateX(${0}px)`,
      });
    }, 2500);
    setTimeout(() => {
      setCard6({
        opacity: 1,
        transform: `translateX(${0}px)`,
      });
    }, 3000);
    setTimeout(() => {
      doneStep();
    }, 500);
  };

  const resetAnimation = () => {
    setCard1({
      opacity: 0,
      transform: `translateX(${-100}px)`,
      immediate: true,
    });
    setCard2({
      opacity: 0,
      transform: `translateX(${-100}px)`,
      immediate: true,
    });
    setCard3({
      opacity: 0,
      transform: `translateX(${-100}px)`,
      immediate: true,
    });
    setCard4({
      opacity: 0,
      transform: `translateX(${-100}px)`,
      immediate: true,
    });
    setCard5({
      opacity: 0,
      transform: `translateX(${-100}px)`,
      immediate: true,
    });
    setCard6({
      opacity: 0,
      transform: `translateX(${-100}px)`,
      immediate: true,
    });
  };

  useEffect(() => {
    if (width > 940) {
      if (status === 7) {
        doneStep();
      }
      if (status === 6 || status === 3) {
        resetAnimation();
      } else if (status === 4 || status === 5) {
        startAnimation();
      }
    } else {
      if (status === 7) {
        doneStep();
      }
      if (status === 7) {
        resetAnimation();
      } else if (status === 8) {
        startAnimation();
      }
    }
  }, [status]);

  return (
    <>
      {width > 940 ? (
        <div className={style.nitelikli}>
          <Discover type={"primary"} click={discover1} />
          <div className={style.svgWrapper}>
            {data.map((item, index) => {
              return (
                <Card
                  top={item.top}
                  bottom={item.bottom}
                  left={item.left}
                  right={item.right}
                  color={item.borderColor}
                  text={item.text}
                  width={item.width}
                  height={item.height}
                  styleAnim={
                    index === 0
                      ? card1
                      : index === 1
                      ? card2
                      : index === 2
                      ? card3
                      : index === 3
                      ? card4
                      : index === 4
                      ? card5
                      : index === 5
                      ? card6
                      : null
                  }
                />
              );
            })}
            <div className={style.mainSvg}>
              <NitelikliType />
            </div>
          </div>
        </div>
      ) : (
        <div className={style.nitelikli}>
          <Discover type={"primary"} click={discover1} />
          <div className={style.svgWrapper}>
            {data.map((item, index) => {
              return (
                <Card
                  top={item.top}
                  bottom={item.bottom}
                  left={item.left}
                  right={item.right}
                  color={item.borderColor}
                  text={item.text}
                  width={item.width}
                  height={item.height}
                  styleAnim={""}
                />
              );
            })}
            <div className={style.mainSvg}>
              <NitelikliType />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Nitelikli;
