import {
  DiscoverArrow,
  Logo,
  LogoFullColor,
  NasilOgretmen,
  NasilOkul,
  NasilOkulElement,
} from "../../assets/icon";
import Card from "../Card/Card";
import OkulImage from "../../assets/img/nasil-okul-image.png";
import OkulImage2 from "../../assets/img/nasil-okul-image-2.png";
import style from "./nasilogretmenwrapper.module.scss";
import useWindowDimensions from "../../helpers/windowWidth";
import { useSpring, a } from "@react-spring/web";
import { useEffect } from "react";
import Discover from "../Elements/Discover/Discover";

const NasilOgretmenWrapper = ({
  status,
  doneStep,
  discover1,
  discover2,
  width,
}) => {
  const [logo, setLogo] = useSpring(() => ({
    transform: `translateX(${-50}px)`,
    opacity: 0,
  }));
  const [svg1, setSvg1] = useSpring(() => ({
    transform: `translateY(${-50}px)`,
    opacity: 0,
  }));
  const [svg2, setSvg2] = useSpring(() => ({
    transform: `translateY(${-100}px)`,
    opacity: 0,
  }));

  const [paragraph1, setParagraph1] = useSpring(() => ({
    transform: `translateX(${-100}px)`,
    opacity: 0,
  }));
  const [paragraph2, setParagraph2] = useSpring(() => ({
    transform: `translateX(${-100}px)`,
    opacity: 0,
  }));
  const [paragraph3, setParagraph3] = useSpring(() => ({
    transform: `translateX(${-100}px)`,
    opacity: 0,
  }));
  const [paragraph4, setParagraph4] = useSpring(() => ({
    transform: `translateX(${-100}px)`,
    opacity: 0,
  }));

  const [row1, setRow1] = useSpring(() => ({
    transform: `translateX(${-100}px)`,
    opacity: 0,
  }));

  const [row2, setRow2] = useSpring(() => ({
    borderColor: "transparent",
  }));
  const [row3, setRow3] = useSpring(() => ({
    borderColor: "transparent",
  }));

  const [row4, setRow4] = useSpring(() => ({
    borderColor: "transparent",
  }));
  const [row5, setRow5] = useSpring(() => ({
    borderColor: "transparent",
  }));
  const [row6, setRow6] = useSpring(() => ({
    borderColor: "transparent",
  }));

  const [row7, setRow7] = useSpring(() => ({
    borderColor: "transparent",
  }));

  const [part2, setPart2] = useSpring(() => ({
    backgroundColor: `#f7f3ee`,
  }));

  const startAnimation = () => {
    setTimeout(() => {
      setLogo({
        transform: `translateX(${0}px)`,
        opacity: 1,
      });
    }, 500);
    setTimeout(() => {
      setSvg2({
        transform: `translateY(${0}px)`,
        opacity: 1,
      });
    }, 700);
    setTimeout(() => {
      setSvg1({
        transform: `translateY(${0}px)`,
        opacity: 1,
      });
    }, 900);

    setTimeout(() => {
      setParagraph1({
        transform: `translateX(${0}px)`,
        opacity: 1,
      });
    }, 1100);
    setTimeout(() => {
      setParagraph2({
        transform: `translateX(${0}px)`,
        opacity: 1,
      });
    }, 1300);
    setTimeout(() => {
      setParagraph3({
        transform: `translateX(${0}px)`,
        opacity: 1,
      });
    }, 1500);
    setTimeout(() => {
      setParagraph4({
        transform: `translateX(${0}px)`,
        opacity: 1,
      });
    }, 1700);
    setTimeout(() => {
      doneStep();
    }, 500);
  };

  const resetAnimation = () => {
    setLogo({
      transform: `translateX(${-50}px)`,
      opacity: 0,
    });
    setSvg2({
      transform: `translateY(${-100}px)`,
      opacity: 0,
    });
    setSvg1({
      transform: `translateY(${-50}px)`,
      opacity: 0,
    });

    setParagraph1({
      transform: `translateX(${-100}px)`,
      opacity: 0,
    });
    setParagraph2({
      transform: `translateX(${-100}px)`,
      opacity: 0,
    });
    setParagraph3({
      transform: `translateX(${-100}px)`,
      opacity: 0,
    });

    setParagraph4({
      transform: `translateX(${-100}px)`,
      opacity: 0,
    });
  };

  const startAnimation1 = () => {
    setTimeout(() => {
      setPart2({
        backgroundColor: "#0055b8",
      });
    }, 500);
    setTimeout(() => {
      setRow1({
        transform: `translateX(${0}px)`,
        opacity: 1,
      });
    }, 500);
    setTimeout(() => {
      setRow2({
        borderColor: "#C0D672",
      });
    }, 1000);
    setTimeout(() => {
      setRow3({
        borderColor: "#A171AF",
      });
    }, 1300);

    setTimeout(() => {
      setRow4({
        borderColor: "#FAB648",
      });
    }, 1500);
    setTimeout(() => {
      setRow5({
        borderColor: "#779FD4",
      });
    }, 1700);
    setTimeout(() => {
      setRow6({
        borderColor: "#A171AF",
      });
    }, 2000);
    setTimeout(() => {
      setRow7({
        borderColor: "#C0D672",
      });
    }, 2300);

    setTimeout(() => {
      doneStep();
    }, 500);
  };

  const resetAnimation1 = () => {
    setPart2({
      backgroundColor: `#f7f3ee`,
    });
    setRow1({
      transform: `translateX(${-100}px)`,
      opacity: 0,
    });
    setRow2({
      borderColor: "transparent",
    });
    setRow3({
      borderColor: "transparent",
    });
    setRow4({
      borderColor: "transparent",
    });
    setRow5({
      borderColor: "transparent",
    });
    setRow6({
      borderColor: "transparent",
    });
    setRow7({
      borderColor: "transparent",
    });
  };

  useEffect(() => {
    if (width > 940) {
      if (status === 11 || status === 9) {
        resetAnimation();
      } else if (status === 10) {
        startAnimation();
      }

      if (status === 12 || status === 10) {
        resetAnimation1();
      } else if (status === 11) {
        startAnimation1();
      }
    } else {
      if (status === 17) {
        resetAnimation();
      } else if (status === 19) {
        startAnimation();
      }

      if (status === 18) {
        resetAnimation1();
      } else if (status === 19) {
        startAnimation1();
      }
    }
  }, [status]);

  return (
    <>
      {width > 940 ? (
        <div onWheel={(event) => {}} className={style.nasilOgretmen}>
          <div className={style.part1}>
            <div className={style.top}>
              <a.div style={logo} className={style.logo}>
                <Logo type={"color"} />
              </a.div>
              <a.div style={svg2} className={style.svgWrapper}>
                <a.span style={svg1}>
                  <NasilOgretmen type={"top"} />
                </a.span>
                <NasilOgretmen />
              </a.div>
            </div>
            <div className={style.info}>
              <a.p style={paragraph1}>
                Okulun tasarımından
                <br /> uygulamasına tüm <br />
                süreçlerde etkin
              </a.p>
              <a.p style={paragraph2}>
                Çağın değişen sorunları
                <br /> ve doğurduğu ihtiyaçları
                <br /> gözlemleyen
              </a.p>
              <a.p style={paragraph3}>
                Bu değişimlerin eğitim-öğretim
                <br /> yaşantısındaki etkisini
                <br /> fark eden
              </a.p>
              <a.p style={paragraph4}>
                <a target="__blank" href="/ogretmen-basvuru">
                  Bayetav Okulları öğretmenlerine katılmak için
                  <br /> bize ulaşabilirsiniz
                  <DiscoverArrow />
                </a>
              </a.p>
            </div>
            <Discover click={discover1} type={"primary"} />
          </div>
          <a.div style={part2} className={style.part2}>
            <a.p style={row1}>
              Çocukları seven, onlara{" "}
              <a.span
                style={{
                  ...row2,
                }}
              >
                sevildiklerini
              </a.span>{" "}
              hissettiren
              <br /> Bulunduğu ortam ve toplulukta{" "}
              <a.span
                style={{
                  ...row3,
                }}
              >
                kendini değerli hisseden
              </a.span>{" "}
              <br />
              Eğitime dair hayalleri olan, heyecanı yüksek <br />{" "}
              <a.span
                style={{
                  ...row4,
                }}
              >
                Sivil toplum çalışmaları deneyimine sahip,
              </a.span>{" "}
              hak savunucusu <br /> Topluma, çevreye dair duyarlılığa sahip{" "}
              <br /> Bir arada yaşama ve öğrenme kültürünü benimseyen <br />{" "}
              Kapsayıcı,{" "}
              <a.span
                style={{
                  ...row5,
                }}
              >
                adil,
              </a.span>{" "}
              hakkaniyetli bir sınıf iklimi yaratan <br /> Çocukların gelişimsel
              ihtiyaçlarını gözeten <br />
              Öğrenme deneyiminde kolaylaştırıcı <br /> Öğrenci ile{" "}
              <a.span
                style={{
                  ...row6,
                }}
              >
                karşılıklı öğrenme sürecinde olan
              </a.span>{" "}
              <br /> Hem kendini hem de{" "}
              <a.span
                style={{
                  ...row7,
                }}
              >
                öğrencileri geleceğe taşıyan
              </a.span>
            </a.p>
            <Discover click={discover2} />
          </a.div>
        </div>
      ) : (
        <div
          id="ogretmen"
          onWheel={(event) => {}}
          className={style.nasilOgretmen}
        >
          <div className={style.part1}>
            <div className={style.top}>
              <div className={style.logo}>
                <Logo type={"color"} />
              </div>
              <div className={style.svgWrapper}>
                <span>
                  <NasilOgretmen type={"top"} />
                </span>
                <NasilOgretmen />
              </div>
            </div>
            <div className={style.info}>
              <p>
                Okulun tasarımından
                <br /> uygulamasına tüm <br />
                süreçlerde etkin
              </p>
              <p>
                Çağın değişen sorunları
                <br /> ve doğurduğu ihtiyaçları
                <br /> gözlemleyen
              </p>
              <p>
                Bu değişimlerin eğitim-öğretim
                <br /> yaşantısındaki etkisini
                <br /> fark eden
              </p>
              <p>
                <a target="__blank" href="/ogretmen-basvuru">
                  Bayetav Okulları öğretmenlerine katılmak için
                  <br /> bize ulaşabilirsiniz
                  <DiscoverArrow />
                </a>
              </p>
            </div>
            <Discover click={discover1} type={"primary"} />
          </div>
          <div className={style.part2}>
            <p style={row1}>
              Çocukları seven, onlara <a.span>sevildiklerini</a.span>{" "}
              hissettiren
              <br /> Bulunduğu ortam ve toplulukta{" "}
              <a.span>kendini değerli hisseden</a.span> <br />
              Eğitime dair hayalleri olan, heyecanı yüksek <br />{" "}
              <a.span>Sivil toplum çalışmaları deneyimine sahip,</a.span> hak
              savunucusu <br /> Topluma, çevreye dair duyarlılığa sahip <br />{" "}
              Bir arada yaşama ve öğrenme kültürünü benimseyen <br /> Kapsayıcı,{" "}
              <a.span>adil,</a.span> hakkaniyetli bir sınıf iklimi yaratan{" "}
              <br /> Çocukların gelişimsel ihtiyaçlarını gözeten <br />
              Öğrenme deneyiminde kolaylaştırıcı <br /> Öğrenci ile{" "}
              <a.span>karşılıklı öğrenme sürecinde olan</a.span> <br /> Hem
              kendini hem de <a.span>öğrencileri geleceğe taşıyan</a.span>
            </p>
          </div>
        </div>
      )}
    </>
  );
};

export default NasilOgretmenWrapper;
