import {
  HomeIntro,
  NasilOkulWrapper,
  NasilOgretmenWrapper,
  BayetavInfo,
  NasilVeliWrapper,
  VideoWrapper,
  NasilOgrenciWrapper,
  PoemWrapper,
  FormWrapper,
  NavBar,
  Intro,
  ImageWrapper,
  Nitelikli,
  NasilOlurWrapper,
  BirAradaYasariz,
  ButunselEgitim,
  Ilkelerimiz,
  PopupKvkk,
  ContactCard,
  WhatsappCard,
} from "../../components";
import styleMain from "./home.module.scss";
import { useRef, useEffect, useCallback, useState } from "react";
import { Controls, PlayState, Timeline, Tween } from "react-gsap";
import { useDebouncedCallback } from "use-debounce";
import { useGesture } from "@use-gesture/react";
import { useSpring, a } from "@react-spring/web";
import { useScroll } from "react-use-gesture";
import useWindowDimensions from "../../helpers/windowWidth";
import { useLocation, useNavigate } from "react-router-dom";
import { NasilOkul } from "../../assets/icon";
import OpeningWrapper from "../../components/OpeningWrapper/OpeningWrapper";

export function useHorizontalScroll() {
  const elRef = useRef();
  useEffect(() => {
    const el = elRef.current;
    if (el) {
      const onWheel = (e) => {
        if (e.deltaY == 0) return;
        e.preventDefault();
        el.scrollTo({
          left:
            e.deltaY > 0
              ? el.scrollLeft + e.deltaY + 300
              : el.scrollLeft + e.deltaY - 300,
          behavior: "smooth",
        });
      };
      el.addEventListener("wheel", onWheel);
      return () => el.removeEventListener("wheel", onWheel);
    }
  }, []);
  return elRef;
}

const Home = () => {
  //   const containerRef = useRef();

  //   const debounced = useDebouncedCallback(
  //     // function
  //     value => {
  //       handleScroll(value);
  //     },
  //     // delay in ms
  //     15
  //   );

  const clamp = (value, clampAt = 30) => {
    if (value > 0) {
      return value > clampAt ? clampAt : value;
    } else {
      return value < -clampAt ? -clampAt : value;
    }
  };

  //   const bind = useScroll(event => {
  //     console.log('venet', event.deltaY);
  //     set({
  //       transform: `perspective(500px) rotateY(${
  //         event.scrolling ? clamp(event.delta[0]) : 0
  //       }deg) translate3d(0,${clamp(event.deltaY)}%,0)`,
  //     });
  //   });

  const containerRef = useRef();
  const { width } = useWindowDimensions();
  const [step, setStep] = useState(0);
  const [scrollStatus, setScrollStatus] = useState(false);
  const [stepNumber, setStepNumber] = useState(1);
  const [isNav, setNav] = useState(false);
  const [isPopup, setPopup] = useState(false);
  const [isIntro, setIntro] = useState(true);
  const location = useLocation();
  const currentRoute = location.pathname;

  const [mobileContact, setMobileContact] = useState(true);

  const [style, set] = useSpring(() => ({
    transform: "translateX(0px)",
  }));
  console.log("hın", step, scrollStatus);

  useEffect(() => {
    if (currentRoute === "/iletisim") {
      setIntro(false);
      if (width > 940) {
        set({
          transform: `translateX(${-(width * 18)}px)`,
          immediate: true,
        });
        setStep(18);
      } else {
        window.location.href = "#iletisim";
      }
    }
  }, []);

  const [touchDelta, setTouchDelta] = useState({
    x: 0,
    y: 0,
  });

  const [latestDelta, setLatestDelta] = useState({
    x: 0,
    y: 0,
  });

  //   console.log('width', width);

  useEffect(() => {
    // if (width < 940) {
    //   set({
    //     transform: `translateX(${-(width * step)}px)`,
    //   });
    // }

    if (width < 940) {
      if (step !== 2 && step !== 3 && step !== 4) {
        setScrollStatus(false);
      }
    } else {
      setScrollStatus(false);
    }

    // const safeSteps = [
    //   1, 2, 3, 4, 7, 8, 9, 12, 13, 14, 15, 16, 18, 19, 21, 22, 23, 24, 28, 29,
    //   30,
    // ];

    // if (!safeSteps.includes(step)) {
    //   set({
    //     transform: `translateX(${-(width * step)}px)`,
    //   });
    // }
  }, [step]);

  useEffect(() => {
    if (currentRoute !== "/iletisim") {
      set({
        transform: `translateX(${-(width * step)}px)`,
      });
    }
  }, [width]);

  useEffect(() => {
    if (width < 940) {
      setScrollStatus(false);
      setTimeout(() => {
        setScrollStatus(true);
      }, 200);
    }
  }, [step]);

  const navigate = useNavigate();

  const [isNavOpen, setNavOpen] = useState(false);

  const [mobileScroll, setMobileScroll] = useState(0);

  const scrollEvent = (e) => {
    const target = e.target;
    setMobileScroll(target.scrollTop);
    console.log("Current scroll position:", target.scrollTop);
  };

  return (
    <>
      <ContactCard
        click={() => {
          if (width > 940) {
            set({
              transform: `translateX(${-(width * 17)}px)`,
            });
            setStep(17);
          } else {
            window.location.href = "#iletisim";
          }
        }}
        status={
          !isIntro &&
          (width > 940 ? step !== 17 : mobileScroll > 11200 ? false : true) &&
          !isNavOpen
        }
        width={width}
      />
      <WhatsappCard
        click={() => {
          if (width > 940) {
            set({
              transform: `translateX(${-(width * 17)}px)`,
            });
            setStep(17);
          } else {
            window.location.href = "#iletisim";
          }
        }}
        status={
          !isIntro &&
          (width > 940 ? step !== 18 : mobileScroll > 11200 ? false : true) &&
          !isNavOpen
        }
        width={width}
      />
      {isPopup ? <PopupKvkk closeClick={() => setPopup(false)} /> : null}
      <NavBar
        width={width}
        okulChange={() => {
          if (width > 940) {
            set({
              transform: `translateX(${-(width * 1)}px)`,
            });
            setStep(1);
          } else {
            // navigate('/', { state: { targetId: '#okul' } });
            window.location.href = "#okul";
          }
        }}
        ogrenciChange={() => {
          if (width > 940) {
            set({
              transform: `translateX(${-(width * 7)}px)`,
            });
            setStep(7);
          } else {
            window.location.href = "#ogrenci";
          }
        }}
        ogretmenChange={() => {
          if (width > 940) {
            set({
              transform: `translateX(${-(width * 10)}px)`,
            });
            setStep(10);
          } else {
            window.location.href = "#ogretmen";
          }
        }}
        veliChange={() => {
          if (width > 940) {
            set({
              transform: `translateX(${-(width * 12)}px)`,
            });
            setStep(12);
          } else {
            window.location.href = "#veli";
          }
        }}
        contactChange={() => {
          if (width > 940) {
            set({
              transform: `translateX(${-(width * 17)}px)`,
            });
            setStep(17);
          } else {
            window.location.href = "#iletisim";
          }
        }}
        sssChange={() => {
          navigate("/sss");
        }}
        status={!isIntro}
        current={step}
        brosurChange={() => {
          navigate("/brosur");
        }}
        navChange={(e) => {
          setNavOpen(e);
        }}
      />
      {isIntro ? <Intro changeStatus={() => setIntro(false)} /> : null}

      {width > 940 ? (
        <a.div
          className={`${styleMain.home}`}
          style={{
            ...style,
          }}
          ref={containerRef}
          onTouchStart={(e) => {
            setTouchDelta({
              x: e.touches[0].clientX,
              y: e.touches[0].clientY,
            });
          }}
          onTouchEnd={(e) => {
            // const deltaVal = e.touches[0].clientY - touchDelta.y;
            const currentDelta =
              Math.abs(latestDelta.y) > Math.abs(latestDelta.x)
                ? latestDelta.y
                : Math.sign(latestDelta.x) >= 0
                ? -Math.abs(latestDelta.x)
                : Math.abs(latestDelta.x);

            const currentDirection =
              Math.abs(latestDelta.y) > Math.abs(latestDelta.x)
                ? "dikey"
                : "yatay";
            console.log(currentDirection);

            const safeSteps = [
              1, 2, 3, 4, 7, 8, 9, 12, 13, 14, 15, 16, 18, 19, 21, 22, 23, 24,
              28, 29, 30,
            ];

            if (!safeSteps.includes(step) && scrollStatus) {
              if (currentDelta > 0 && step !== 32) {
                set({
                  transform: `translateX(${-(width * (step + 1))}px)`,
                });
                setStep(step + 1);
              } else if (currentDelta < 0 && step !== 0) {
                if (step === 32 && currentDirection === "yatay") {
                  set({
                    transform: `translateX(${-(width * (step - 1))}px)`,
                  });
                  setStep(step - 1);
                } else if (step !== 32) {
                  set({
                    transform: `translateX(${-(width * (step - 1))}px)`,
                  });
                  setStep(step - 1);
                }
              }
            }

            console.log(latestDelta);
          }}
          onTouchMove={(e) => {
            const currentVal = parseFloat(
              style.transform.animation.to
                .split("(")[1]
                .split(")")[0]
                .split("px")[0]
            );
            const deltaX = e.touches[0].clientX - touchDelta.x;
            const deltaVal = e.touches[0].clientY - touchDelta.y;

            const currentDelta =
              Math.abs(deltaX) > Math.abs(deltaVal)
                ? deltaX
                : Math.sign(deltaVal) >= 0
                ? -Math.abs(deltaVal)
                : Math.abs(deltaVal);

            setLatestDelta({
              x: deltaX,
              y: deltaVal,
            });

            const safeSteps = [
              1, 2, 3, 4, 7, 8, 9, 12, 13, 14, 15, 16, 18, 19, 21, 22, 23, 24,
              28, 29, 30,
            ];

            // console.log(deltaVal);

            setStep(Math.floor(Math.abs(currentVal) / width));

            if (safeSteps.includes(step)) {
              set({
                transform: `translateX(${currentDelta / 1.2 + currentVal}px)`,
              });
            } else {
              set({
                transform: `translateX(${-(width * step)}px)`,
              });
            }

            // touchmove(e);
          }}
          onWheel={(event) => {
            const currentVal = parseFloat(
              style.transform.animation.to
                .split("(")[1]
                .split(")")[0]
                .split("px")[0]
            );
            const currentDelta =
              Math.sign(event.deltaY) >= 0
                ? -Math.abs(event.deltaY)
                : Math.abs(event.deltaY);
            const totalStep = width > 940 ? 17 : 32;

            setStep(Math.floor(Math.abs(currentVal) / width));

            if (scrollStatus || step === 18) {
              if (currentVal > 0) {
                set({
                  transform: `translateX(${0}px)`,
                });
              } else if (currentVal < -(width * totalStep)) {
                set({
                  transform: `translateX(${-(width * totalStep)}px)`,
                });
              } else {
                set({
                  transform: `translateX(${currentDelta + currentVal}px)`,
                });
              }
            } else {
              set({
                transform: `translateX(${-(width * step)}px)`,
              });
            }
          }}
        >
          <OpeningWrapper
            width={width}
            doneStep={() => setScrollStatus(true)}
            status={isIntro ? -1 : step}
            discover1={() => {
              set({
                transform: `translateX(${-(width * (step + 1))}px)`,
              });
              setStep(step + 1);
            }}
          />
          <NasilOkulWrapper
            width={width}
            doneStep={() => setScrollStatus(true)}
            status={step}
            discover1={() => {
              set({
                transform: `translateX(${-(width * (step + 1))}px)`,
              });
              setStep(step + 1);
            }}
            discover2={() => {
              set({
                transform: `translateX(${-(width * (step + 1))}px)`,
              });
              setStep(step + 1);
            }}
          />
          <ImageWrapper
            width={width}
            doneStep={() => setScrollStatus(true)}
            status={step}
            discover1={() => {
              set({
                transform: `translateX(${-(width * (step + 1))}px)`,
              });
              console.log("GG");
              setStep(step + 1);
            }}
          />
          <Nitelikli
            width={width}
            doneStep={() => setScrollStatus(true)}
            status={step}
            discover1={() => {
              set({
                transform: `translateX(${-(width * (step + 1))}px)`,
              });
              setStep(step + 1);
            }}
          />
          <VideoWrapper
            width={width}
            doneStep={() => setScrollStatus(true)}
            status={step}
            discover1={() => {
              set({
                transform: `translateX(${-(width * (step + 1))}px)`,
              });
              setStep(step + 1);
            }}
          />
          <NasilOgrenciWrapper
            width={width}
            doneStep={() => setScrollStatus(true)}
            status={step}
            discover1={() => {
              set({
                transform: `translateX(${-(width * (step + 1))}px)`,
              });
              setStep(step + 1);
            }}
            discover2={() => {
              set({
                transform: `translateX(${-(width * (step + 1))}px)`,
              });
              setStep(step + 1);
            }}
          />
          <ButunselEgitim
            width={width}
            doneStep={() => setScrollStatus(true)}
            status={step}
            discover1={() => {
              set({
                transform: `translateX(${-(width * (step + 1))}px)`,
              });
              setStep(step + 1);
            }}
          />
          <NasilOgretmenWrapper
            width={width}
            doneStep={() => setScrollStatus(true)}
            status={step}
            discover1={() => {
              set({
                transform: `translateX(${-(width * (step + 1))}px)`,
              });
              setStep(step + 1);
            }}
            discover2={() => {
              set({
                transform: `translateX(${-(width * (step + 1))}px)`,
              });
              setStep(step + 1);
            }}
          />
          <NasilVeliWrapper
            width={width}
            doneStep={() => setScrollStatus(true)}
            status={step}
            discover1={() => {
              set({
                transform: `translateX(${-(width * (step + 1))}px)`,
              });
              setStep(step + 1);
            }}
            discover2={() => {
              set({
                transform: `translateX(${-(width * (step + 1))}px)`,
              });
              setStep(step + 1);
            }}
          />
          <Ilkelerimiz
            width={width}
            doneStep={() => setScrollStatus(true)}
            status={step}
            discover1={() => {
              set({
                transform: `translateX(${-(width * (step + 1))}px)`,
              });
              setStep(step + 1);
            }}
          />
          <ImageWrapper
            width={width}
            doneStep={() => setScrollStatus(true)}
            status={step}
            second
            discover1={() => {
              set({
                transform: `translateX(${-(width * (step + 1))}px)`,
              });
              setStep(step + 1);
            }}
          />
          <BirAradaYasariz
            width={width}
            doneStep={() => setScrollStatus(true)}
            status={step}
            discover1={() => {
              set({
                transform: `translateX(${-(width * (step + 1))}px)`,
              });
              setStep(step + 1);
            }}
          />

          <FormWrapper
            status={step}
            popupClick={() => setPopup(true)}
            doneStep={() => setScrollStatus(true)}
            backClick={() => {
              set({
                transform: `translateX(${0}px)`,
              });
              setStep(0);
            }}
            changeContact={(e) => setMobileContact(e)}
          />
        </a.div>
      ) : (
        <div onScroll={scrollEvent} className={styleMain.home}>
          <OpeningWrapper />
          <NasilOkulWrapper />
          <ImageWrapper />
          <VideoWrapper />
          <NasilOgrenciWrapper />
          <ButunselEgitim />
          <NasilOgretmenWrapper />
          <Ilkelerimiz />
          <NasilVeliWrapper />
          <ImageWrapper second />
          <NasilOlurWrapper />
          <BirAradaYasariz />
          <FormWrapper
            backClick={() => {
              window.location.href = "#okul";
            }}
            popupClick={() => setPopup(true)}
          />
          {/* <Nitelikli /> */}
        </div>
      )}
      {/* <Controls>
        <Timeline
          target={
           
          }
        >
          <Tween from={{ opacity: 0 }} to={{ opacity: 1 }} duration={2} />
          <Tween to={{ x: '200px' }} />
          <Tween to={{ rotation: 180 }} position="+=1" />
        </Timeline>
      </Controls> */}
    </>
  );
};

export default Home;
