import { DiscoverArrow } from '../../../assets/icon';
import stlye from './discover.module.scss';

const Discover = ({ type, click }) => {
  return (
    <div onClick={click} className={stlye.discover}>
      <h5
        style={{
          color: type === 'primary' ? '#0055b8' : '#c0d672',
        }}
      >
        Keşfet
      </h5>
      <DiscoverArrow color={type} />
    </div>
  );
};

export default Discover;
