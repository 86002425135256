import Image1 from "../../assets/img/opening.jpeg";
import Image2 from "../../assets/img/opening.jpeg";
import {
  Logo,
  LogoFullColor,
  NasilOkul,
  NasilOkulElement,
} from "../../assets/icon";
import Card from "../Card/Card";
import style from "./imagewrapper.module.scss";
import useWindowDimensions from "../../helpers/windowWidth";
import { useSpring, a, useTrail } from "@react-spring/web";
import { useEffect } from "react";
import Discover from "../Elements/Discover/Discover";
import { useNavigate } from "react-router-dom";

const OpeningWrapper = ({ status, doneStep, discover1, discover2, width }) => {
  const [info1, setInfo1] = useSpring(() => ({
    transform: `translateX(${-100}px)`,
    opacity: 0,
  }));
  const [info2, setInfo2] = useSpring(() => ({
    transform: `translateX(${-100}px)`,
    opacity: 0,
  }));
  const [info3, setInfo3] = useSpring(() => ({
    transform: `translateX(${-100}px)`,
    opacity: 0,
  }));

  const [info4, setInfo4] = useSpring(() => ({
    transform: `translateX(${-100}px)`,
    opacity: 0,
  }));

  const startAnimation = () => {
    setTimeout(() => {
      setInfo1({
        transform: `translateX(${0}px)`,
        opacity: 1,
      });
    }, 500);
    setTimeout(() => {
      setInfo2({
        transform: `translateX(${0}px)`,
        opacity: 1,
      });
    }, 700);
    setTimeout(() => {
      setInfo3({
        transform: `translateX(${0}px)`,
        opacity: 1,
      });
    }, 900);
    setTimeout(() => {
      setInfo4({
        transform: `translateX(${0}px)`,
        opacity: 1,
      });
    }, 1100);
    setTimeout(() => {
      doneStep();
    }, 1000);
  };

  const resetAnimation = () => {
    setInfo1({
      transform: `translateX(${-100}px)`,
      opacity: 0,
      immediate: true,
    });
    setInfo2({
      transform: `translateX(${-100}px)`,
      opacity: 0,
      immediate: true,
    });
    setInfo3({
      transform: `translateX(${-100}px)`,
      opacity: 0,
      immediate: true,
    });
    setInfo4({
      transform: `translateX(${-100}px)`,
      opacity: 0,
      immediate: true,
    });
  };

  useEffect(() => {
    if (width > 940) {
      if (status === 1) {
        resetAnimation();
      } else if (status === 0) {
        startAnimation();
      }

      if (status === 0 || status === 1) {
        resetAnimation();
      } else if (status === 1) {
        startAnimation();
      }
    } else {
      if (status === 200) {
        resetAnimation();
      } else if (status === 0) {
        startAnimation();
      }

      if (status === 0) {
        resetAnimation();
      } else if (status === 1) {
        startAnimation();
      }
    }
  }, [status]);

  return (
    <>
      {width > 940 ? (
        <div className={style.imageWrapper}>
          <img src={Image1} />
          <Discover click={discover1} />
        </div>
      ) : (
        <div className={style.imageWrapper}>
          <img src={Image2} />
          {/* <Discover click={discover1} /> */}
        </div>
      )}
    </>
  );
};

export default OpeningWrapper;
