import { DiscoverArrow } from '../../../assets/icon';
import stlye from './backtostory.module.scss';

const BackToStory = ({ type, click }) => {
  return (
    <div onClick={click} className={stlye.backToStory}>
      <DiscoverArrow color={'primary'} />
      <h5>Başa Dön</h5>
    </div>
  );
};

export default BackToStory;
