import style from "./intro.module.scss";
import { useSpring, a } from "@react-spring/web";
import { useEffect, useState } from "react";
import useWindowDimensions from "../../helpers/windowWidth";
import SchoolImage from "../../assets/img/nasil-okul-image-2.png";

const Intro = ({ status, changeStatus }) => {
  const [containerStyle, setContainer] = useSpring(() => ({
    backgroundColor: `#0055B8`,
    opacity: 1,
  }));
  const [svgStyle, setSvg] = useSpring(() => ({
    fill: `#016EEC`,
  }));

  const [svgWrapperStyle, setSvgWrapper] = useSpring(() => ({
    opacity: 0,
    scale: "1.3",
  }));

  const [imageStyle, setImageStyle] = useSpring(() => ({
    height: "0",
    width: "50%",
  }));

  const [recStyle, setRec] = useSpring(() => ({
    height: "0",
  }));
  const [recStyle2, setRec2] = useSpring(() => ({
    height: "0",
    opacity: 1,
  }));

  const [logoStyle, setLogo] = useSpring(() => ({
    opacity: 0,
  }));

  const [color, setColor] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setSvgWrapper({
        opacity: 1,
        scale: "1",
      });
    }, 0);

    setTimeout(() => {
      setSvg({
        fill: `white`,
      });
    }, 0);

    setTimeout(() => {
      setSvgWrapper({
        scale: "0.1",
        opacity: 0,
      });
    }, 0);

    setTimeout(() => {
      setLogo({
        opacity: 1,
      });
    }, 0);

    setTimeout(() => {
      setImageStyle({
        height: "50%",
      });
    }, 0);
    setTimeout(() => {
      setImageStyle({
        width: "100%",
        height: "100%",
      });
    }, 0);

    setTimeout(() => {
      setRec({
        height: "100%",
      });
    }, 0);
    setTimeout(() => {
      setRec2({
        height: "100%",
      });
    }, 0);
    setTimeout(() => {
      setColor(true);
    }, 0);

    setTimeout(() => {
      setLogo({
        opacity: 0,
      });
    }, 0);

    setTimeout(() => {
      setContainer({
        opacity: 0,
      });
    }, 0);

    setTimeout(() => {
      changeStatus();
    }, 0);
    // setTimeout(() => {
    //   setSvgWrapper({
    //     opacity: 0,
    //     scale: '1',
    //   });
    // }, 4300);
  }, []);

  return <a.div style={containerStyle} className={style.intro}></a.div>;
};

export default Intro;
