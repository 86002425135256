import { IlkeCircle } from "../../assets/icon";
import style from "./ilkelerimiz.module.scss";
import { useSpring, a } from "@react-spring/web";
import { useEffect } from "react";
import Discover from "../Elements/Discover/Discover";

const Ilkelerimiz = ({ status, doneStep, discover1, width }) => {
  const [title, setTitle] = useSpring(() => ({
    transform: `translateX(${-100}px)`,
    opacity: 0,
  }));
  const [circle1, setCircle1] = useSpring(() => ({
    width: "0vh",
    height: "0vh",
  }));
  const [circle2, setCircle2] = useSpring(() => ({
    width: "0vh",
    height: "0vh",
  }));
  const [circle3, setCircle3] = useSpring(() => ({
    width: "0vh",
    height: "0vh",
  }));
  const [circle4, setCircle4] = useSpring(() => ({
    width: "0vh",
    height: "0vh",
  }));

  const [text1, setText1] = useSpring(() => ({
    transform: `translateX(${-100}px)`,
    opacity: 0,
  }));
  const [text2, setText2] = useSpring(() => ({
    transform: `translateX(${-100}px)`,
    opacity: 0,
  }));
  const [text3, setText3] = useSpring(() => ({
    transform: `translateX(${-100}px)`,
    opacity: 0,
  }));
  const [text4, setText4] = useSpring(() => ({
    transform: `translateX(${-100}px)`,
    opacity: 0,
  }));

  const startAnimation = () => {
    setTimeout(() => {
      setTitle({
        transform: `translateX(${0}px)`,
        opacity: 1,
      });
    }, 500);
    setTimeout(() => {
      setText1({
        transform: `translateX(${0}px)`,
        opacity: 1,
      });
    }, 1400);
    setTimeout(() => {
      setCircle1({
        width: "89vh",
        height: "89vh",
      });
    }, 1200);
    setTimeout(() => {
      setText2({
        transform: `translateX(${0}px)`,
        opacity: 1,
      });
    }, 1700);
    setTimeout(() => {
      setCircle2({
        width: "69vh",
        height: "69vh",
      });
    }, 1500);
    setTimeout(() => {
      setText3({
        transform: `translateX(${0}px)`,
        opacity: 1,
      });
    }, 2000);
    setTimeout(() => {
      setCircle3({
        width: "50vh",
        height: "50vh",
      });
    }, 1800);

    setTimeout(() => {
      setText4({
        transform: `translateX(${0}px)`,
        opacity: 1,
      });
    }, 2200);
    setTimeout(() => {
      setCircle4({
        width: "30vh",
        height: "30vh",
      });
    }, 2100);

    setTimeout(() => {
      doneStep();
    }, 500);
  };

  const resetAnimation = () => {
    setCircle1({
      width: "0vh",
      height: "0vh",
    });
    setCircle2({
      width: "0vh",
      height: "0vh",
    });
    setCircle3({
      width: "0vh",
      height: "0vh",
    });
    setCircle4({
      width: "0vh",
      height: "0vh",
    });

    setText1({
      transform: `translateX(${-100}px)`,
      opacity: 0,
    });
    setText2({
      transform: `translateX(${-100}px)`,
      opacity: 0,
    });
    setText3({
      transform: `translateX(${-100}px)`,
      opacity: 0,
    });
    setText4({
      transform: `translateX(${-100}px)`,
      opacity: 0,
    });

    setTitle({
      transform: `translateX(${-100}px)`,
      opacity: 0,
    });
  };

  useEffect(() => {
    if (width > 940) {
      if (status === 15 || status === 13) {
        resetAnimation();
      } else if (status === 14) {
        startAnimation();
      }
    } else {
      if (status === 23) {
        resetAnimation();
      } else if (status === 24) {
        startAnimation();
      }
    }
  }, [status]);

  return (
    <>
      {width > 940 ? (
        <div className={style.ilkelerimiz}>
          <div className={style.content}>
            <a.h2 style={title}>İlkelerimiz</a.h2>
            <div className={style.circleWrapper}>
              <div className={style.circle}>
                <a.h3 style={text1}>
                  Bir Arada <br />
                  Yaşama Kültürü
                </a.h3>
                <a.span style={circle1}>
                  <IlkeCircle type={0} />
                </a.span>
              </div>
              <div className={style.circle}>
                <a.h3 style={text2}>
                  Adaletli Eşitlik <br />
                  Hakkaniyet
                </a.h3>
                <a.span style={circle2}>
                  <IlkeCircle type={1} />
                </a.span>
              </div>
              <div className={style.circle}>
                <a.h3 style={text3}> Kapsayıcılık</a.h3>
                <a.span style={circle3}>
                  <IlkeCircle type={2} />
                </a.span>
              </div>
              <div className={style.circle}>
                <a.h3 style={text4}>
                  Ayrımcılığa Karşı
                  <br />
                  Hak Temelli <br />
                  Yaklaşım
                </a.h3>
                <a.span style={circle4}>
                  <IlkeCircle />
                </a.span>
              </div>
            </div>
          </div>
          <Discover click={discover1} />
        </div>
      ) : (
        <div className={style.ilkelerimiz}>
          <div className={style.content}>
            <h2>İlkelerimiz</h2>
            <div className={style.circleWrapper}>
              <div className={style.circle}>
                <h3>
                  Bir Arada <br />
                  Yaşama Kültürü
                </h3>
                <span>
                  <IlkeCircle type={0} />
                </span>
              </div>
              <div className={style.circle}>
                <a.h3>
                  Adaletli Eşitlik <br />
                  Hakkaniyet
                </a.h3>
                <a.span>
                  <IlkeCircle type={1} />
                </a.span>
              </div>
              <div className={style.circle}>
                <a.h3> Kapsayıcılık</a.h3>
                <a.span>
                  <IlkeCircle type={2} />
                </a.span>
              </div>
              <div className={style.circle}>
                <a.h3>
                  Ayrımcılığa Karşı
                  <br />
                  Hak Temelli <br />
                  Yaklaşım
                </a.h3>
                <a.span>
                  <IlkeCircle />
                </a.span>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Ilkelerimiz;
