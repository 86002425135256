import { Form, Formik, Field } from 'formik';
import style from './ogretmenForm.module.scss';
import {
  DeleteIcon,
  EditIcon,
  FormBG,
  Logo,
  UploadIcon,
} from '../../assets/icon';
import { useSpring, a } from '@react-spring/web';
import { useEffect, useState } from 'react';
import Airtable from 'airtable';
import axios from 'axios';
import BackToStory from '../Elements/BackToStory/BackToStory';
import * as Yup from 'yup';
import { CityData, DistrictData } from '../../helpers/CityData';
import useWindowDimensions from '../../helpers/windowWidth';

const InputWrapper = ({
  title,
  half,
  name,
  value,
  placeholder,
  student,
  change,
  error,
  select,
  selectValue,
  type,
  textarea,
  disabled,
  city,
  date,
}) => {
  return (
    <label className={`${style.inputLabel} ${half ? style.half : null}`}>
      <h6>{title}</h6>
      {student && !date && !select ? (
        <input
          name={name}
          value={value}
          placeholder={placeholder}
          type={type ? type : 'text'}
          onChange={change}
          disabled={disabled}
          id={name}
        />
      ) : student && date ? (
        <input
          name={name}
          value={value}
          placeholder={placeholder}
          type={'date'}
          onChange={change}
          disabled={disabled}
          max={new Date().toJSON().slice(0, 10)}
          id={name}
        />
      ) : student && select ? (
        <select id={name} value={value} disabled={disabled} onChange={change}>
          <option className={style.disabled} selected disabled value={''}>
            Seçim Yapınız
          </option>
          {selectValue &&
            selectValue.length &&
            selectValue.map(item => {
              return <option value={item}>{item}</option>;
            })}
        </select>
      ) : select ? (
        <Field
          name={name}
          value={value}
          placeholder={placeholder}
          as={'select'}
          disabled={disabled}
          id={name}
        >
          <option className={style.disabled} selected disabled value={''}>
            Seçim Yapınız
          </option>
          {selectValue &&
            selectValue.length &&
            selectValue.map(item => {
              return (
                <option value={item}>
                  {city ? item.split('?')[1].split('name=')[1] : item}
                </option>
              );
            })}
        </Field>
      ) : textarea ? (
        <Field
          name={name}
          value={value}
          placeholder={placeholder}
          as={'textarea'}
          disabled={disabled}
          id={name}
        />
      ) : (
        <Field
          name={name}
          value={value}
          placeholder={placeholder}
          type={type ? type : 'text'}
          disabled={disabled}
          id={name}
        />
      )}
      {error ? <span>{error}</span> : null}
    </label>
  );
};

const CheckWrapper = ({
  title,
  name,
  value,
  placeholder,
  disabled,
  id,
  fields,
  mouseEnter,
  mouseLeave,
  click,
  isOpen,
  error,
}) => {
  return (
    <div
      onMouseEnter={mouseEnter}
      onMouseLeave={mouseLeave}
      className={style.checkWrapper}
    >
      <button className={style.checkTitle} onClick={click}>
        <h5>{title}</h5>
        <span>
          {value && value.length
            ? value.map((i, index) => (
                <>
                  {index !== 0 ? ',' : null} {i}{' '}
                </>
              ))
            : 'Seçim Yapınız'}
        </span>
      </button>
      {isOpen === id ? (
        <div className={style.checkCard}>
          {fields.map(item => {
            return (
              <label>
                <Field
                  name={name}
                  value={item}
                  placeholder={placeholder}
                  type={'checkbox'}
                  disabled={disabled}
                  id={name}
                />
                <h5>{item}</h5>
              </label>
            );
          })}
        </div>
      ) : null}
      {error ? <span className={style.error}>{error}</span> : null}
    </div>
  );
};

const FileWrapper = ({ title, accept, value, change, photo, error }) => {
  const type = value ? value.type.split('/')[0] : '';

  return (
    <div className={style.fileWrapper}>
      <label>
        <h5>{title}</h5>
        <input onChange={change} type="file" accept={accept} />
        <div className={style.field}>
          {value && photo ? (
            <img src={URL.createObjectURL(value)} />
          ) : value ? (
            <>
              {value && type === 'image' ? (
                <img src={URL.createObjectURL(value)} />
              ) : (
                <h5>{value.name}</h5>
              )}
            </>
          ) : (
            <>
              <UploadIcon /> Dosya Ekle
            </>
          )}
        </div>
      </label>
      {error ? <span className={style.error}>{error}</span> : null}
    </div>
  );
};

const Terms = ({ click, state, popupClick }) => {
  return (
    <label className={style.terms}>
      <div
        onClick={click}
        className={`${style.check} ${state ? style.activeCheck : null}`}
      />
      <p>
        <span onClick={click}>Kişisel Verilerinizin korunmasına ilişkin</span>{' '}
        <span onClick={popupClick} className={style.underline}>
          Aydınlatma Açık Rıza Formunu{' '}
        </span>
        <span onClick={click}>okudum onaylıyorum.</span>
      </p>
    </label>
  );
};

const FormCard = ({ children, title, formStyle, id }) => {
  return (
    <a.div
      style={{
        ...formStyle,
      }}
      className={style.formCard}
      id={id}
    >
      <h5 className={style.title}>{title}</h5>
      <div className={style.inputWrapper}>{children}</div>
    </a.div>
  );
};

const phoneRegExp = /^(5)([0-9]{2})\s?([0-9]{3})\s?([0-9]{2})\s?([0-9]{2})$/;
const FormSchema = Yup.object().shape({
  ad: Yup.string().min(1, 'Kısa isim.').required('İsim alanı zorunludur.'),
  soyad: Yup.string()
    .min(1, 'Kısa soyisim.')
    .required('Soyisim alanı zorunludur.'),
  iletisimNumarasi: Yup.string()
    .required('İletişim Numarası alanı zorunludur.')
    .matches(phoneRegExp, 'Doğru bir telefon numarası giriniz.'),
  eposta: Yup.string()
    .required('Eposta alanı zorunludur.')
    .email('Doğru bir eposta  giriniz.'),
  dogumTarihi: Yup.string().required('Doğum Tarihi alanı zorunludur.'),
  il: Yup.string().required('İl alanı zorunludur.'),
  ilce: Yup.string().required('İlçe alanı zorunludur.'),
  medeniDurumu: Yup.string().required('Medeni Durumu alanı zorunludur.'),
  emeklilik: Yup.string().required('Emeklilik alanı zorunludur.'),
  mezunOkul: Yup.string().required('Mezun Olduğu Okul alanı zorunludur.'),
  bransBolum: Yup.string().required('Branş/Bölüm alanı zorunludur.'),
  pedagojik: Yup.string().required('Pedagojik Formasyon alanı zorunludur.'),
  brans: Yup.array()
    .min(1)
    .of(Yup.string().required(), 'Branş alanı zorunludur.')
    .required('Branş alanı zorunludur.'),
  kademeniz: Yup.array()
    .min(1)
    .of(Yup.string().required(), 'Branş alanı zorunludur.')
    .required('Branş alanı zorunludur.'),
  english: Yup.string().required('İngilizce alanı zorunludur.'),
  cigarete: Yup.string().required('Sigara alanı zorunludur.'),
  news: Yup.string().required(
    'Bayetav Okullarından nasıl haberdar oldunuz? alanı zorunludur.'
  ),
  whyBayetav: Yup.string().required(
    'Neden Bayetav Okullarında çalışmak istiyorsunuz? alanı zorunludur.'
  ),
  salary: Yup.string().required('Maaş beklentiniz alanı zorunludur.'),

  terms: Yup.bool().oneOf([true], 'Lütfen onay veriniz.'),
});

const FormWrapper = ({
  status,
  backClick,
  popupClick,
  doneStep,
  changeContact,
  isSuccess,
  setSuccess,
}) => {
  const [error, setError] = useState({
    cv: '',
    photo: '',
  });
  const [currentDistrict, setCurrentDistrict] = useState();
  const { width } = useWindowDimensions();
  const [isLoad, setLoad] = useState(false);

  const submitRecord = values => {
    let form = new FormData();
    form.append('Adi', values.ad);
    form.append('Soyadi', values.soyad);
    form.append('Eposta', values.eposta);
    form.append('IletisimNumarasi', values.iletisimNumarasi);
    form.append('Il', values.il.split('?')[1].split('name=')[1]);
    form.append('Ilce', values.ilce);
    form.append('MedeniDurumu', values.medeniDurumu);
    form.append('CocukSayisi', values.cocukSayisi);
    form.append('Emeklilik', values.emeklilik);
    form.append('MezunOkul', values.mezunOkul);
    form.append('BransBolum', values.bransBolum);
    form.append('Pedagojik', values.pedagojik);
    form.append('Brans', values.brans.join(','));
    form.append('Kademeniz', values.kademeniz.join(','));
    form.append('Ingilizce', values.english);
    form.append('Proje', values.english);
    form.append('Sertifika', values.certificate);
    form.append('Hobiler', values.hobby);
    form.append('Sigara', values.cigarete);
    form.append('NasilHaberdarOldunuz', values.news);
    form.append('NedenBayetav', values.whyBayetav);
    form.append('SivilToplumOrgutu', values.civilSociety);
    form.append('Maas', values.salary);

    form.append('photo', photo);
    form.append('cv', cv);

    axios
      .post('https://bayetav-backend.vercel.app/ogretmenBasvuru', form)
      .then(res => {
        console.log(res.data);
        setSuccess(true);
        setLoad(false);
      })
      .catch(err => {
        console.log(err);
        setLoad(false);
      });
  };

  useEffect(() => {
    if (status === 18) {
      doneStep();
    }
  }, [status]);

  const handleScroll = event => {
    const { scrollHeight, scrollTop, clientHeight } = event.target;
    const scroll = scrollHeight - scrollTop - clientHeight;
    if (scroll === clientHeight) {
      changeContact(true);
    } else {
      changeContact(false);
    }
    console.log(scroll);
    if (scroll > 0) {
      // We are not at the bottom of the scroll content
    } else if (scroll == 0) {
      // We are at the bottom
    }
  };

  const [rawErrors, setRawErrors] = useState({});

  const [isMouse, setMouse] = useState(false);

  const [isOpen, setOpen] = useState('');

  const [photo, setPhoto] = useState();
  const [cv, setCV] = useState();

  return (
    <div
      onScroll={handleScroll}
      onWheel={event => {}}
      className={style.formWrapper}
      id="iletisim"
      style={{
        paddingBottom: !isSuccess ? '25vh' : '0',
      }}
      onClick={e => {
        if (!isMouse) {
          setOpen('');
        }
      }}
    >
      {isSuccess ? (
        <div className={style.success}>
          <p>
            <span>Bilgilerinizi paylaştığınız için teşekkür ederiz.</span>
            <br /> Sizinle tanışacağımız için heyecanlı ve mutluyuz.
            <br /> Ekibimiz en kısa sürede sizinle iletişime geçerek randevunuzu
            oluşturacaktır.
          </p>
        </div>
      ) : (
        <div className={style.content}>
          <Formik
            initialValues={{
              ad: '',
              soyad: '',
              eposta: '',
              iletisimNumarasi: '',
              dogumTarihi: '',
              il: '',
              ilce: '',
              medeniDurumu: '',
              cocukSayisi: '',
              emeklilik: '',
              mezunOkul: '',
              bransBolum: '',
              pedagojik: '',
              brans: [],
              kademeniz: [],
              english: '',
              project: '',
              certificate: '',
              hobby: '',
              cigarete: '',
              news: '',
              whyBayetav: '',
              civilSociety: '',
              salary: '',
              terms: false,
            }}
            validationSchema={FormSchema}
            onSubmit={values => {
              console.log('girdi');
              if (!cv) {
                setLoad(false);
                setError({
                  ...error,
                  cv: 'Lütfen CV ekleyiniz',
                });
              }
              if (!photo) {
                setLoad(false);
                setError({
                  ...error,
                  photo: 'Lütfen Fotoğraf ekleyiniz',
                });
              }

              if (!isLoad && photo && cv) {
                setLoad(true);
                submitRecord(values);
              }
            }}
          >
            {({ errors, touched, values, handleSubmit, setFieldValue }) => {
              setRawErrors(errors);
              return (
                <Form
                  onChange={e => {
                    if (e.target.name === 'il' && e.target.value) {
                      setCurrentDistrict(
                        DistrictData.filter(
                          i =>
                            i.il_id ===
                            e.target.value.split('?')[0].split('id=')[1]
                        ).sort((a, b) => a.name.localeCompare(b.name))
                      );
                      setFieldValue('ilce', '');
                      // setCurrentDistrict(DistrictData.filter(i => ))
                    }
                  }}
                  id="veliForm"
                  onSubmit={handleSubmit}
                  onSubmitCapture={e => {
                    console.log(rawErrors);
                    const scroll = id => {
                      const section = document.querySelector(`#${id}`);
                      section.scrollIntoView({
                        behavior: 'smooth',
                        block: 'start',
                      });
                    };

                    if (width < 940 && Object.keys(rawErrors).length !== 0) {
                      scroll('veliForms');
                    } else if (width < 940 && error) {
                      //   scroll('ogrenciForms');
                    }
                  }}
                >
                  <FormCard id="veliForms" title={'Kişisel Bilgiler'}>
                    {/* <InputWrapper title={'T.C Kimlik No'} /> */}
                    <div className={style.split}>
                      <InputWrapper
                        name={'ad'}
                        value={values.ad}
                        title={'Adı'}
                        half
                        error={errors.ad && touched.ad ? errors.ad : null}
                      />
                      <InputWrapper
                        name={'soyad'}
                        value={values.soyad}
                        half
                        title={'Soyadı'}
                        error={
                          errors.soyad && touched.soyad ? errors.soyad : null
                        }
                      />
                    </div>
                    <InputWrapper
                      name={'eposta'}
                      value={values.eposta}
                      title={'E-Posta Adresi'}
                      error={
                        errors.eposta && touched.eposta ? errors.eposta : null
                      }
                    />
                    <div className={style.split}>
                      <InputWrapper
                        name={'iletisimNumarasi'}
                        value={values.iletisimNumarasi}
                        placeholder={'500 222 00 00'}
                        title={'İletişim Numarası'}
                        type={'tel'}
                        error={
                          errors.iletisimNumarasi && touched.iletisimNumarasi
                            ? errors.iletisimNumarasi
                            : null
                        }
                        half
                      />
                      <InputWrapper
                        half
                        title={'Doğum Tarihi'}
                        date
                        name={'dogumTarihi'}
                        value={values.dogumTarihi}
                        type={'date'}
                        error={
                          errors.dogumTarihi && touched.dogumTarihi
                            ? errors.dogumTarihi
                            : null
                        }
                      />
                    </div>
                    <div className={style.split}>
                      <InputWrapper
                        name={'il'}
                        value={values.il}
                        half
                        title={'İl'}
                        select
                        city
                        selectValue={[
                          { id: '35', name: 'İZMİR' },
                          { id: '45', name: 'MANİSA' },
                          { id: '34', name: 'İSTANBUL' },
                          ...CityData,
                        ].map(item => `id=${item.id}?name=${item.name}`)}
                        error={errors.il && touched.il ? errors.il : null}
                      />
                      <InputWrapper
                        name={'ilce'}
                        value={values.ilce}
                        half
                        title={'İlçe'}
                        select
                        disabled={!(currentDistrict && currentDistrict.length)}
                        selectValue={
                          currentDistrict && currentDistrict.length
                            ? currentDistrict.map(item => item.name)
                            : null
                        }
                        error={errors.ilce && touched.ilce ? errors.ilce : null}
                      />
                    </div>
                    <div className={style.split}>
                      <InputWrapper
                        name={'medeniDurumu'}
                        value={values.medeniDurumu}
                        half
                        title={'Medeni Durum'}
                        select
                        selectValue={['Evli', 'Bekar']}
                        error={
                          errors.medeniDurumu && touched.medeniDurumu
                            ? errors.medeniDurumu
                            : null
                        }
                      />
                      <InputWrapper
                        name={'cocukSayisi'}
                        value={values.cocukSayisi}
                        half
                        title={'(Varsa) Çocuklarınız ve yaşları?'}
                        type={'number'}
                        error={
                          errors.cocukSayisi && touched.cocukSayisi
                            ? errors.cocukSayisi
                            : null
                        }
                      />
                    </div>
                    <InputWrapper
                      name={'emeklilik'}
                      value={values.emeklilik}
                      title={'Emeklilik'}
                      select
                      selectValue={['Evet', 'Hayır']}
                      error={
                        errors.emeklilik && touched.emeklilik
                          ? errors.emeklilik
                          : null
                      }
                    />
                  </FormCard>
                  <FormCard id="veliForms" title={'Eğitim Bilgileri'}>
                    {/* <InputWrapper title={'T.C Kimlik No'} /> */}
                    <div className={style.split}>
                      <InputWrapper
                        name={'mezunOkul'}
                        value={values.mezunOkul}
                        title={'Mezun Olduğu Okul'}
                        half
                        error={
                          errors.mezunOkul && touched.mezunOkul
                            ? errors.mezunOkul
                            : null
                        }
                      />
                      <InputWrapper
                        name={'bransBolum'}
                        value={values.bransBolum}
                        half
                        title={'Branş/Bölüm'}
                        error={
                          errors.bransBolum && touched.bransBolum
                            ? errors.bransBolum
                            : null
                        }
                      />
                    </div>
                    <InputWrapper
                      name={'pedagojik'}
                      value={values.pedagojik}
                      title={'Pedagojik Formasyon'}
                      error={
                        errors.pedagojik && touched.pedagojik
                          ? errors.pedagojik
                          : null
                      }
                    />
                    <CheckWrapper
                      fields={['Okul Öncesi', 'İlkokul', 'Ortaokul', 'Lise']}
                      title={'Kademeniz'}
                      id={'kademeniz'}
                      name={'kademeniz'}
                      mouseEnter={() => setMouse(true)}
                      mouseLeave={() => setMouse(false)}
                      value={values.kademeniz}
                      isOpen={isOpen}
                      click={e => {
                        setOpen(isOpen ? '' : 'kademeniz');
                        e.preventDefault();
                      }}
                      error={
                        errors.kademeniz && touched.kademeniz
                          ? 'Kademeniz alanı zorunludur.'
                          : null
                      }
                    />

                    <CheckWrapper
                      fields={[
                        'PDR',
                        'Okulöncesi',
                        'Sınıf Öğretmeni',
                        'Matemaik (Ortaokul)',
                        'Matematik (Lise)',
                        'Fen Bilgisi',
                        'Türkçe',
                        'Bilişim Tekonolojileri Öğretmeni',
                        'Din Kültürü Öğretmeni',
                        'İngilizce',
                        'Türk Dili ve Edebiyatı',
                        'Fizik',
                        'Kimya',
                        'Biyoloji',
                        'Tarih',
                        'Coğrafya',
                        'Görsel Sanatlar',
                        'Müzik',
                        'Beden Eğitimi',
                      ]}
                      title={'Branşınız'}
                      id={'brans'}
                      name={'brans'}
                      mouseEnter={() => setMouse(true)}
                      mouseLeave={() => setMouse(false)}
                      value={values.brans}
                      isOpen={isOpen}
                      click={e => {
                        setOpen(isOpen ? '' : 'brans');
                        e.preventDefault();
                      }}
                      error={
                        errors.brans && touched.brans
                          ? 'Branş alanı zorunludur.'
                          : null
                      }
                    />

                    <InputWrapper
                      name={'english'}
                      value={values.english}
                      title={'İngilizce seviyeniz?'}
                      select
                      selectValue={['Hiç', 'A1', 'A2', 'B1', 'B2', 'C1', 'C2']}
                      error={
                        errors.english && touched.english
                          ? errors.english
                          : null
                      }
                    />
                    <InputWrapper
                      name={'project'}
                      value={values.project}
                      title={
                        '(Varsa) Proje çalışmaları yaptınız mı? Hangi projelerde bulundunuz/çalıştınız?'
                      }
                      error={
                        errors.project && touched.project
                          ? errors.project
                          : null
                      }
                    />
                    <InputWrapper
                      name={'certificate'}
                      value={values.certificate}
                      title={
                        'Mesleğiniz dışında sertifikalı diğer çalışma alanlarınız? (İzcilik, Antrenörlük, Yaratıcı Drama, Yoga, Satranç vb..)'
                      }
                      error={
                        errors.certificate && touched.certificate
                          ? errors.certificate
                          : null
                      }
                    />
                    <InputWrapper
                      name={'hobby'}
                      value={values.hobby}
                      title={
                        'Hobileriniz? (Bir enstrüman çalıyorsanız bu alanda belirtebilirsiniz)'
                      }
                      error={
                        errors.hobby && touched.hobby ? errors.hobby : null
                      }
                    />

                    <InputWrapper
                      name={'cigarete'}
                      value={values.cigarete}
                      title={'Sigara kullanıyor musunuz?'}
                      select
                      selectValue={['Evet', 'Hayır']}
                      error={
                        errors.cigarete && touched.cigarete
                          ? errors.cigarete
                          : null
                      }
                    />

                    <InputWrapper
                      name={'news'}
                      value={values.news}
                      title={'Bayetav Okullarından nasıl haberdar oldunuz?'}
                      error={errors.news && touched.news ? errors.news : null}
                    />
                    <InputWrapper
                      name={'whyBayetav'}
                      value={values.whyBayetav}
                      title={'Neden Bayetav Okullarında çalışmak istiyorsunuz?'}
                      error={
                        errors.whyBayetav && touched.whyBayetav
                          ? errors.whyBayetav
                          : null
                      }
                    />
                    <InputWrapper
                      name={'civilSociety'}
                      value={values.civilSociety}
                      title={
                        'Eğitim alanında göreviniz dışında hangi sivil toplum örgütlerine dahilsiniz ve ne tür çalışmalar yaptınız/yapıyorsunuz?'
                      }
                      error={
                        errors.civilSociety && touched.civilSociety
                          ? errors.civilSociety
                          : null
                      }
                    />
                    <InputWrapper
                      name={'salary'}
                      value={values.salary}
                      title={'Maaş beklentiniz'}
                      error={
                        errors.salary && touched.salary ? errors.salary : null
                      }
                    />
                    <FileWrapper
                      value={photo}
                      title={'Fotoğraf'}
                      photo
                      accept={'image/*'}
                      change={e => {
                        setPhoto(e.target.files[0]);
                        setError({
                          ...error,
                          photo: '',
                        });
                      }}
                      error={error.photo}
                    />
                    <FileWrapper
                      value={cv}
                      accept={
                        'application/pdf,image/*,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                      }
                      title={'CV'}
                      change={e => {
                        setCV(e.target.files[0]);
                        setError({
                          ...error,
                          cv: '',
                        });
                      }}
                      error={error.cv}
                    />
                    <div className={style.formBottom}>
                      <Terms
                        click={() => {
                          setFieldValue('terms', !values.terms);
                        }}
                        popupClick={popupClick}
                        state={values.terms}
                      />
                      {errors.terms && touched.terms ? (
                        <span className={style.error}>{errors.terms}</span>
                      ) : null}

                      <button
                        disabled={isLoad}
                        type="submit"
                        className={style.send}
                        onClick={() => {
                          handleSubmit();
                        }}
                      >
                        Gönder
                      </button>
                    </div>
                  </FormCard>
                </Form>
              );
            }}
          </Formik>
        </div>
      )}
    </div>
  );
};

export default FormWrapper;
