import { useEffect, useState } from 'react';
import {
  CircleColorLogo,
  CloseIcon,
  HamburgerMenu,
  Logo,
  NavItem,
  SocialIcon,
} from '../../assets/icon';
import style from './navbar.module.scss';
import { useSpring, a } from '@react-spring/web';
import CircleLogo from '../../assets/img/circle-logo.png';
import BayetavLogo from '../../assets/img/bayetav-vakfı-logo.png';

const NavBar = ({
  status,
  current,
  okulChange,
  ogrenciChange,
  ogretmenChange,
  veliChange,
  contactChange,
  width,
  navChange,
  brosurChange,
  sssChange,
}) => {
  const [isOpen, setOpen] = useState(false);
  const [isWidth, setWidth] = useState(false);
  const [canClick, setCanclick] = useState(true);
  const [navStyle, set] = useSpring(() => ({
    transform: `translateX(${-200}px)`,
    backgroundColor: `#f7f3ee`,
    borderColor: '#b9d36c',
  }));

  const [navContent, setNav] = useSpring(() => ({
    bottom: '-100vh',
  }));

  const desktopBlue = [1, 6, 8, 10, 13, 17];
  const mobileBlue = [1, 2, 3, 4, 11, 15, 16, 18, 19, 23, 24, 31];
  const desktopTransparent = [2, 14];
  const mobileTransparent = [5, 6, 25, 26];

  const isBlue =
    width > 940 ? desktopBlue.includes(current) : mobileBlue.includes(current);

  const isTransparent =
    width > 940
      ? desktopTransparent.includes(current)
      : mobileTransparent.includes(current);

  const initialWidth = width > 940 ? '9.1vh' : '100vw';

  useEffect(() => {
    if (status) {
      set({
        transform: `translateX(${0}px)`,
      });
    } else {
      set({
        transform: `translateX(${-200}px)`,
      });
    }
  }, [status]);

  useEffect(() => {
    if (isBlue) {
      set({
        backgroundColor: `#0055b8`,
        borderColor: `white`,
      });
    } else if (isTransparent) {
      set({
        backgroundColor: `transparent`,
        borderColor: 'white',
      });
    } else {
      set({
        backgroundColor: `#f7f3ee`,
        borderColor: '#b9d36c',
      });
    }
  }, [current]);

  const toggleNav = () => {
    setCanclick(false);
    if (canClick) {
      setOpen(!isOpen);
      navChange(!isOpen);

      if (isOpen) {
        setTimeout(() => {
          setWidth(!isWidth);
        }, 700);
      } else {
        setWidth(!isWidth);
      }

      if (isOpen) {
        setNav({
          bottom: '-100vh',
        });
      } else {
        setNav({
          bottom: '0',
        });
      }

      setTimeout(() => {
        setCanclick(true);
      }, 700);
    }
  };

  return (
    <div
      style={{
        width: isWidth ? '100vw' : initialWidth,
      }}
      className={style.navWrapper}
    >
      <a.div
        style={{
          ...navStyle,
        }}
        className={`${style.navbar} ${isOpen ? style.active : null} ${
          isBlue ? style.white : null
        } ${isTransparent ? style.transparent : null}`}
      >
        <button
          onClick={() => {
            toggleNav();
          }}
        >
          {isOpen ? <CloseIcon /> : <HamburgerMenu />}
        </button>
        <div className={style.bottom}>
          <ul>
            <li>OKUL ÖNCESİ</li>
            <li>İLKOKUL</li>
            <li>ORTAOKUL</li>
            <li>LİSE</li>
          </ul>
          <img src={CircleLogo} />
        </div>
      </a.div>
      <a.div style={navContent} className={style.navContent}>
        <div className={style.content}>
          <div className={style.nasil}>
            <div className={style.nasilSvg}>
              <NavItem type={'nasil'} />
            </div>
            <ul>
              <li>
                <button
                  onClick={() => {
                    okulChange();
                    toggleNav();
                  }}
                >
                  <NavItem type={'okul'} />
                </button>
              </li>
              <li>
                <button
                  onClick={() => {
                    ogrenciChange();
                    toggleNav();
                  }}
                >
                  <NavItem type={'ogrenci'} />
                </button>
              </li>
              <li>
                <button
                  onClick={() => {
                    ogretmenChange();
                    toggleNav();
                  }}
                >
                  <NavItem type={'ogretmen'} />
                </button>
              </li>
              <li>
                <button
                  onClick={() => {
                    veliChange();
                    toggleNav();
                  }}
                >
                  <NavItem type={'veli'} />
                </button>
              </li>
            </ul>
          </div>
          <div className={style.bottom}>
            <button
              onClick={() => {
                contactChange();
                toggleNav();
              }}
            >
              Randevu Al
            </button>
            <button
              className={style.sss}
              onClick={() => {
                sssChange();
                toggleNav();
              }}
            >
              Sıkça Sorulan Sorular
            </button>
            <button
              className={style.brosur}
              onClick={() => {
                brosurChange();
                toggleNav();
              }}
            >
              E-Broşür
            </button>
            <div className={style.contactInfo}>
              <span>Tanıtım ve İletişim Ofisi:</span>
              <br />
              Maltass Köşkü
              <br />
              Erzene, 83. Sokak No:8,
              <br />
              35040 Bornova/İzmir
              <br />
              <span>Tel:</span> (232) 246 66 66
              <br />
              <a href="mailto:info@bayetavokullari.com" target="__blank">
                info@bayetavokullari.com
              </a>
              <br />
            </div>

            <div className={style.social}>
              <ul>
                <a
                  href="https://www.bayetav.org/tr"
                  target="__blank"
                  className={style.bayetav}
                >
                  <img src={BayetavLogo} />
                </a>
                <li>
                  <a
                    href="https://www.facebook.com/bayetavokullari/"
                    target="__blank"
                  >
                    <SocialIcon type={'facebook'} />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/bayetavokullari"
                    target="__blank"
                  >
                    <SocialIcon type={'instagram'} />
                  </a>
                </li>
                <li>
                  {' '}
                  <a
                    href="https://www.youtube.com/@BayetavOkullari"
                    target="__blank"
                  >
                    <SocialIcon type={'youtube'} />
                  </a>
                </li>
                <li>
                  {' '}
                  <a
                    href="https://twitter.com/bayetavokullari"
                    target="__blank"
                  >
                    <SocialIcon type={'twitter'} />
                  </a>
                </li>
                <li>
                  <a>
                    <NavItem type={'logo'} />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </a.div>
    </div>
  );
};

export default NavBar;
