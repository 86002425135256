import style from './biraradayasariz.module.scss';
import { useSpring, a, useTrail } from '@react-spring/web';
import { useEffect, useState } from 'react';
import Discover from '../Elements/Discover/Discover';

const BirAradaYasariz = ({ status, doneStep, discover1 }) => {
  const textData = [
    'büyütürüz',
    'gelişiriz',
    'eğleniriz',
    'yaşarız',
    'öğreniriz',
    'büyürüz',
    'üretiriz',
  ];

  const [mainText, setMainText] = useState(15);

  useEffect(() => {
    if (mainText === 21) {
      setTimeout(() => {
        setMainText(15);
      }, 1000);
    } else {
      setTimeout(() => {
        setMainText(mainText + 1);
      }, 1000);
    }
  }, [mainText]);

  useEffect(() => {
    if (status === 17) {
      setTimeout(() => {
        doneStep();
      }, 500);
    }
  }, [status]);

  return (
    <div className={style.birAradaYasariz}>
      <Discover click={discover1} />
      <div className={style.content}>
        <h2>bir arada</h2>
        <ul>
          {[...textData, ...textData, ...textData, ...textData].map(
            (item, index) => {
              return (
                <a.li className={mainText === index ? style.mainText : null}>
                  {item}
                </a.li>
              );
            }
          )}
          {/* <a.li style={firstText}>büyütürüz</a.li>
          <a.li style={secondText}>gelişiriz</a.li>
          <a.li style={thirdText}>eğleniriz</a.li>
          <a.li style={mainText}>yaşarız</a.li>
          <a.li style={thirdText}>öğreniriz</a.li>
          <a.li style={secondText}>büyürüz</a.li>
          <a.li style={firstText}>üretiriz</a.li> */}
        </ul>
      </div>
    </div>
  );
};

export default BirAradaYasariz;
