import { Home, OgretmenBasvur, FAQ, Brosur } from '../containers';

export const routes = [
  {
    title: 'Home',
    path: '/',
    component: <Home route={''} />,
    protected: false,
  },
  {
    title: 'OgretmenBasvur',
    path: '/ogretmen-basvuru',
    component: <OgretmenBasvur route={''} />,
    protected: false,
  },
  {
    title: 'FAQ',
    path: '/sss',
    component: <FAQ route={''} />,
    protected: false,
  },
  {
    title: 'Brosur',
    path: '/brosur',
    component: <Brosur route={''} />,
    protected: false,
  },
  {
    title: 'Home',
    path: '*',
    component: <Home route={''} />,
    protected: false,
  },
];
