import {
  Logo,
  LogoFullColor,
  NasilOgrenci,
  NasilOgrenciElement,
} from "../../assets/icon";
import Card from "../Card/Card";
import OkulImage from "../../assets/img/nasil-okul-image.png";
import OkulImage2 from "../../assets/img/nasil-okul-image-2.png";
import style from "./nasilogrenci.module.scss";
import useWindowDimensions from "../../helpers/windowWidth";
import { useSpring, a } from "@react-spring/web";
import { useEffect } from "react";
import Discover from "../Elements/Discover/Discover";
import { useNavigate } from "react-router-dom";

const NasilOgrenciWrapper = ({
  status,
  doneStep,
  discover1,
  discover2,
  width,
}) => {
  const [logo, setLogo] = useSpring(() => ({
    transform: `translateX(${-50}px)`,
    opacity: 0,
  }));
  const [svg1, setSvg1] = useSpring(() => ({
    transform: `translateY(${-50}px)`,
    opacity: 0,
  }));
  const [svg2, setSvg2] = useSpring(() => ({
    transform: `translateY(${-100}px)`,
    opacity: 0,
  }));

  const [paragraph1, setParagraph1] = useSpring(() => ({
    transform: `translateX(${-100}px)`,
    opacity: 0,
  }));
  const [paragraph2, setParagraph2] = useSpring(() => ({
    transform: `translateX(${-100}px)`,
    opacity: 0,
  }));
  const [paragraph3, setParagraph3] = useSpring(() => ({
    transform: `translateX(${-100}px)`,
    opacity: 0,
  }));
  const [paragraph4, setParagraph4] = useSpring(() => ({
    transform: `translateX(${-100}px)`,
    opacity: 0,
  }));

  const [row1, setRow1] = useSpring(() => ({
    transform: `translateX(${-100}px)`,
    opacity: 0,
  }));

  const [row2, setRow2] = useSpring(() => ({
    transform: `translateX(${-100}px)`,
    opacity: 0,
  }));
  const [row3, setRow3] = useSpring(() => ({
    transform: `translateX(${-100}px)`,
    opacity: 0,
  }));

  const [row4, setRow4] = useSpring(() => ({
    transform: `translateX(${-100}px)`,
    opacity: 0,
  }));
  const [row5, setRow5] = useSpring(() => ({
    transform: `translateX(${-100}px)`,
    opacity: 0,
  }));

  const [part2, setPart2] = useSpring(() => ({
    backgroundColor: `#0055b8`,
  }));

  const [stroke1, setStroke1] = useSpring(() => ({
    width: `0vh`,
  }));
  const [stroke2, setStroke2] = useSpring(() => ({
    width: `0vh`,
  }));
  const [stroke3, setStroke3] = useSpring(() => ({
    width: `0vh`,
  }));

  const startAnimation = () => {
    setTimeout(() => {
      setLogo({
        transform: `translateX(${0}px)`,
        opacity: 1,
      });
    }, 500);
    setTimeout(() => {
      setSvg2({
        transform: `translateY(${0}px)`,
        opacity: 1,
      });
    }, 700);
    setTimeout(() => {
      setSvg1({
        transform: `translateY(${0}px)`,
        opacity: 1,
      });
    }, 900);

    setTimeout(() => {
      setParagraph1({
        transform: `translateX(${0}px)`,
        opacity: 1,
      });
    }, 1100);
    setTimeout(() => {
      setParagraph2({
        transform: `translateX(${0}px)`,
        opacity: 1,
      });
    }, 1300);
    setTimeout(() => {
      setParagraph3({
        transform: `translateX(${0}px)`,
        opacity: 1,
      });
    }, 1500);
    setTimeout(() => {
      setParagraph4({
        transform: `translateX(${0}px)`,
        opacity: 1,
      });
    }, 1700);

    setTimeout(() => {
      doneStep();
    }, 500);
  };

  const resetAnimation = () => {
    setLogo({
      transform: `translateX(${-50}px)`,
      opacity: 0,
    });
    setSvg2({
      transform: `translateY(${-100}px)`,
      opacity: 0,
    });
    setSvg1({
      transform: `translateY(${-50}px)`,
      opacity: 0,
    });

    setParagraph1({
      transform: `translateX(${-100}px)`,
      opacity: 0,
    });
    setParagraph2({
      transform: `translateX(${-100}px)`,
      opacity: 0,
    });
    setParagraph3({
      transform: `translateX(${-100}px)`,
      opacity: 0,
    });

    setParagraph4({
      transform: `translateX(${-100}px)`,
      opacity: 0,
    });
  };

  const startAnimation1 = () => {
    setTimeout(() => {
      setPart2({
        backgroundColor: "#f7f3ee",
      });
    }, 500);
    setTimeout(() => {
      setRow1({
        transform: `translateX(${0}px)`,
        opacity: 1,
      });
    }, 500);
    setTimeout(() => {
      setStroke1({
        width: width > 940 ? "76vh" : "5vw",
      });
    }, 1000);
    setTimeout(() => {
      setRow2({
        transform: `translateX(${0}px)`,
        opacity: 1,
      });
    }, 1000);
    setTimeout(() => {
      setRow3({
        transform: `translateX(${0}px)`,
        opacity: 1,
      });
    }, 1300);

    setTimeout(() => {
      setStroke2({
        width: width > 940 ? "45vh" : "20vw",
      });
    }, 1300);

    setTimeout(() => {
      setRow4({
        transform: `translateX(${0}px)`,
        opacity: 1,
      });
    }, 1500);
    setTimeout(() => {
      setStroke3({
        width: width > 940 ? "64vh" : "40vw",
      });
    }, 1500);
    setTimeout(() => {
      setRow5({
        transform: `translateX(${0}px)`,
        opacity: 1,
      });
    }, 1700);

    setTimeout(() => {
      doneStep();
    }, 500);
  };

  const resetAnimation1 = () => {
    setPart2({
      backgroundColor: `#0055b8`,
    });
    setRow1({
      transform: `translateX(${-100}px)`,
      opacity: 0,
    });
    setRow2({
      transform: `translateX(${-100}px)`,
      opacity: 0,
    });
    setRow3({
      transform: `translateX(${-100}px)`,
      opacity: 0,
    });
    setRow4({
      transform: `translateX(${-100}px)`,
      opacity: 0,
    });
    setRow5({
      transform: `translateX(${-100}px)`,
      opacity: 0,
    });
    setStroke1({
      width: "0vh",
    });
    setStroke2({
      width: "0vh",
    });
    setStroke3({
      width: "0vh",
    });
  };

  useEffect(() => {
    if (width > 940) {
      if (status === 8 || status === 6) {
        resetAnimation();
      } else if (status === 7) {
        startAnimation();
      }

      if (status === 9 || status === 7) {
        resetAnimation1();
      } else if (status === 8) {
        startAnimation1();
      }
    } else {
      if (status === 11) {
        resetAnimation();
      } else if (status === 12) {
        startAnimation();
      }

      if (status === 12) {
        resetAnimation1();
      } else if (status === 13) {
        startAnimation1();
      }
    }
  }, [status]);

  const navigate = useNavigate();

  return (
    <>
      {width > 940 ? (
        <div onWheel={(event) => {}} className={style.nasilOgrenci}>
          <div className={style.part1}>
            <div className={style.top}>
              <a.div style={logo} className={style.logo}>
                <Logo type={"white"} />
              </a.div>
              <a.div style={svg2} className={style.svgWrapper}>
                <a.span style={svg1}>
                  <NasilOgrenci type={"top"} />
                </a.span>
                <NasilOgrenci />
              </a.div>
            </div>
            <div className={style.info}>
              <a.p style={paragraph1}>
                Kendini ekosistemin, çevrenin,
                <br /> toplumun ve topluluğun değerli
                <br /> bir parçası olarak gören
              </a.p>
              <a.p style={paragraph2}>
                Kendisinin ve topluluğun <br />
                ihtiyaçlarını fark edip çözüm
                <br /> üretebilen
              </a.p>
              <a.p style={paragraph3}>
                Sosyal ve duygusal anlamda <br /> gelişmiş, iş birliği ve <br />
                ilişkilerini geliştirebilen
              </a.p>
              <a.p onClick={() => navigate("/sss")} style={paragraph3}>
                Daha fazla <br />
                bilgi almak için <a href="/sss">S.S.S</a>
              </a.p>
            </div>
            <Discover click={discover1} />
          </div>
          <a.div style={part2} className={style.part2}>
            <div className={style.row}>
              <a.div style={stroke1} className={style.stroke} />
              <div className={style.columnWrapper}>
                <a.div style={row2} className={style.column}>
                  <NasilOgrenciElement type={0} />
                </a.div>
                <a.div style={row2} className={style.column}>
                  <NasilOgrenciElement type={1} />
                  <p>
                    çeşitlilikle yan yana durabilen,
                    <br /> haklarını bilen, savunabilen ve
                    <br /> kendini ifade edebilen
                  </p>
                </a.div>
              </div>
            </div>
            <div className={style.row}>
              <a.div style={stroke2} className={style.stroke} />
              <div className={style.columnWrapper}>
                <a.div style={row3} className={style.column}>
                  <NasilOgrenciElement type={2} />
                </a.div>
                <a.div style={row3} className={style.column}>
                  <NasilOgrenciElement type={3} />
                  <p>
                    doğru bilgiyi ayırt edebilen, derin
                    <br /> düşünme ve bağ kurma becerileri
                    <br /> yüksek, kendi potansiyelini açığa
                    <br /> çıkaran, yolunu çizebilen, zorlu
                    <br /> olaylarla ve stresle baş edebilen
                  </p>
                </a.div>
              </div>
            </div>
            <div className={style.row}>
              <a.div style={stroke3} className={style.stroke} />
              <div className={style.columnWrapper}>
                <a.div style={row4} className={style.column}>
                  <NasilOgrenciElement type={4} />
                </a.div>
                <a.div style={row4} className={style.column}>
                  <p>
                    merakının peşinden giden
                    <br />
                    <div className={style.space} />
                    Mutlu, onurlu, duyarlı, özgüvenli, yaratıcı
                    <br />
                    <div className={style.space} />
                    Değişim yaratabilen, hayatın öznesi olan
                    <br />
                    <div className={style.space} />
                    Kendisiyle ilgili kararlarda söz sahibi olan
                    <br />
                    <div className={style.space} />
                    Duygularını kabul edip düzenleyebilen,
                    <br /> anlamlı deneyimlere dönüştürebilen
                  </p>
                </a.div>
              </div>
            </div>
            <a.div style={row5} className={style.row}>
              <div className={style.column}>
                <NasilOgrenciElement type={5} />
              </div>
              <div className={style.column}>
                <p>
                  desteklendiğini, güvende olduğunu
                  <br /> hisseden
                </p>
              </div>
            </a.div>
            <Discover click={discover2} type={"primary"} />
          </a.div>
        </div>
      ) : (
        <div
          id="ogrenci"
          onWheel={(event) => {}}
          className={style.nasilOgrenci}
        >
          <div className={style.part1}>
            <div className={style.top}>
              <div style={logo} className={style.logo}>
                <Logo type={"white"} />
              </div>
              <div style={svg2} className={style.svgWrapper}>
                <span style={svg1}>
                  <NasilOgrenci type={"top"} />
                </span>
                <NasilOgrenci />
              </div>
            </div>
            <div className={style.info}>
              <p style={paragraph1}>
                Kendini ekosistemin, çevrenin,
                <br /> toplumun ve topluluğun değerli
                <br /> bir parçası olarak gören
              </p>
              <p style={paragraph2}>
                Kendisinin ve topluluğun <br />
                ihtiyaçlarını fark edip çözüm
                <br /> üretebilen
              </p>
              <p style={paragraph3}>
                Sosyal ve duygusal anlamda <br /> gelişmiş, iş birliği ve <br />
                ilişkilerini geliştirebilen
              </p>
              <p onClick={() => navigate("/sss")} style={paragraph3}>
                Daha fazla <br />
                bilgi almak için <a href="/sss">S.S.S</a>
              </p>
            </div>
            <Discover click={discover1} />
          </div>
          <div className={style.part2}>
            <div className={style.row}>
              <div className={style.stroke} />
              <div className={style.columnWrapper}>
                <div className={style.column}>
                  <NasilOgrenciElement type={0} />
                </div>
                <div className={style.column}>
                  <NasilOgrenciElement type={1} />
                  <p>
                    çeşitlilikle yan yana durabilen,
                    <br /> haklarını bilen, savunabilen ve
                    <br /> kendini ifade edebilen
                  </p>
                </div>
              </div>
            </div>
            <div className={style.row}>
              <div className={style.stroke} />
              <div className={style.columnWrapper}>
                <div className={style.column}>
                  <NasilOgrenciElement type={2} />
                </div>
                <div className={style.column}>
                  <NasilOgrenciElement type={3} />
                  <p>
                    doğru bilgiyi ayırt edebilen, derin
                    <br /> düşünme ve bağ kurma becerileri
                    <br /> yüksek, kendi potansiyelini açığa
                    <br /> çıkaran, yolunu çizebilen, zorlu
                    <br /> olaylarla ve stresle baş edebilen
                  </p>
                </div>
              </div>
            </div>
            <div className={style.row}>
              <div className={style.stroke} />
              <div className={style.columnWrapper}>
                <div className={style.column}>
                  <NasilOgrenciElement type={4} />
                </div>
                <div className={style.column}>
                  <p>
                    merakının peşinden giden
                    <br />
                    <div className={style.space} />
                    Mutlu, onurlu, duyarlı, özgüvenli, yaratıcı
                    <br />
                    <div className={style.space} />
                    Değişim yaratabilen, hayatın öznesi olan
                    <br />
                    <div className={style.space} />
                    Kendisiyle ilgili kararlarda söz sahibi olan
                    <br />
                    <div className={style.space} />
                    Duygularını kabul edip düzenleyebilen,
                    <br /> anlamlı deneyimlere dönüştürebilen
                  </p>
                </div>
              </div>
            </div>
            <div className={style.row}>
              <div className={style.column}>
                <NasilOgrenciElement type={5} />
              </div>
              <div className={style.column}>
                <p>
                  desteklendiğini, güvende olduğunu
                  <br /> hisseden
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default NasilOgrenciWrapper;
