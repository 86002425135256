import { Logo, NedenBoyleBirOkul, PlayIcon } from "../../assets/icon";
import style from "./videoWrapper.module.scss";
import { useSpring, a } from "@react-spring/web";
import Image from "../../assets/img/image-1.png";
import { useState, useEffect } from "react";
import YouTube from "react-youtube";
import BayetavVideo from "../../assets/img/bayetav-video.mp4";
import Discover from "../Elements/Discover/Discover";

const VideoWrapper = ({ status, doneStep, width, discover1 }) => {
  const [isPlay, setPlay] = useState(false);
  const [logo, setLogo] = useSpring(() => ({
    transform: `translateX(${-100}px)`,
    opacity: 0,
  }));
  const [video, setVideo] = useSpring(() => ({
    transform: `translateX(${-100}px)`,
    opacity: 0,
  }));

  const startAnimation = () => {
    setTimeout(() => {
      setLogo({
        opacity: 1,
        transform: `translateX(${0}px)`,
      });
    }, 500);
    setTimeout(() => {
      setVideo({
        opacity: 1,
        transform: `translateX(${0}px)`,
      });
    }, 1000);

    setTimeout(() => {
      doneStep();
    }, 500);
  };

  const resetAnimation = () => {
    setLogo({
      opacity: 0,
      transform: `translateX(${-100}px)`,
      immediate: true,
    });
    setVideo({
      opacity: 0,
      transform: `translateX(${-100}px)`,
      immediate: true,
    });
  };

  useEffect(() => {
    if (width > 940) {
      if (status === 7) {
        resetAnimation();
      } else if (status === 6) {
        startAnimation();
      }
    } else {
      if (status === 10) {
        resetAnimation();
      } else if (status === 11) {
        startAnimation();
      }
    }
  }, [status]);

  return (
    <>
      {width > 940 ? (
        <div onWheel={(event) => {}} className={style.videoWrapper}>
          <div className={style.content}>
            <a.div style={logo} className={style.title}>
              <Logo type={"color"} />
            </a.div>
            <a.div style={video} className={style.videoContent}>
              {isPlay ? (
                <video autoPlay controls>
                  <source src={BayetavVideo} type="video/mp4" />
                </video>
              ) : (
                <div onClick={() => setPlay(true)} className={style.cover}>
                  <PlayIcon />
                  <img src={Image} />
                </div>
              )}
            </a.div>
          </div>
          <Discover type={"primary"} click={discover1} />
        </div>
      ) : (
        <div onWheel={(event) => {}} className={style.videoWrapper}>
          <div className={style.content}>
            <div className={style.title}>
              <Logo type={"color"} />
            </div>
            <div className={style.videoContent}>
              {isPlay ? (
                <video autoPlay controls>
                  <source src={BayetavVideo} type="video/mp4" />
                </video>
              ) : (
                <div onClick={() => setPlay(true)} className={style.cover}>
                  <PlayIcon />
                  <img src={Image} />
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default VideoWrapper;
