import style from './contactcard.module.scss';
import { DiscoverArrow } from '../../../assets/icon';

const ContactCard = ({ click, status }) => {
  return (
    <div
      onClick={click}
      className={`${style.cardWrapper} ${status ? style.active : null}`}
    >
      <div className={style.card}>
        <svg
          width="12"
          height="16"
          viewBox="0 0 12 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3.3063 0.991193C4.17481 0.369203 5.24768 7.83262e-09 6.4057 9.56106e-09C9.30414 1.38872e-08 11.6542 2.29504 11.6542 5.1256C11.6542 6.62902 10.9901 7.98276 9.93424 8.92074L9.72307 9.07374C9.40632 9.31655 9.01123 9.45958 8.57868 9.45958C7.5569 9.45958 6.72585 8.648 6.72585 7.65015C6.72585 7.08803 6.9813 6.5758 7.39341 6.24319C7.76466 5.94051 8.00307 5.4815 8.00307 4.9726C8.00307 4.05791 7.24355 3.31617 6.30692 3.31617C6.30692 3.31617 6.02083 3.31617 5.79944 3.37604C5.58146 3.43591 5.25449 3.5989 5.04673 3.78516L4.76403 4.0446C3.48681 5.31852 2.91802 7.20777 3.44253 9.05046C4.2259 11.8012 7.14819 13.421 9.96148 12.656C10.8164 12.4232 11.7019 12.9121 11.9403 13.747C12.1788 14.5819 11.6815 15.46 10.8266 15.6928C6.28649 16.9268 1.58289 14.3324 0.319293 9.89863C-0.64118 6.52591 0.625828 3.00352 3.3063 0.994519L3.3063 0.991193Z"
            fill="white"
          />
        </svg>

        <h2>RANDEVU AL</h2>
      </div>
      <div className={`${style.content} ${style.active}`}>
        <p>
          Sizinle iletişime geçebilmemiz ve randevu oluşturabilmemiz için lütfen
          formu doldurun.
        </p>
        <button>
          İLETİŞİM <DiscoverArrow color={'primary'} />
        </button>
      </div>
    </div>
  );
};

export default ContactCard;
